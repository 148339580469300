import React from 'react';
import StaticDashboard from '../../components/Dashboards/StaticDashboard';
import { RouteComponentProps } from 'react-router';
import useRouter from 'use-react-router';
import { QueryRenderer } from 'react-relay';
import environment from '../../graphql/environment';
import ErrorNotice from '../../components/ErrorNotice';
import { graphql } from 'babel-plugin-relay/macro';
import { DashboardPageQuery } from '../../__generated__/DashboardPageQuery.graphql';
import { ModuleHeader } from '../../components/Module';
import { useTranslation } from 'react-i18next';

export const dashboardPageQuery = graphql`
  query DashboardPageQuery($tenantId: String!) {
    scans(tenantId: $tenantId, first: 10) {
      ...StaticDashboard_scans
    }

    aggregations(tenantId: $tenantId, first: 30) {
      ...StaticDashboard_aggregations
    }
  }
`;

const DashboardPage = () => {
  const { match }: RouteComponentProps<any> = useRouter();
  const { t } = useTranslation(['qrpid']);

  return (
    <div className="cell small-12">
      <ModuleHeader
        title={t('dashboard.title')}
        description={t('dashboard.description')}
        link={''}
      />

      <QueryRenderer<DashboardPageQuery>
        variables={{ tenantId: match.params.tenantId }}
        environment={environment}
        query={dashboardPageQuery}
        render={({ error, props }) => {
          if (error) {
            return (
              <ErrorNotice
                errorText={'Sorry, there was an error while loading the dashboard.'}
                buttonText={'Back'}
                returnUrl={`/`}
              />
            );
          }

          const scans = props ? props.scans : null;
          const aggregations = props ? props.aggregations : null;

          // if (props && props.scans && props.aggregations) {
          return (
            <>
              <StaticDashboard scans={scans} aggregations={aggregations} />
            </>
          );
          // }

          // return <div>Loading</div>;
        }}
      />
    </div>
  );
};

export default DashboardPage;
