export const b64toBlob = (b64Data: string, sliceSize = 512): Blob => {
  const match = b64Data.match(/data:(image\/[a-zA-Z]*);base64,([^"]*)/);

  if (!match || match.length !== 3) {
    throw new Error('Invalid base64 string.');
  }

  const [, contentType, data] = match;
  const byteCharacters = atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = [slice.length];
    for (let i = 0; i < slice.length; i = i + 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};
