/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeDetails_scans = {
  readonly ' $fragmentRefs': FragmentRefs<'LatestScansTableWidget_scans'>;
  readonly ' $refType': 'QrCodeDetails_scans';
};
export type QrCodeDetails_scans$data = QrCodeDetails_scans;
export type QrCodeDetails_scans$key = {
  readonly ' $data'?: QrCodeDetails_scans$data;
  readonly ' $fragmentRefs': FragmentRefs<'QrCodeDetails_scans'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'QrCodeDetails_scans',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'LatestScansTableWidget_scans',
    },
  ],
  type: 'ScanConnection',
};
(node as any).hash = 'd5e4bcbac13db6093f90610a4362da4e';
export default node;
