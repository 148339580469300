/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
export type QrCodeType = 'REDIRECT' | 'URL' | 'WIFI' | '%future added value';
export type WifiEncryptionType = 'NONE' | 'WEP' | 'WPA' | '%future added value';
export type UpdateQrCodeInput = {
  clientMutationId?: string | null;
  id: string;
  tenantId: string;
  type?: QrCodeType | null;
  enabled?: boolean | null;
  comment?: string | null;
  name?: string | null;
  redirectUrl?: string | null;
  ssid?: string | null;
  password?: string | null;
  encryption?: WifiEncryptionType | null;
  url?: string | null;
};
export type updateQrCodeMutationVariables = {
  input: UpdateQrCodeInput;
};
export type updateQrCodeMutationResponse = {
  readonly updateQrCode: {
    readonly qrCode: {
      readonly id: string;
      readonly tenantId: string;
      readonly type: QrCodeType;
      readonly isDynamic: boolean;
      readonly comment: string | null;
    };
  } | null;
};
export type updateQrCodeMutation = {
  readonly response: updateQrCodeMutationResponse;
  readonly variables: updateQrCodeMutationVariables;
};

/*
mutation updateQrCodeMutation(
  $input: UpdateQrCodeInput!
) {
  updateQrCode(input: $input) {
    qrCode {
      __typename
      id
      tenantId
      type
      isDynamic
      comment
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'UpdateQrCodeInput!',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'input',
        variableName: 'input',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'tenantId',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'type',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDynamic',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'comment',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'updateQrCodeMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'UpdatePayload',
          kind: 'LinkedField',
          name: 'updateQrCode',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: 'LinkedField',
              name: 'qrCode',
              plural: false,
              selections: [v2 /*: any*/, v3 /*: any*/, v4 /*: any*/, v5 /*: any*/, v6 /*: any*/],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'updateQrCodeMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'UpdatePayload',
          kind: 'LinkedField',
          name: 'updateQrCode',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: 'LinkedField',
              name: 'qrCode',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
                v2 /*: any*/,
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'updateQrCodeMutation',
      operationKind: 'mutation',
      text:
        'mutation updateQrCodeMutation(\n  $input: UpdateQrCodeInput!\n) {\n  updateQrCode(input: $input) {\n    qrCode {\n      __typename\n      id\n      tenantId\n      type\n      isDynamic\n      comment\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'c9845fdc1e11d184993c92d252c823e2';
export default node;
