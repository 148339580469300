/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeType = 'REDIRECT' | 'URL' | 'WIFI' | '%future added value';
export type UrlForm_qrCode = {
  readonly type: QrCodeType;
  readonly isDynamic: boolean;
  readonly name: string | null;
  readonly url?: string | null;
  readonly ' $refType': 'UrlForm_qrCode';
};
export type UrlForm_qrCode$data = UrlForm_qrCode;
export type UrlForm_qrCode$key = {
  readonly ' $data'?: UrlForm_qrCode$data;
  readonly ' $fragmentRefs': FragmentRefs<'UrlForm_qrCode'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'UrlForm_qrCode',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'type',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDynamic',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
      ],
      type: 'UrlQrCode',
    },
  ],
  type: 'QrCode',
};
(node as any).hash = '6670bd240c1be583b35a5718337637e4';
export default node;
