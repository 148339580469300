import React, { useState } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import ActionTypeSelect from '../../QrCodeDetails/components/ActionTypeSelect';
import Submit from '../../QrCodeDetails/components/Submit';
import { UrlForm_qrCode } from '../../../../../__generated__/UrlForm_qrCode.graphql';

interface IProps {
  qrCode: UrlForm_qrCode;
}

const UrlForm = ({ qrCode }: IProps) => {
  const { isDynamic } = qrCode;

  const [state, setState] = useState({
    url: qrCode.url,
    type: qrCode.type,
    name: qrCode.name,
  });

  const { url, type, name } = state;

  const handleInputChange = (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const inputName = event.target.name;

    setState({
      ...state,
      [inputName]: value,
    });
  };

  return (
    <>
      <label>
        Name
        <input
          type="text"
          name="name"
          placeholder="Name"
          disabled={!isDynamic}
          defaultValue={name ? name : undefined}
          onChange={handleInputChange}
          maxLength={32}
        />
      </label>

      <ActionTypeSelect value={type} isDynamic={isDynamic} onChange={handleInputChange} />

      <label>
        URL
        <input
          type="text"
          name="url"
          placeholder="https://..."
          disabled={!isDynamic}
          defaultValue={url ? url : undefined}
          onChange={handleInputChange}
        />
      </label>

      <Submit
        onSubmit={() => {
          return;
        }}
      />
    </>
  );
};

export default createFragmentContainer(UrlForm, {
  qrCode: graphql`
    fragment UrlForm_qrCode on QrCode {
      type
      isDynamic
      name
      ... on UrlQrCode {
        url
      }
    }
  `,
});
