import React from 'react';
import { Link } from 'react-router-dom';
import styles from './EmptyBillingInformation.module.scss';
import { useSelectedTenantId } from '../../../../../hooks/use-tenant-id.hook';
import { useTranslation } from 'react-i18next';

const EmptyBillingInformation = () => {
  const tenantId = useSelectedTenantId();
  const { t } = useTranslation(['qrpid']);

  return (
    <div className={styles.container}>
      <div className="mb-2">
        <h4 className="mb-0">{t('account.accountPage.billing.empty.headline')}</h4>
        <small className={styles['text--muted']}>
          {t('account.accountPage.billing.empty.subHeadline')}
        </small>
      </div>
      <Link to={`/${tenantId}/account/billing-information`} className="button primary small">
        {t('account.accountPage.billing.empty.callToAction')}
      </Link>
    </div>
  );
};

export default EmptyBillingInformation;
