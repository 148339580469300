import React, { createContext } from 'react';

export interface IAuthContext {
  authState: string;
  changeState: (state: string, data: any) => void;
}

export const AuthContext = createContext<IAuthContext>({ authState: '', changeState: () => null });

const AuthContextProvider = (props: any) => {
  return (
    <AuthContext.Provider value={{ authState: props.authState, changeState: props.onStateChange }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
