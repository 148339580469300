/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type LatestScansChartWidget_aggregations = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly timestamp: string;
      readonly count: number;
    };
  } | null> | null;
  readonly retentionTimeInDays: number;
  readonly ' $refType': 'LatestScansChartWidget_aggregations';
};
export type LatestScansChartWidget_aggregations$data = LatestScansChartWidget_aggregations;
export type LatestScansChartWidget_aggregations$key = {
  readonly ' $data'?: LatestScansChartWidget_aggregations$data;
  readonly ' $fragmentRefs': FragmentRefs<'LatestScansChartWidget_aggregations'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'LatestScansChartWidget_aggregations',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'AggregationEdge',
      kind: 'LinkedField',
      name: 'edges',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'AggregationItem',
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'timestamp',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'count',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'retentionTimeInDays',
      storageKey: null,
    },
  ],
  type: 'AggregationConnection',
};
(node as any).hash = 'd96767f98e77cee2836844b153e0c478';
export default node;
