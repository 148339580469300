import React from 'react';
import UserProfile from '../../../components/UserProfile';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { ProfilePageQuery } from '../../../__generated__/ProfilePageQuery.graphql';
import environment from '../../../graphql/environment';
import ErrorNotice from '../../../components/ErrorNotice';

export const profilePageQuery = graphql`
  query ProfilePageQuery {
    user {
      ...UserProfile_user
    }
  }
`;

const ProfilePage = () => {
  return (
    <QueryRenderer<ProfilePageQuery>
      environment={environment}
      query={profilePageQuery}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return (
            <ErrorNotice
              errorText={'Sorry, there was an unexpected error.'}
              buttonText={'Back'}
              returnUrl={`/`}
            />
          );
        }

        return (
          <div className="cell small-12">
            <h1 className="mb-2">User Profile</h1>
            <UserProfile user={props?.user ?? null} />
          </div>
        );
      }}
    />
  );
};

export default ProfilePage;
