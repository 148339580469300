import { useState, useRef } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from './use-constant.hook';
import { useAsync } from 'react-async-hook';

export const useDebouncedInput = (fetch: (input: string) => void, delay: number) => {
  const [inputText, setInputText] = useState('');
  const isFirstRun = useRef(true);

  const debouncedInput = useConstant(() => AwesomeDebouncePromise(fetch, delay));

  const execution = useAsync(
    async (text: string) => {
      // do not trigger fetch on first execution
      if (!isFirstRun.current) {
        debouncedInput(text);
      }

      isFirstRun.current = false;
    },
    [inputText]
  );

  return {
    inputText,
    setInputText,
    execution,
  };
};
