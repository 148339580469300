import React from 'react';
import styles from './ClickableRow.module.scss';

interface IProps {
  onClick: () => void;
  row: string[];
}

const ClickableRow = ({ onClick, row }: IProps) => (
  <tr className={styles.clickableRow} onClick={onClick}>
    {row.map((r, i) => (
      <td key={i}>{r}</td>
    ))}
  </tr>
);

export default ClickableRow;
