import React, { ReactNode } from 'react';
import styles from './HighlightedContainer.module.scss';

interface IProps {
  children?: ReactNode;
  className?: string;
  padding?: boolean;
}

const HighlightedContainer = ({ children, className, padding = true }: IProps) => {
  const css = `${styles.highlightedContainer} ${className} ${
    padding ? styles.highlightedContainer__innerSpacing : ''
  }`;

  return <div className={css}>{children}</div>;
};

export default HighlightedContainer;
