import React, { PureComponent } from 'react';
import CollapsibleMenuItem from '../CollapsibleMenuItem';
import MenuItemWithIcon from '../MenuItemWithIcon';
import styles from './MenuItem.module.scss';
import { Link } from 'react-router-dom';
import { IMenuItem } from '../../lib/menu-item';

interface IProps {
  menuItem: IMenuItem | null;
  onNavigate: () => void;
}

interface State {
  isOpen: boolean;
}

class MenuItem extends PureComponent<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    const { menuItem, onNavigate } = this.props;

    return menuItem?.submenuItems ? (
      <CollapsibleMenuItem submenuItems={menuItem.submenuItems} onNavigate={onNavigate}>
        <MenuItemWithIcon name={menuItem.name} icon={menuItem.icon} />
      </CollapsibleMenuItem>
    ) : menuItem?.url ? (
      <Link to={menuItem.url} onClick={onNavigate}>
        <MenuItemWithIcon className={styles.menuItem} name={menuItem.name} icon={menuItem.icon} />
      </Link>
    ) : (
      <div
        onClick={() => {
          if (menuItem?.onClick) {
            menuItem.onClick();
          }

          onNavigate();
        }}
      >
        <MenuItemWithIcon className={styles.menuItem} name={menuItem?.name} icon={menuItem?.icon} />
      </div>
    );
  }
}

export default MenuItem;
