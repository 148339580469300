import React from 'react';
import ActionTypeSelect from '../../QrCodeDetails/components/ActionTypeSelect';
import Submit from '../../QrCodeDetails/components/Submit';
import EnabledCheckbox from '../../QrCodeDetails/components/EnabledCheckbox';
import { IRedirectForm } from './lib/redirect-form';
import { Formik } from 'formik';
import { validationSchema } from './lib/validationSchema';
import Input from '../../../../../components/forms/Input';
import Textarea from '../../../../../components/forms/Textarea';

interface IProps {
  initialValues?: IRedirectForm;
  onSubmit: (form: IRedirectForm) => void;
}

const RedirectForm = ({ initialValues, onSubmit }: IProps) => {
  const formValues: IRedirectForm = {
    type: 'REDIRECT',
    enabled: initialValues?.enabled ?? true,
    redirectUrl: initialValues?.redirectUrl ?? '',
    comment: initialValues?.comment ?? '',
    name: initialValues?.name ?? '',
  };

  return (
    <Formik initialValues={formValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {props => {
        const { handleSubmit, errors, touched, handleChange } = props;

        return (
          <>
            <Input
              type="text"
              label="Website"
              name="redirectUrl"
              placeholder="https://..."
              touched={touched.redirectUrl}
              error={errors.redirectUrl}
            />

            <EnabledCheckbox onChange={handleChange} checked={formValues.enabled} />

            <ActionTypeSelect value={'REDIRECT'} isDynamic={true} onChange={handleChange} />

            <Input
              type="text"
              label="Name"
              name="name"
              placeholder="Name"
              touched={touched.name}
              error={errors.name}
            />

            <Textarea
              label="Comment"
              name="comment"
              placeholder="Comment"
              rows={4}
              touched={touched.comment}
              error={errors.comment}
            />

            <Submit onSubmit={handleSubmit} />
          </>
        );
      }}
    </Formik>
  );
};

export default RedirectForm;
