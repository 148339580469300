import React from 'react';
import { Loading } from 'aws-amplify-react/lib/Auth';
import styles from './QrpidLoading.module.scss';
import Spinner from '../../../Spinner';
import { Translation } from 'react-i18next';

export default class QrpidLoading extends Loading {
  showComponent() {
    return (
      <Translation>
        {t => (
          <div className={styles.loading}>
            <div className={`${styles.loading__spinner} mb-2`}>
              <Spinner color="PRIMARY" />
            </div>
            {t('auth.loading.headline')}
          </div>
        )}
      </Translation>
    );
  }
}
