import React, { ReactNode } from 'react';
import styles from './Field.module.scss';
import { Skeleton } from '../../../../components/Skeleton';

interface IProps {
  label: string | ReactNode;
  value?: string;
  skeletonWidth?: string;
  isLoading?: boolean;
}

const Field: React.SFC<IProps> = ({ isLoading, value, skeletonWidth, label }) => {
  return (
    <div className={`grid-x grid-margin-x`}>
      <div className={`cell small-12 medium-4 ${styles.field__label}`}>
        <strong>{label}</strong>
      </div>
      <div className={`cell small-12 medium-8`}>
        {isLoading ? (
          <Skeleton
            style={{ fontSize: '75%', ...(skeletonWidth ? { width: skeletonWidth } : {}) }}
          />
        ) : (
          value
        )}
      </div>
    </div>
  );
};

export default Field;
