import React, { memo } from 'react';
import { EChartType } from './lib/chart-type.model';
import { Line, Bar, Pie, Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { lineChartDataSet, lineOptions } from '../../common/options/chart/line-chart.options';
import { barChartDataSet, barOptions } from '../../common/options/chart/bar-chart.options';
import { pieChartDataSet, pieOptions } from '../../common/options/chart/pie-chart';
import { doughnutChartDataSet, doughnutOptions } from '../../common/options/chart/doughnut-chart';
import merge from 'lodash.merge';

interface IProps {
  type: EChartType;
  data: ChartData;
  options?: ChartOptions;
}

const createChartData = (data: ChartData, baseDataSets: any[]) => {
  return {
    ...data,
    datasets: data.datasets && data.datasets.map((d, i) => ({ ...d, ...baseDataSets[i] })),
  };
};

const createChartOptions = (baseChartOptions: ChartOptions, customOptions: ChartOptions) => {
  return merge({}, baseChartOptions, customOptions);
};

const Chart = ({ type, data, options }: IProps) => {
  switch (type) {
    case EChartType.LineChart:
      return (
        <Line
          data={createChartData(data, lineChartDataSet)}
          options={options ? createChartOptions(lineOptions, options) : lineOptions}
        />
      );

    case EChartType.BarChart:
      return (
        <Bar
          data={createChartData(data, barChartDataSet)}
          options={options ? createChartOptions(barOptions, options) : barOptions}
        />
      );

    case EChartType.PieChart:
      return (
        <Pie
          data={createChartData(data, pieChartDataSet)}
          options={options ? createChartOptions(pieOptions, options) : pieOptions}
        />
      );

    case EChartType.DoughnutChart:
      return (
        <Doughnut
          data={createChartData(data, doughnutChartDataSet)}
          options={options ? createChartOptions(doughnutOptions, options) : doughnutOptions}
        />
      );

    default:
      throw new Error(`Unknown chart type: ${type}`);
  }
};

export default memo(Chart);
