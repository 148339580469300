import React, { ReactNode } from 'react';
import styles from './InputGroup.module.scss';
import { Skeleton } from '../../Skeleton';

interface IProps {
  label?: string;
  touched?: boolean;
  error?: string;
  required?: boolean;
  loading?: boolean;
  skeletonWidth?: string;
  children: ReactNode;
}

const InputGroup = ({
  label,
  touched,
  error,
  required,
  loading,
  skeletonWidth,
  children,
}: IProps) => {
  return (
    <div className={`grid-x grid-margin-x mb-2`}>
      <div className={`cell small-12 medium-4 ${styles.inputGroup__label}`}>
        {label}
        {required && <sup className={styles.inputGroup__required}>*</sup>}
      </div>

      <div className={`cell small-12 medium-8 ${styles.inputGroup__inputs}`}>
        {loading ? <Skeleton style={{ width: `${skeletonWidth ?? '200px'}` }} /> : <>{children}</>}
      </div>

      {touched && error && (
        <>
          <div className={`cell small-4`}>&nbsp;</div>
          <div className={`cell small-8`}>
            <span className="form-error is-visible mt-1 mb-0">{error}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default InputGroup;
