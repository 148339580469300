import * as Yup from 'yup';

export const validationSchema = Yup.object().shape<any>({
  code: Yup.string()
    .required('Please enter the verification code.')
    .min(6, ({ min }) => `The verification has to have ${min} digits.`)
    .max(6, ({ max }) => `The verification has to have ${max} digits.`)
    .matches(/^\d+$/, 'The verfication code can only consist of digits.'),
  password: Yup.string()
    .required('Please enter a password.')
    .min(6, ({ min }) => `Your password length must be at least ${min} characters.`)
    .required('Please enter a password.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[A-Z]/, 'Must contain at least one uppercase character.')
    .matches(/[a-z]/, 'Must contain at least one lowercase character.')
    .matches(/[0-9]/, 'Must contain at least one number.')
    .matches(
      /[\^$*.[\]{}()?\-\\"!@#%&/\\,><':;|_~`]/,
      'Your password must contain at least one of the following special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ `'
    ),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match.'),
});
