import React from 'react';
import { Field } from 'formik';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { CountrySelect_countries } from '../../../__generated__/CountrySelect_countries.graphql';

interface IProps {
  name: string;
  countries: CountrySelect_countries | undefined | null;
}

const CountrySelect: React.SFC<IProps> = ({ name, countries }: IProps) => {
  return countries ? (
    <Field as="select" name={name} className="mb-0">
      <option>Select Country</option>
      {countries.edges.map(c => (
        <option key={`country_${c}`} value={c?.code}>
          {c?.name}
        </option>
      ))}
    </Field>
  ) : null;
};

export default createFragmentContainer(CountrySelect, {
  countries: graphql`
    fragment CountrySelect_countries on CountryConnection {
      edges {
        code
        name
      }
    }
  `,
});
