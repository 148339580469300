import React, { PureComponent, ReactNode } from 'react';
import Collapsible from 'react-collapsible';
import SubMenu from '../SubMenu';
import { ISubmenuItem } from '../../lib/sub-menu-item';
import CollapsibleMenuHeader from '../CollapsibleMenuItemHeader';

interface IProps {
  submenuItems: (ISubmenuItem | null)[];
  children: ReactNode;
  onNavigate?: () => void;
}

interface State {
  isOpen: boolean;
}

class CollapsibleMenuItem extends PureComponent<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = { isOpen: false };
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { children, submenuItems, onNavigate } = this.props;

    return (
      <Collapsible
        transitionTime={250}
        trigger={
          <CollapsibleMenuHeader isOpen={this.state.isOpen}>{children}</CollapsibleMenuHeader>
        }
        onOpening={this.toggleOpen}
        onClosing={this.toggleOpen}
      >
        <SubMenu subMenuItems={submenuItems} onNavigate={onNavigate} />
      </Collapsible>
    );
  }
}

export default CollapsibleMenuItem;
