import React from 'react';
import styles from './Overlay.module.scss';

interface IProps {
  text?: string;
}

const Overlay = ({ text }: IProps) => {
  return <div className={styles.overlay}>{text}</div>;
};

export default Overlay;
