import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { en, de } from './locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        qrpid: en.en,
      },
      de: {
        qrpid: de.de,
      },
    },

    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['qrpid'],
    defaultNS: 'qrpid',

    keySeparator: '.',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
