/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type AccountPageQueryVariables = {
  id: string;
};
export type AccountPageQueryResponse = {
  readonly tenant: {
    readonly billingInformation: {
      readonly ' $fragmentRefs': FragmentRefs<'BillingInformation_billingInformation'>;
    } | null;
  } | null;
};
export type AccountPageQuery = {
  readonly response: AccountPageQueryResponse;
  readonly variables: AccountPageQueryVariables;
};

/*
query AccountPageQuery(
  $id: ID!
) {
  tenant(id: $id) {
    billingInformation {
      ...BillingInformation_billingInformation
    }
    id
  }
}

fragment BillingInformation_billingInformation on BillingInformation {
  type
  firstName
  lastName
  company
  city
  zipCode
  street
  streetNumber
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'AccountPageQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Tenant',
          kind: 'LinkedField',
          name: 'tenant',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'BillingInformation',
              kind: 'LinkedField',
              name: 'billingInformation',
              plural: false,
              selections: [
                {
                  args: null,
                  kind: 'FragmentSpread',
                  name: 'BillingInformation_billingInformation',
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'AccountPageQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Tenant',
          kind: 'LinkedField',
          name: 'tenant',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'BillingInformation',
              kind: 'LinkedField',
              name: 'billingInformation',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'type',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'firstName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'lastName',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'company',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'city',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'zipCode',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'street',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'streetNumber',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'AccountPageQuery',
      operationKind: 'query',
      text:
        'query AccountPageQuery(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    billingInformation {\n      ...BillingInformation_billingInformation\n    }\n    id\n  }\n}\n\nfragment BillingInformation_billingInformation on BillingInformation {\n  type\n  firstName\n  lastName\n  company\n  city\n  zipCode\n  street\n  streetNumber\n}\n',
    },
  };
})();
(node as any).hash = '1d7d285ce5b90fd1f3e3b5fbedfa37b0';
export default node;
