import React, { ReactNode } from 'react';
import { Container } from 'inversify';
import { InversifyContext } from './lib/ioc-context';

interface IProps {
  container: Container;
  children: ReactNode;
}

const IocProvider = (props: IProps) => {
  return (
    <InversifyContext.Provider value={{ container: props.container }}>
      {props.children}
    </InversifyContext.Provider>
  );
};

export default IocProvider;
