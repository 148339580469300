import React from 'react';
import styles from './Sidebar.module.scss';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import QrCodeWithDownload from './components/QrCodeWithDownload';
import { Sidebar_qrCode } from '../../../../../../__generated__/Sidebar_qrCode.graphql';
import { SkeletonArea, SkeletonTitle } from '../../../../../../components/Skeleton';
import { toLocaleDateTime } from '../../../../../../common/helper/dateFormat.helper';

interface IProps {
  qrCode: Sidebar_qrCode | null;
}

const Sidebar = ({ qrCode }: IProps) => {
  if (!qrCode) {
    return (
      <>
        <div className={`${styles.sidebar__qrCode} mb-1`}>
          <SkeletonArea />
        </div>
        <div className="mt-4" style={{ width: '30%' }}>
          <SkeletonTitle />
        </div>
        <div className="mt-2" style={{ width: '60%' }}>
          <SkeletonTitle />
        </div>
        <div className="mt-2" style={{ width: '50%' }}>
          <SkeletonTitle />
        </div>
        <div className="mt-2" style={{ width: '100%' }}>
          <SkeletonTitle />
        </div>
      </>
    );
  }

  const { serialNumber, isDynamic, scanCount, updatedAt } = qrCode;

  return (
    <>
      <div className={`${styles.sidebar__qrCode} mb-1`}>
        <QrCodeWithDownload qrCode={qrCode} />
      </div>
      <label>
        Scans
        <input type="text" value={scanCount ? scanCount : '0'} disabled />
      </label>
      <label>
        Serial number
        <input type="text" value={serialNumber} disabled />
      </label>
      <label>
        Type
        <input type="text" value={isDynamic ? 'dynamic' : 'static'} disabled />
      </label>
      <label>
        Last Change
        <input type="text" value={updatedAt ? toLocaleDateTime(updatedAt) : ''} disabled />
      </label>
    </>
  );
};

export default createFragmentContainer(Sidebar, {
  qrCode: graphql`
    fragment Sidebar_qrCode on QrCode {
      accessKey
      serialNumber
      updatedAt
      type
      isDynamic
      ... on RedirectQrCode {
        scanCount
      }
      ...QrCodeWithDownload_qrCode
    }
  `,
});
