import React from 'react';
import { Field } from 'formik';
import { QueryRenderer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../../../graphql/environment';
import { StateSelectQuery } from '../../../__generated__/StateSelectQuery.graphql';

interface IProps {
  name: string;
  countryCode: string;
}

const stateQuery = graphql`
  query StateSelectQuery($code: String!) {
    provinces(code: $code) {
      edges {
        name
      }
    }
  }
`;

const StateSelect: React.SFC<IProps> = ({ countryCode, name }: IProps) => {
  return (
    <QueryRenderer<StateSelectQuery>
      environment={environment}
      variables={{ code: countryCode }}
      query={stateQuery}
      render={({ props }) => {
        return (
          <Field as="select" name={name}>
            {props?.provinces ? <option>Select State</option> : <option>Loading...</option>}
            {props?.provinces.edges.map(c => (
              <option key={c?.name} value={c?.name}>
                {c?.name}
              </option>
            ))}
          </Field>
        );
      }}
    />
  );
};

export default StateSelect;
