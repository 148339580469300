/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
export type StateSelectQueryVariables = {
  code: string;
};
export type StateSelectQueryResponse = {
  readonly provinces: {
    readonly edges: ReadonlyArray<{
      readonly name: string;
    } | null>;
  };
};
export type StateSelectQuery = {
  readonly response: StateSelectQueryResponse;
  readonly variables: StateSelectQueryVariables;
};

/*
query StateSelectQuery(
  $code: String!
) {
  provinces(code: $code) {
    edges {
      name
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'code',
        type: 'String!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'code',
            variableName: 'code',
          },
        ],
        concreteType: 'ProvinceConnection',
        kind: 'LinkedField',
        name: 'provinces',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Province',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'name',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'StateSelectQuery',
      selections: v1 /*: any*/,
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'StateSelectQuery',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'StateSelectQuery',
      operationKind: 'query',
      text:
        'query StateSelectQuery(\n  $code: String!\n) {\n  provinces(code: $code) {\n    edges {\n      name\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '1794ac0127e345e617d638a25014cf2a';
export default node;
