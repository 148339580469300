/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type TenantSelect_user = {
  readonly tenants: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
  }>;
  readonly ' $refType': 'TenantSelect_user';
};
export type TenantSelect_user$data = TenantSelect_user;
export type TenantSelect_user$key = {
  readonly ' $data'?: TenantSelect_user$data;
  readonly ' $fragmentRefs': FragmentRefs<'TenantSelect_user'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'TenantSelect_user',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Tenant',
      kind: 'LinkedField',
      name: 'tenants',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ],
  type: 'User',
};
(node as any).hash = '35b9512ff1eff147f62d1c5a4d7ee862';
export default node;
