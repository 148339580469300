import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { env } from '../../../common/helper/environment.helper';

export default class TicketService {
  static async getTickets(tenantId: string, query: string) {
    const session = await Auth.currentSession();
    const baseUrl = env('REACT_APP_API_BASE_PATH');

    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: session.getIdToken().getJwtToken(),
      },
    };

    return await axios.get(`${baseUrl}/support/tickets/${tenantId}?query=${query}`, config);
  }

  static async getTicket(tenantId: string, id: string) {
    const session = await Auth.currentSession();
    const baseUrl = env('REACT_APP_API_BASE_PATH');

    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: session.getIdToken().getJwtToken(),
      },
    };

    return await axios.get(`${baseUrl}/support/tickets/${tenantId}/${id}`, config);
  }
}
