/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type Sidebar_user = {
  readonly ' $fragmentRefs': FragmentRefs<'UserMenu_user'>;
  readonly ' $refType': 'Sidebar_user';
};
export type Sidebar_user$data = Sidebar_user;
export type Sidebar_user$key = {
  readonly ' $data'?: Sidebar_user$data;
  readonly ' $fragmentRefs': FragmentRefs<'Sidebar_user'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Sidebar_user',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'UserMenu_user',
    },
  ],
  type: 'User',
};
(node as any).hash = '1f8ef1812dc2c6680acc6393a82e4cf8';
export default node;
