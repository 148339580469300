import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './Section.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  headline: string;
  children: ReactNode;
  editLinkDest?: string;
}

const Section: React.SFC<IProps> = ({ headline, children, editLinkDest }) => {
  const { t } = useTranslation(['qrpid']);

  return (
    <div className="cell small-12 medium-6 mb-3">
      <div className={styles.section__header}>
        <h3 className={`mb-0 ${styles.section__headline}`}>{headline}</h3>
        {editLinkDest && (
          <Link
            to={editLinkDest}
            style={{ alignSelf: 'center' }}
            className="button primary clear mb-0 pr-0 py-1"
          >
            {t('account.accountPage.edit')}
          </Link>
        )}
      </div>
      <hr className="mt-1 mb-2 mx-0" />
      {children}
    </div>
  );
};

export default Section;
