/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type BillingType = 'company' | 'person' | '%future added value';
export type BillingInformationEditPageQueryVariables = {
  id: string;
  language: string;
};
export type BillingInformationEditPageQueryResponse = {
  readonly tenant: {
    readonly billingInformation: {
      readonly type: BillingType;
      readonly firstName: string;
      readonly lastName: string;
      readonly email: string;
      readonly phone: string | null;
      readonly company: string | null;
      readonly street: string;
      readonly streetNumber: string | null;
      readonly country: string;
      readonly city: string;
      readonly zipCode: string;
      readonly vat: string | null;
      readonly state: string | null;
    } | null;
  } | null;
  readonly countries: {
    readonly ' $fragmentRefs': FragmentRefs<'CountrySelect_countries'>;
  };
};
export type BillingInformationEditPageQuery = {
  readonly response: BillingInformationEditPageQueryResponse;
  readonly variables: BillingInformationEditPageQueryVariables;
};

/*
query BillingInformationEditPageQuery(
  $id: ID!
  $language: String!
) {
  tenant(id: $id) {
    billingInformation {
      type
      firstName
      lastName
      email
      phone
      company
      street
      streetNumber
      country
      city
      zipCode
      vat
      state
    }
    id
  }
  countries(language: $language) {
    ...CountrySelect_countries
  }
}

fragment CountrySelect_countries on CountryConnection {
  edges {
    code
    name
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'language',
        type: 'String!',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      concreteType: 'BillingInformation',
      kind: 'LinkedField',
      name: 'billingInformation',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'type',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'firstName',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'lastName',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'email',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'phone',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'company',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'street',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'streetNumber',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'country',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'city',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'zipCode',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'vat',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'state',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v3 = [
      {
        kind: 'Variable',
        name: 'language',
        variableName: 'language',
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'BillingInformationEditPageQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Tenant',
          kind: 'LinkedField',
          name: 'tenant',
          plural: false,
          selections: [v2 /*: any*/],
          storageKey: null,
        },
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'CountryConnection',
          kind: 'LinkedField',
          name: 'countries',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'CountrySelect_countries',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'BillingInformationEditPageQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Tenant',
          kind: 'LinkedField',
          name: 'tenant',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'CountryConnection',
          kind: 'LinkedField',
          name: 'countries',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'Country',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'code',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'name',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'BillingInformationEditPageQuery',
      operationKind: 'query',
      text:
        'query BillingInformationEditPageQuery(\n  $id: ID!\n  $language: String!\n) {\n  tenant(id: $id) {\n    billingInformation {\n      type\n      firstName\n      lastName\n      email\n      phone\n      company\n      street\n      streetNumber\n      country\n      city\n      zipCode\n      vat\n      state\n    }\n    id\n  }\n  countries(language: $language) {\n    ...CountrySelect_countries\n  }\n}\n\nfragment CountrySelect_countries on CountryConnection {\n  edges {\n    code\n    name\n  }\n}\n',
    },
  };
})();
(node as any).hash = '87aa012eab9c6186ce9003bb68d2f18b';
export default node;
