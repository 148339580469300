/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
export type HomePageQueryVariables = {};
export type HomePageQueryResponse = {
  readonly user: {
    readonly tenants: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
};
export type HomePageQuery = {
  readonly response: HomePageQueryResponse;
  readonly variables: HomePageQueryVariables;
};

/*
query HomePageQuery {
  user {
    tenants {
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v1 = {
      alias: null,
      args: null,
      concreteType: 'Tenant',
      kind: 'LinkedField',
      name: 'tenants',
      plural: true,
      selections: [v0 /*: any*/],
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'HomePageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'User',
          kind: 'LinkedField',
          name: 'user',
          plural: false,
          selections: [v1 /*: any*/],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'HomePageQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'User',
          kind: 'LinkedField',
          name: 'user',
          plural: false,
          selections: [v1 /*: any*/, v0 /*: any*/],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'HomePageQuery',
      operationKind: 'query',
      text: 'query HomePageQuery {\n  user {\n    tenants {\n      id\n    }\n    id\n  }\n}\n',
    },
  };
})();
(node as any).hash = '63cd762ccc277b7d0454b7101082133e';
export default node;
