import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape<any>({
    email: Yup.string()
      .required(t('auth.validation.email.required'))
      .email(t('auth.validation.email.valid')),
    password: Yup.string()
      .required(t('auth.validation.password.required'))
      .min(6, t('auth.validation.password.min'))
      .matches(/[A-Z]/, t('auth.validation.password.uppercase'))
      .matches(/[a-z]/, t('auth.validation.password.lowercase'))
      .matches(/[0-9]/, t('auth.validation.password.digit'))
      .matches(/[\^$*.[\]{}()?\-\\"!@#%&/\\,><':;|_~`]/, t('auth.validation.password.special')),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], t('auth.validation.password.match')),
  });
