import React from 'react';
import styles from './DashboardToolbar.module.scss';
import Edit from '../../../Icons/Edit';
import { isMobile } from 'react-device-detect';

interface IProps {
  onClickEdit?: Function;
}

const DashboardToolbar = ({ onClickEdit }: IProps) => {
  return (
    <div className={`${styles.dashboardToolbar} mb-2`}>
      <ul className={styles.dashboardToolbar__list}>
        {onClickEdit ? (
          <li>
            <button
              className={`button light small mb-0 ${isMobile ? 'hide' : 'show-for-medium'}`}
              onClick={() => onClickEdit()}
            >
              <Edit className={styles.dashboardToolbar__btn__icon} />
              <span>Edit</span>
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default DashboardToolbar;
