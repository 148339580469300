/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type UserMenu_tenant = {
  readonly id: string;
  readonly permissions: ReadonlyArray<{
    readonly name: string;
  }> | null;
  readonly ' $refType': 'UserMenu_tenant';
};
export type UserMenu_tenant$data = UserMenu_tenant;
export type UserMenu_tenant$key = {
  readonly ' $data'?: UserMenu_tenant$data;
  readonly ' $fragmentRefs': FragmentRefs<'UserMenu_tenant'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'UserMenu_tenant',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      concreteType: 'Permission',
      kind: 'LinkedField',
      name: 'permissions',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ],
  type: 'Tenant',
};
(node as any).hash = 'ff40d31f4f2ab30e054e5dd9f5d4ac94';
export default node;
