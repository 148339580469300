import React from 'react';
import { ForgotPassword } from 'aws-amplify-react/lib/Auth';
import AuthFormContainer from '../AuthFormContainer';
import Auth from '@aws-amplify/auth';
import EmailForm from './components/EmailForm';
import CodeForm from './components/CodeForm';
import { Translation } from 'react-i18next';
import { TFunction } from 'i18next';
import { withRouter } from 'react-router';

class QrpidForgotPassword extends ForgotPassword {
  async sendCode(t: TFunction, isResend?: boolean) {
    const username = this.getUsernameFromInput() || this.props.authData?.username;

    this.setState(prevState => ({ ...prevState, codeLoading: true }));

    try {
      const data = await Auth.forgotPassword(username);

      this.setState(prevState => ({
        ...prevState,
        delivery: data.CodeDeliveryDetails,
        successMessage: t(`auth.alerts.success.${!isResend ? 'sentCode' : 'resentCode'}`),
      }));
    } catch (err) {
      this.error(err);
    } finally {
      this.setState(prevState => ({ ...prevState, codeLoading: false }));
    }
  }

  async submitPassword(t: TFunction) {
    const { history } = this.props as any;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || this.props.authData?.username;

    this.setState(prevState => ({ ...prevState, submitLoading: true }));

    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      history.push({
        pathname: '/sign-in',
        state: {
          successMessage: t(`auth.alerts.success.passwordChanged`),
        },
      });

      this.setState({ delivery: null });
    } catch (err) {
      this.error(err);
    } finally {
      this.setState(prevState => ({ ...prevState, submitLoading: false }));
    }
  }

  renderSend(t: TFunction) {
    const { codeLoading } = (this.state as any) ?? {};

    return (
      <EmailForm
        isLoading={codeLoading}
        onSubmit={async values => {
          this.inputs.email = values.email.toLowerCase();
          await this.sendCode(t, false);
        }}
      />
    );
  }

  renderSubmit(t: TFunction) {
    const { submitLoading, codeLoading } = (this.state as any) ?? {};

    return (
      <CodeForm
        isLoading={submitLoading}
        isResendLoading={codeLoading}
        onSubmit={async values => {
          this.inputs.code = values.code;
          this.inputs.password = values.password;
          await this.submitPassword(t);
        }}
        onResendCode={() => this.sendCode(t, true)}
      />
    );
  }

  showComponent() {
    const { loading, successMessage } = (this.state as any) ?? {};

    return (
      <Translation>
        {t => (
          <AuthFormContainer
            loading={loading ?? false}
            headline={t('auth.forgotPassword.headline')}
            successMessage={successMessage}
          >
            {this.state.delivery || this.props.authData?.username
              ? this.renderSubmit(t)
              : this.renderSend(t)}
          </AuthFormContainer>
        )}
      </Translation>
    );
  }
}

export default withRouter(QrpidForgotPassword as any);
