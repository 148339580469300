import * as Yup from 'yup';
import { IRedirectForm } from './redirect-form';

export const validationSchema = Yup.object().shape<IRedirectForm>({
  redirectUrl: Yup.string()
    .matches(
      /^(http:\/\/|https:\/\/)/,
      'Please enter a valid website address that starts with https:// or http://.'
    )
    .url('Please enter a valid website address.')
    .when('enabled', {
      is: true,
      then: Yup.string().required('Please enter a website to activate a QR-Code.'),
    }),
  enabled: Yup.boolean().required(),
  type: Yup.mixed(),
  comment: Yup.string().max(256, 'Name is too long - should be 256 characters maximum.'),
  name: Yup.string().max(32, 'Name is too long - should be 32 characters maximum.'),
});
