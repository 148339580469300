import React, { useRef, useEffect } from 'react';
import qrCode from 'qrcode';

interface IProps {
  content: string;
  backgroundColor?: string;
}

const QrCode = ({ content, backgroundColor = '#f3f3f3' }: IProps) => {
  const canvas = useRef(null);

  useEffect(() => {
    qrCode.toCanvas(canvas.current, content, {
      margin: 0,
      width: 150,
      color: { light: backgroundColor },
    });
  }, [canvas, content, backgroundColor]);

  return <canvas ref={canvas} />;
};

export default QrCode;
