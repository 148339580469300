import React, { ReactNode } from 'react';
import styles from './CollapsibleMenuItemHeader.module.scss';
import Arrow from '../../../Icons/Arrow';

interface IProps {
  children: ReactNode;
  isOpen: boolean;
}

const CollapsibleMenuHeader = (props: IProps) => (
  <div
    className={`${styles.collapsibleMenuHeader} ${
      props.isOpen ? styles.collapsibleMenuHeader__open : ''
    }`}
  >
    {props.children}

    <Arrow
      className={`${styles.collapsibleMenuHeader__icon} ${
        styles.collapsibleMenuHeader__icon__arrow
      } ${props.isOpen ? styles.collapsibleMenuHeader__icon__arrow__open : ''}`}
    />
  </div>
);
export default CollapsibleMenuHeader;
