/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type Navbar_user = {
  readonly ' $fragmentRefs': FragmentRefs<'TenantSelect_user'>;
  readonly ' $refType': 'Navbar_user';
};
export type Navbar_user$data = Navbar_user;
export type Navbar_user$key = {
  readonly ' $data'?: Navbar_user$data;
  readonly ' $fragmentRefs': FragmentRefs<'Navbar_user'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Navbar_user',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'TenantSelect_user',
    },
  ],
  type: 'User',
};
(node as any).hash = 'dc2b555c9083dc4e422e10924a85e7d0';
export default node;
