import React from 'react';
import Field from '../../Field';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { BillingInformation_billingInformation } from '../../../../../__generated__/BillingInformation_billingInformation.graphql';
import { useTranslation } from 'react-i18next';

interface IProps {
  billingInformation: BillingInformation_billingInformation;
}

const BillingInformation: React.SFC<IProps> = ({ billingInformation }) => {
  const { t } = useTranslation(['qrpid']);

  return (
    <>
      {billingInformation.company && (
        <Field
          label={t('account.accountPage.billing.company')}
          value={billingInformation.company}
        />
      )}

      <Field
        label={t('account.accountPage.billing.name')}
        value={`${billingInformation.firstName} ${billingInformation.lastName}`}
      />

      <Field
        label={t('account.accountPage.billing.street')}
        value={`${billingInformation.street}`}
      />

      {billingInformation.streetNumber && (
        <Field label={''} value={`${billingInformation.streetNumber}`} />
      )}

      <Field
        label={t('account.accountPage.billing.city')}
        value={`${billingInformation?.zipCode} ${billingInformation.city}`}
      />
    </>
  );
};

export default createFragmentContainer(BillingInformation, {
  billingInformation: graphql`
    fragment BillingInformation_billingInformation on BillingInformation {
      type
      firstName
      lastName
      company
      city
      zipCode
      street
      streetNumber
    }
  `,
});
