import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { toLocalDate } from '../../../common/helper/dateFormat.helper';
import ChartWidget from '../ChartWidget';
import { EChartType } from '../../Chart/lib/chart-type.model';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { LatestScansChartWidget_aggregations } from '../../../__generated__/LatestScansChartWidget_aggregations.graphql';
import SkeletonWidget from '../SkeletonWidget';

interface IProps {
  aggregations: LatestScansChartWidget_aggregations | null;
  showHeadline?: boolean;
}

const createLabels = (aggregations: LatestScansChartWidget_aggregations): string[] => {
  if (!aggregations.edges) {
    return [];
  }

  return aggregations.edges
    .map(a => (a ? toLocalDate(a.node.timestamp) : ''))
    .filter(a => a !== '');
};

const createDatasets = (aggregations: LatestScansChartWidget_aggregations): ChartDataSets[] => {
  if (!aggregations.edges) {
    return [];
  }

  const data = aggregations.edges.map(a => (a ? a.node.count : 0));

  return [{ data, label: 'Scans' }];
};

const chartOptions: ChartOptions = {
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Scans per day',
        },
        gridLines: {
          display: true,
        },
      },
    ],
  },
};

const LatestScansChartWidget = ({ aggregations, showHeadline }: IProps) => {
  if (!aggregations) {
    return (
      <SkeletonWidget
        headline={showHeadline ? 'Scans of the last 30 days' : undefined}
        missingDataText={''}
      >
        <>Loading...</>
      </SkeletonWidget>
    );
  }

  return (
    <ChartWidget
      type={EChartType.LineChart}
      headline={showHeadline ? 'Scans of the last 30 days' : undefined}
      data={{ labels: createLabels(aggregations), datasets: createDatasets(aggregations) }}
      options={chartOptions}
      missingDataText={`No scans during the last 30 days.`}
    />
  );
};

export default createFragmentContainer(LatestScansChartWidget, {
  aggregations: graphql`
    fragment LatestScansChartWidget_aggregations on AggregationConnection {
      edges {
        node {
          timestamp
          count
        }
      }
      retentionTimeInDays
    }
  `,
});
