export const PERMISSION_DASHBOARD_READ = 'dashboard_read';
export const PERMISSION_BILLING_READ = 'billing_read';
export const PERMISSION_BILLING_UPDATE = 'billing_update';
export const PERMISSION_QRCODE_CREAT = 'qrcode_create';
export const PERMISSION_QRCODE_READ = 'qrcode_read';
export const PERMISSION_QRCODE_UPDATE = 'qrcode_update';
export const PERMISSION_QRCODE_DELETE = 'qrcode_delete';
export const PERMISSION_SUPPORT_READ = 'support_read';
export const PERMISSION_SUPPORT_UPDATE = 'support_update';
export const PERMISSION_SUPPORT_DELETE = 'support_delete';
export const PERMISSION_PRODUCT_CREATE = 'product_create';
export const PERMISSION_PRODUCT_READ = 'product_read';
export const PERMISSION_PRODUCT_UPDATE = 'product_update';
export const PERMISSION_PRODUCT_DELETE = 'product_delete';
