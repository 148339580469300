import React from 'react';
import { Field } from 'formik';

interface IProps {
  label: string;
  name: string;
  placeholder: string;
  rows: number;
  touched?: boolean;
  error?: string;
}

const Textarea = ({ label, name, placeholder, rows, touched, error }: IProps) => {
  return (
    <label className={touched && error ? 'is-invalid-label' : ''}>
      {label}
      <Field
        component="textarea"
        name={name}
        className={touched && error ? 'is-invalid-input' : ''}
        placeholder={placeholder}
        rows={rows}
      />
      {touched && error ? <span className="form-error is-visible">{error}</span> : null}
    </label>
  );
};

export default Textarea;
