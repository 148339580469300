import React from 'react';
import BurgerMenu from '../../../../../Icons/BurgerMenu';
import styles from './ResponsiveMenuButton.module.scss';

interface IProps {
  onClick: () => void;
}

const ResponsiveMenuButton = ({ onClick }: IProps) => {
  return (
    <div className={styles.responsiveMenuButton} onClick={onClick}>
      <BurgerMenu className={styles.responsiveMenuButton__icon} />
    </div>
  );
};

export default ResponsiveMenuButton;
