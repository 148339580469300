import React from 'react';
import HighlightedContainer from '../HighlightedContainer';
import Avatar from '../Avatar';
import styles from './UserProfile.module.scss';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { UserProfile_user } from '../../__generated__/UserProfile_user.graphql';

interface IProps {
  user: UserProfile_user | null | undefined;
}

const UserProfile = ({ user }: IProps) => {
  return (
    <HighlightedContainer>
      <section className={styles.userProfile__container}>
        <div className={styles.userProfile__avatar__container}>
          <Avatar email={user && user.email} className={styles.userProfile__avatar} />
        </div>

        <label>
          Firstname
          <input
            type="text"
            name="firstname"
            disabled={true}
            defaultValue={(user && user.firstname) ?? ''}
          />
        </label>

        <label>
          Lastname
          <input
            type="text"
            name="lastname"
            disabled={true}
            defaultValue={(user && user.lastname) ?? ''}
          />
        </label>

        <label>
          E-Mail
          <input
            type="text"
            name="email"
            disabled={true}
            defaultValue={(user && user.email) ?? ''}
          />
        </label>
      </section>
    </HighlightedContainer>
  );
};

export default createFragmentContainer(UserProfile, {
  user: graphql`
    fragment UserProfile_user on User {
      email
      firstname
      lastname
    }
  `,
});
