import React, { ReactNode } from 'react';
import styles from './Widget.module.scss';
import HighlightedContainer from '../../HighlightedContainer';
import Overlay from '../../Overlay';

interface IProps {
  headline?: string;
  children?: ReactNode;
  noPadding?: boolean;
  missingDataText?: string;
}

const Widget = ({ headline, children, noPadding, missingDataText }: IProps) => {
  return (
    <HighlightedContainer className={styles.widget} padding={false}>
      {headline ? <div className={styles.widget__headline}>{headline}</div> : null}
      <div className={`${styles.widget__content} ${!noPadding ? 'mx-1 mb-1' : ''}`}>
        {children}
        {missingDataText ? <Overlay text={missingDataText} /> : null}
      </div>
    </HighlightedContainer>
  );
};

export default Widget;
