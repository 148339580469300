import React from 'react';
import { ModuleHeader } from '../../../../components/Module';
import { useTranslation } from 'react-i18next';
import QrCodeNew from '../../components/QrCodeNew';

const QrCodeNewPage = () => {
  const { t } = useTranslation(['qrpid']);

  return (
    <div className="cell small-12">
      <ModuleHeader
        title={t('qrcodes.new.title')}
        description={t('qrcodes.description')}
        link={''}
      />

      <QrCodeNew />
    </div>
  );
};

export default QrCodeNewPage;
