/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
export type QrCodeType = 'REDIRECT' | 'URL' | 'WIFI' | '%future added value';
export type WifiEncryptionType = 'NONE' | 'WEP' | 'WPA' | '%future added value';
export type CreateQrCodeInput = {
  clientMutationId?: string | null;
  tenantId: string;
  type: QrCodeType;
  enabled?: boolean | null;
  comment?: string | null;
  name?: string | null;
  redirectUrl?: string | null;
  ssid?: string | null;
  password?: string | null;
  encryption?: WifiEncryptionType | null;
  url?: string | null;
};
export type createQrCodeMutationVariables = {
  input: CreateQrCodeInput;
};
export type createQrCodeMutationResponse = {
  readonly createQrCode: {
    readonly qrCode: {
      readonly id: string;
    };
  } | null;
};
export type createQrCodeMutation = {
  readonly response: createQrCodeMutationResponse;
  readonly variables: createQrCodeMutationVariables;
};

/*
mutation createQrCodeMutation(
  $input: CreateQrCodeInput!
) {
  createQrCode(input: $input) {
    qrCode {
      __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'CreateQrCodeInput!',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'input',
        variableName: 'input',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'createQrCodeMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'CreatePayload',
          kind: 'LinkedField',
          name: 'createQrCode',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: 'LinkedField',
              name: 'qrCode',
              plural: false,
              selections: [v2 /*: any*/],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'createQrCodeMutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'CreatePayload',
          kind: 'LinkedField',
          name: 'createQrCode',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: null,
              kind: 'LinkedField',
              name: 'qrCode',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
                v2 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'createQrCodeMutation',
      operationKind: 'mutation',
      text:
        'mutation createQrCodeMutation(\n  $input: CreateQrCodeInput!\n) {\n  createQrCode(input: $input) {\n    qrCode {\n      __typename\n      id\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = '67bb2ed0c99c05c931fff848aca09b40';
export default node;
