import { IBreadcrumb } from '../components/Breadcrumbs/lib/breadcrumb';
import {
  dashboardRoute,
  qrCodesRoute,
  qrCodeDetailsRoute,
  ticketsRoute,
  ticketDetailsRoute,
  userProfileRoute,
  accountRoute,
  billingInformationRoute,
  // productsRoute,
  // productDetailsRoute,
  // productNewRoute,
  // productEditRoute,
} from './routes';

export interface IBreadcrumbsRouteMapping {
  route: string;
  breadcrumbs: IBreadcrumb[];
}

export const breadcrumbsRouteMapping: IBreadcrumbsRouteMapping[] = [
  {
    route: dashboardRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'Dashboard', url: '' },
    ],
  },
  {
    route: qrCodesRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'Manage QR Codes', url: '' },
    ],
  },
  {
    route: qrCodeDetailsRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'Manage QR Codes', url: qrCodesRoute },
      { name: 'Details', url: '' },
    ],
  },
  {
    route: userProfileRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'User', url: '' },
      { name: 'Profile', url: '' },
    ],
  },
  // {
  //   route: productsRoute,
  //   breadcrumbs: [
  //     { name: 'QRPid', url: '/' },
  //     { name: 'Products', url: '' },
  //   ],
  // },
  // {
  //   route: productNewRoute,
  //   breadcrumbs: [
  //     { name: 'QRPid', url: '/' },
  //     { name: 'Products', url: productsRoute },
  //     { name: 'New', url: '' },
  //   ],
  // },
  // {
  //   route: productEditRoute,
  //   breadcrumbs: [
  //     { name: 'QRPid', url: '/' },
  //     { name: 'Products', url: productsRoute },
  //     { name: 'Edit', url: '' },
  //   ],
  // },
  // {
  //   route: productDetailsRoute,
  //   breadcrumbs: [
  //     { name: 'QRPid', url: '/' },
  //     { name: 'Products', url: productsRoute },
  //     { name: 'View', url: '' },
  //   ],
  // },
  {
    route: ticketsRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'Manage Tickets', url: '' },
    ],
  },
  {
    route: ticketDetailsRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'Manage Tickets', url: ticketsRoute },
      { name: 'Details', url: '' },
    ],
  },
  {
    route: accountRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'Manage Account', url: '' },
    ],
  },
  {
    route: billingInformationRoute,
    breadcrumbs: [
      { name: 'QRPid', url: '/' },
      { name: 'Manage Account', url: accountRoute },
      { name: 'Billing Information', url: '' },
    ],
  },
];
