import { IRoute } from './route';
import { routes } from '../../pages/routes';
import { RouteType } from './route-type';
import { matchPath } from 'react-router-dom';

export function getRouteFromUrl(url: string): IRoute {
  const matchedRoutes = routes.filter(r =>
    matchPath(url, { path: r.path, exact: r.type === RouteType.Exact })
  );

  if (matchedRoutes.length <= 0) {
    throw new Error(`URL couldn't be matched. URL: ${url}`);
  }

  return matchedRoutes[0];
}

export function getParamsFromUrl(url: string): any {
  const params = routes
    .map(r => {
      const match = matchPath(url, { path: r.path, exact: r.type === RouteType.Exact });
      return match && match.params ? match.params : undefined;
    })
    .filter(m => m);

  if (params.length <= 0) {
    throw new Error(`URL couldn't be matched. URL: ${url}`);
  }

  return params[0];
}
