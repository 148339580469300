/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeDetailsPageQueryVariables = {
  id: string;
  tenantId: string;
};
export type QrCodeDetailsPageQueryResponse = {
  readonly node: {
    readonly ' $fragmentRefs': FragmentRefs<'QrCodeDetails_qrCode'>;
  } | null;
  readonly scans: {
    readonly ' $fragmentRefs': FragmentRefs<'QrCodeDetails_scans'>;
  } | null;
  readonly aggregations: {
    readonly ' $fragmentRefs': FragmentRefs<'QrCodeDetails_aggregations'>;
  } | null;
};
export type QrCodeDetailsPageQuery = {
  readonly response: QrCodeDetailsPageQueryResponse;
  readonly variables: QrCodeDetailsPageQueryVariables;
};

/*
query QrCodeDetailsPageQuery(
  $id: ID!
  $tenantId: String!
) {
  node(id: $id, tenantId: $tenantId) {
    __typename
    ... on QrCode {
      ...QrCodeDetails_qrCode
    }
    id
  }
  scans(tenantId: $tenantId, qrCodeId: $id, first: 5) {
    ...QrCodeDetails_scans
  }
  aggregations(tenantId: $tenantId, id: $id, first: 30) {
    ...QrCodeDetails_aggregations
  }
}

fragment LatestScansChartWidget_aggregations on AggregationConnection {
  edges {
    node {
      timestamp
      count
    }
  }
  retentionTimeInDays
}

fragment LatestScansTableWidget_scans on ScanConnection {
  edges {
    node {
      timestamp
      country
      city
      browser
      device
      os
      qrCodeId
      id
    }
  }
  retentionTimeInDays
}

fragment QrCodeDetails_aggregations on AggregationConnection {
  ...LatestScansChartWidget_aggregations
}

fragment QrCodeDetails_qrCode on QrCode {
  id
  tenantId
  type
  isDynamic
  comment
  name
  ... on RedirectQrCode {
    enabled
    redirectUrl
  }
  ...UrlForm_qrCode
  ...WifiForm_qrCode
  ...Sidebar_qrCode
}

fragment QrCodeDetails_scans on ScanConnection {
  ...LatestScansTableWidget_scans
}

fragment QrCodeWithDownload_qrCode on QrCode {
  serialNumber
  type
  isDynamic
  ... on RedirectQrCode {
    qrCodeUrl
  }
  ... on UrlQrCode {
    url
  }
  ... on WifiQrCode {
    ssid
    password
    encryption
  }
}

fragment Sidebar_qrCode on QrCode {
  accessKey
  serialNumber
  updatedAt
  type
  isDynamic
  ... on RedirectQrCode {
    scanCount
  }
  ...QrCodeWithDownload_qrCode
}

fragment UrlForm_qrCode on QrCode {
  type
  isDynamic
  name
  ... on UrlQrCode {
    url
  }
}

fragment WifiForm_qrCode on QrCode {
  type
  isDynamic
  name
  ... on WifiQrCode {
    ssid
    password
    encryption
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'tenantId',
        type: 'String!',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'id',
      variableName: 'id',
    },
    v2 = {
      kind: 'Variable',
      name: 'tenantId',
      variableName: 'tenantId',
    },
    v3 = [v1 /*: any*/, v2 /*: any*/],
    v4 = [
      {
        kind: 'Literal',
        name: 'first',
        value: 5,
      },
      {
        kind: 'Variable',
        name: 'qrCodeId',
        variableName: 'id',
      },
      v2 /*: any*/,
    ],
    v5 = [
      {
        kind: 'Literal',
        name: 'first',
        value: 30,
      },
      v1 /*: any*/,
      v2 /*: any*/,
    ],
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timestamp',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'retentionTimeInDays',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'QrCodeDetailsPageQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'QrCodeDetails_qrCode',
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: 'ScanConnection',
          kind: 'LinkedField',
          name: 'scans',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'QrCodeDetails_scans',
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v5 /*: any*/,
          concreteType: 'AggregationConnection',
          kind: 'LinkedField',
          name: 'aggregations',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'QrCodeDetails_aggregations',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'QrCodeDetailsPageQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v6 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'tenantId',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'type',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'isDynamic',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'comment',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'name',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'accessKey',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'serialNumber',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'updatedAt',
              storageKey: null,
            },
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'enabled',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'redirectUrl',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'scanCount',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'qrCodeUrl',
                  storageKey: null,
                },
              ],
              type: 'RedirectQrCode',
            },
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'url',
                  storageKey: null,
                },
              ],
              type: 'UrlQrCode',
            },
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'ssid',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'password',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'encryption',
                  storageKey: null,
                },
              ],
              type: 'WifiQrCode',
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v4 /*: any*/,
          concreteType: 'ScanConnection',
          kind: 'LinkedField',
          name: 'scans',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'ScanEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'Scan',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v7 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'country',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'city',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'browser',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'device',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'os',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'qrCodeId',
                      storageKey: null,
                    },
                    v6 /*: any*/,
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            v8 /*: any*/,
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v5 /*: any*/,
          concreteType: 'AggregationConnection',
          kind: 'LinkedField',
          name: 'aggregations',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'AggregationEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'AggregationItem',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v7 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'count',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            v8 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'QrCodeDetailsPageQuery',
      operationKind: 'query',
      text:
        'query QrCodeDetailsPageQuery(\n  $id: ID!\n  $tenantId: String!\n) {\n  node(id: $id, tenantId: $tenantId) {\n    __typename\n    ... on QrCode {\n      ...QrCodeDetails_qrCode\n    }\n    id\n  }\n  scans(tenantId: $tenantId, qrCodeId: $id, first: 5) {\n    ...QrCodeDetails_scans\n  }\n  aggregations(tenantId: $tenantId, id: $id, first: 30) {\n    ...QrCodeDetails_aggregations\n  }\n}\n\nfragment LatestScansChartWidget_aggregations on AggregationConnection {\n  edges {\n    node {\n      timestamp\n      count\n    }\n  }\n  retentionTimeInDays\n}\n\nfragment LatestScansTableWidget_scans on ScanConnection {\n  edges {\n    node {\n      timestamp\n      country\n      city\n      browser\n      device\n      os\n      qrCodeId\n      id\n    }\n  }\n  retentionTimeInDays\n}\n\nfragment QrCodeDetails_aggregations on AggregationConnection {\n  ...LatestScansChartWidget_aggregations\n}\n\nfragment QrCodeDetails_qrCode on QrCode {\n  id\n  tenantId\n  type\n  isDynamic\n  comment\n  name\n  ... on RedirectQrCode {\n    enabled\n    redirectUrl\n  }\n  ...UrlForm_qrCode\n  ...WifiForm_qrCode\n  ...Sidebar_qrCode\n}\n\nfragment QrCodeDetails_scans on ScanConnection {\n  ...LatestScansTableWidget_scans\n}\n\nfragment QrCodeWithDownload_qrCode on QrCode {\n  serialNumber\n  type\n  isDynamic\n  ... on RedirectQrCode {\n    qrCodeUrl\n  }\n  ... on UrlQrCode {\n    url\n  }\n  ... on WifiQrCode {\n    ssid\n    password\n    encryption\n  }\n}\n\nfragment Sidebar_qrCode on QrCode {\n  accessKey\n  serialNumber\n  updatedAt\n  type\n  isDynamic\n  ... on RedirectQrCode {\n    scanCount\n  }\n  ...QrCodeWithDownload_qrCode\n}\n\nfragment UrlForm_qrCode on QrCode {\n  type\n  isDynamic\n  name\n  ... on UrlQrCode {\n    url\n  }\n}\n\nfragment WifiForm_qrCode on QrCode {\n  type\n  isDynamic\n  name\n  ... on WifiQrCode {\n    ssid\n    password\n    encryption\n  }\n}\n',
    },
  };
})();
(node as any).hash = '110c72de5286225c5dbf4804be448990';
export default node;
