/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ActivationAlert_qrCodeSubscription = {
  readonly maxQrCodeActivations: number;
  readonly currentQrCodeActivations: number;
  readonly ' $refType': 'ActivationAlert_qrCodeSubscription';
};
export type ActivationAlert_qrCodeSubscription$data = ActivationAlert_qrCodeSubscription;
export type ActivationAlert_qrCodeSubscription$key = {
  readonly ' $data'?: ActivationAlert_qrCodeSubscription$data;
  readonly ' $fragmentRefs': FragmentRefs<'ActivationAlert_qrCodeSubscription'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ActivationAlert_qrCodeSubscription',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'maxQrCodeActivations',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'currentQrCodeActivations',
      storageKey: null,
    },
  ],
  type: 'QrCodeSubscription',
};
(node as any).hash = '850ef8f844fdd42344a63b3692e0cf20';
export default node;
