import React from 'react';
import styles from './SkeletonRow.module.scss';
import { SkeletonTitle } from '../../../Skeleton';

interface IProps {
  row: string[];
  width?: number | string | null;
}

const SkeletonRow = ({ row, width }: IProps) => {
  const randomIntFromInterval = (min: number, max: number) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  return (
    <tr className={styles.clickableRow}>
      {row.map((_r, i) => (
        <td key={i}>
          <div style={{ width: width ? `${width}` : `${randomIntFromInterval(20, 80)}%` }}>
            <SkeletonTitle />
          </div>
        </td>
      ))}
    </tr>
  );
};

export default SkeletonRow;
