import React, { ReactNode } from 'react';
import Header from './components/Header';
import styles from './Table.module.scss';

interface IProps {
  columns: string[];
  children: ReactNode;
}

const Table = ({ columns, children }: IProps) => {
  return (
    <>
      <div className={'table-scroll h-100'}>
        <table className={styles.table}>
          <Header columns={columns} />
          <tbody className={styles.table__body}>{children}</tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
