import React from 'react';
import { useDebouncedInput } from '../../../../../../hooks/use-debounced-input.hook';

interface IProps {
  onSearchChange: (search: string) => void;
}

const Search = ({ onSearchChange }: IProps) => {
  const { setInputText, inputText } = useDebouncedInput(onSearchChange, 300);

  return (
    <input
      type="text"
      placeholder="Search"
      onChange={e => setInputText(e.target.value)}
      value={inputText}
    />
  );
};

export default Search;
