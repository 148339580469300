import React, { ReactNode } from 'react';
import HighlightedContainer from '../../components/HighlightedContainer';
import { ModuleHeader } from '../../components/Module';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useSelectedTenantId } from '../../hooks/use-tenant-id.hook';
import { graphql } from 'babel-plugin-relay/macro';
import {
  AccountPageQuery,
  AccountPageQueryResponse,
} from '../../__generated__/AccountPageQuery.graphql';
import { QueryRenderer } from 'react-relay';
import useRouter from 'use-react-router';
import environment from '../../graphql/environment';
import ErrorNotice from '../../components/ErrorNotice';
import { Section } from './components/Section';
import Field from './components/Field';
import { Skeleton } from '../../components/Skeleton';
import { EmptyBillingInformation } from './components/billingInformation/EmptyBillingInformation';
import { BillingInformation } from './components/billingInformation/BillingInformation';

export const accountByTenantId = graphql`
  query AccountPageQuery($id: ID!) {
    tenant(id: $id) {
      billingInformation {
        ...BillingInformation_billingInformation
      }
    }
  }
`;

const renderSectionSkeleton = () => (
  <>
    <Field
      label={<Skeleton style={{ fontSize: '75%', width: '75%' }} />}
      isLoading
      skeletonWidth={'55%'}
    />
    <Field
      label={<Skeleton style={{ fontSize: '75%', width: '55%' }} />}
      isLoading
      skeletonWidth={'70%'}
    />
    <Field
      label={<Skeleton style={{ fontSize: '75%', width: '70%' }} />}
      isLoading
      skeletonWidth={'45%'}
    />
  </>
);

const renderBillingInformationSection = (
  props: AccountPageQueryResponse | null | undefined
): ReactNode => {
  switch (props?.tenant?.billingInformation) {
    case undefined:
      return renderSectionSkeleton();

    case null:
      return <EmptyBillingInformation />;

    default:
      return props?.tenant?.billingInformation ? (
        <BillingInformation billingInformation={props.tenant.billingInformation} />
      ) : null;
  }
};

const AccountPage = () => {
  const { t } = useTranslation(['qrpid']);
  const tenantId = useSelectedTenantId();
  const { match }: RouteComponentProps<any> = useRouter();

  return (
    <>
      <ModuleHeader title={t('account.title')} description={t('account.description')} link={''} />

      <div className="cell small-12 mt-2">
        <HighlightedContainer className="cell small-12" padding={true}>
          <QueryRenderer<AccountPageQuery>
            variables={{
              id: match.params.tenantId,
            }}
            environment={environment}
            query={accountByTenantId}
            render={({ error, props }) => {
              if (error) {
                return (
                  <ErrorNotice
                    errorText={t('account.accountPage.error.headline')}
                    buttonText={t('account.accountPage.error.callToAction')}
                    returnUrl={`/${tenantId}/account`}
                  />
                );
              }

              return (
                <div className="grid-x grid-margin-x">
                  <div className="cell medium-offset-0 small-12 large-offset-1 large-10">
                    <div className="grid-x grid-margin-x">
                      <Section
                        headline={t('account.accountPage.billing.headline')}
                        editLinkDest={`/${tenantId}/account/billing-information`}
                      >
                        {renderBillingInformationSection(props)}
                      </Section>

                      <Section headline={t('account.accountPage.payment.headline')}>
                        <h4 className="mb-0 text-center">{t('account.accountPage.comingSoon')}</h4>
                      </Section>

                      <Section headline={t('account.accountPage.invoices.headline')}>
                        <h4 className="mb-0 text-center">{t('account.accountPage.comingSoon')}</h4>
                      </Section>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </HighlightedContainer>
      </div>
    </>
  );
};

export default AccountPage;
