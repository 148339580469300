import useRouter from 'use-react-router';

export const useSelectedTenantId = (): string => {
  const {
    match: {
      params: { tenantId },
    },
  } = useRouter();

  if (!tenantId) {
    throw new Error(
      'TenantId could not be found. Are you using this Hook outside a <Route /> Component?'
    );
  }

  return tenantId;
};
