import * as yup from 'yup';

export const validationSchema = yup.object({
  company: yup.string().when(['type'], {
    is: 'company',
    then: yup
      .string()
      .required()
      .min(3, 'Company has minimum length of 3 letters.')
      .max(100, 'Company has maximum length of 100 letters.'),
  }),
  vat: yup.string().when(['type'], {
    is: 'company',
    then: yup
      .string()
      .min(4, 'VAT has minimum length of 4 letters.')
      .max(15, 'VAT has maximum length of 15 letters.'),
  }),
  firstName: yup
    .string()
    .required('Entering a first name is required.')
    .min(3, 'First name has minimum length of 3 letters.')
    .max(100, 'First name has maximum length of 100 letters.'),
  lastName: yup
    .string()
    .required('Entering a last name is required.')
    .min(3, 'Last name has minimum length of 3 letters.')
    .max(100, 'Last name has maximum length of 100 letters.'),
  email: yup
    .string()
    .required('Entering an email is required.')
    .email('Please enter a valid email address.'),
  street: yup.string().required('Entering a street is required.'),
  streetNumber: yup.string(),
  zipCode: yup.string().required('Entering a zip code is required.'),
  city: yup.string().required('Entering a city is required.'),
  country: yup.string().required('Entering a country is required.'),
  state: yup.string(),
  phone: yup.string(),
});
