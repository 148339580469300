import React, { PureComponent } from 'react';
import styles from './Menu.module.scss';
import { IMenu } from './lib/menu';
import MenuItem from './components/MenuItem';

interface IProps {
  menus: IMenu[];
  onNavigate: () => void;
}

interface IState {
  isOpen: boolean;
}

class Menu extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { isOpen: false };
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { menus, onNavigate } = this.props;

    return menus.map((m, i) => (
      <ul key={m.name + i} className={styles.menu}>
        <li className={styles.menu__section}>{m.name}</li>
        {m.menuItems.map((mi, j) => (
          <li key={(mi?.name ?? '') + j}>
            <MenuItem menuItem={mi} onNavigate={onNavigate} />
          </li>
        ))}
      </ul>
    ));
  }
}

export default Menu;
