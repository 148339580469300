/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeManagementQueryVariables = {
  tenantId: string;
  count: number;
  cursor?: string | null;
  searchTerm?: string | null;
};
export type QrCodeManagementQueryResponse = {
  readonly ' $fragmentRefs': FragmentRefs<'QrCodeManagement_query'>;
};
export type QrCodeManagementQuery = {
  readonly response: QrCodeManagementQueryResponse;
  readonly variables: QrCodeManagementQueryVariables;
};

/*
query QrCodeManagementQuery(
  $tenantId: String!
  $count: Int!
  $cursor: String
  $searchTerm: String
) {
  ...QrCodeManagement_query
}

fragment ActivationAlert_qrCodeSubscription on QrCodeSubscription {
  maxQrCodeActivations
  currentQrCodeActivations
}

fragment QrCodeManagement_query on Query {
  qrCodes(tenantId: $tenantId, first: $count, after: $cursor, searchTerm: $searchTerm) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...QrCodeRow_qrCode
        id
        __typename
      }
      cursor
    }
  }
  qrCodeSubscription(tenantId: $tenantId) {
    ...ActivationAlert_qrCodeSubscription
  }
}

fragment QrCodeRow_qrCode on QrCode {
  id
  serialNumber
  type
  isDynamic
  name
  comment
  ... on RedirectQrCode {
    enabled
    scanCount
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'tenantId',
        type: 'String!',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'count',
        type: 'Int!',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'cursor',
        type: 'String',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'searchTerm',
        type: 'String',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'tenantId',
      variableName: 'tenantId',
    },
    v2 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'cursor',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'count',
      },
      {
        kind: 'Variable',
        name: 'searchTerm',
        variableName: 'searchTerm',
      },
      v1 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'QrCodeManagementQuery',
      selections: [
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'QrCodeManagement_query',
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'QrCodeManagementQuery',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'QrCodeConnection',
          kind: 'LinkedField',
          name: 'qrCodes',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'PageInfo',
              kind: 'LinkedField',
              name: 'pageInfo',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'hasNextPage',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'hasPreviousPage',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'startCursor',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'endCursor',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'QrCodeEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: null,
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'serialNumber',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'type',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'isDynamic',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'name',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'comment',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: '__typename',
                      storageKey: null,
                    },
                    {
                      kind: 'InlineFragment',
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'enabled',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'scanCount',
                          storageKey: null,
                        },
                      ],
                      type: 'RedirectQrCode',
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'cursor',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v2 /*: any*/,
          filters: ['tenantId', 'searchTerm'],
          handle: 'connection',
          key: 'QrCodeManagement_qrCodes',
          kind: 'LinkedHandle',
          name: 'qrCodes',
        },
        {
          alias: null,
          args: [v1 /*: any*/],
          concreteType: 'QrCodeSubscription',
          kind: 'LinkedField',
          name: 'qrCodeSubscription',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'maxQrCodeActivations',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'currentQrCodeActivations',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'QrCodeManagementQuery',
      operationKind: 'query',
      text:
        'query QrCodeManagementQuery(\n  $tenantId: String!\n  $count: Int!\n  $cursor: String\n  $searchTerm: String\n) {\n  ...QrCodeManagement_query\n}\n\nfragment ActivationAlert_qrCodeSubscription on QrCodeSubscription {\n  maxQrCodeActivations\n  currentQrCodeActivations\n}\n\nfragment QrCodeManagement_query on Query {\n  qrCodes(tenantId: $tenantId, first: $count, after: $cursor, searchTerm: $searchTerm) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        ...QrCodeRow_qrCode\n        id\n        __typename\n      }\n      cursor\n    }\n  }\n  qrCodeSubscription(tenantId: $tenantId) {\n    ...ActivationAlert_qrCodeSubscription\n  }\n}\n\nfragment QrCodeRow_qrCode on QrCode {\n  id\n  serialNumber\n  type\n  isDynamic\n  name\n  comment\n  ... on RedirectQrCode {\n    enabled\n    scanCount\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'bcfd94e0d95f4e0553fe1524f13ff2b2';
export default node;
