import React from 'react';

interface IProps {
  row: string[];
}

const Row = ({ row }: IProps) => (
  <tr>
    {row.map((r, i) => (
      <td key={i}>{r}</td>
    ))}
  </tr>
);

export default Row;
