import React from 'react';
import styles from './Sidebar.module.scss';
import Menu, { IMenu } from '../../../Menu';
import UserMenu from './components/UserMenu';
import QrCode from '../../../Icons/QrCode';
import Dashboard from '../../../Icons/Dashboard';
import Support from '../../../Icons/Support';
import { useInjection } from '../../../IocProvider';
import UserService from '../../../../services/user.service';
import useRouter from 'use-react-router';
import { rootRoute } from '../../../../pages/routes';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer, QueryRenderer } from 'react-relay';
import { Sidebar_user } from '../../../../__generated__/Sidebar_user.graphql';
import UserExtensions from '../../../../common/extensions/user.extensions';
import { PERMISSION_SUPPORT_READ } from '../../../../common/constants/permissions';
import environment from '../../../../graphql/environment';
import { SidebarQuery } from '../../../../__generated__/SidebarQuery.graphql';

interface IProps {
  user: Sidebar_user | null | undefined;
  selectedTenantId: string;
  onNavigate: () => void;
}

const sidebarTenantQuery = graphql`
  query SidebarQuery($tenantId: ID!) {
    tenant(id: $tenantId) {
      permissions {
        name
      }
      ...UserMenu_tenant
    }
  }
`;

const createMenu = (tenantId: string, tenant: any | null | undefined): IMenu[] =>
  tenant
    ? [
        {
          name: 'Modules',
          menuItems: [
            {
              name: 'Dashboard',
              icon: <Dashboard />,
              url: `/${tenantId}/dashboard`,
            },
            {
              name: 'QR Codes',
              icon: <QrCode />,
              submenuItems: [
                {
                  name: 'Manage QR Codes',
                  url: `/${tenantId}/qr-codes`,
                },
              ],
            },
            ...(UserExtensions.hasPermission(tenant.permissions, PERMISSION_SUPPORT_READ)
              ? [
                  {
                    name: 'Support',
                    icon: <Support />,
                    submenuItems: [
                      {
                        name: 'Tickets',
                        url: `/${tenantId}/support/tickets`,
                      },
                    ],
                  },
                ]
              : []),
          ],
        },
      ]
    : [
        {
          name: 'Modules',
          menuItems: [null, null, null],
        },
      ];

const Sidebar = ({ selectedTenantId, user, onNavigate }: IProps) => {
  const { history } = useRouter();
  const userService = useInjection(UserService);

  return (
    <QueryRenderer<SidebarQuery>
      variables={{ tenantId: selectedTenantId }}
      environment={environment}
      query={sidebarTenantQuery}
      render={({ props }) => {
        return (
          <ul className={styles.sidebar}>
            <li className={styles.sidebar__item}>
              <UserMenu
                user={user}
                tenant={props?.tenant ?? null}
                logout={() => {
                  history.push(rootRoute);
                  userService.logout();
                }}
                onNavigate={onNavigate}
              />
            </li>
            <li className={styles.sidebar__item}>
              <Menu menus={createMenu(selectedTenantId, props?.tenant)} onNavigate={onNavigate} />
            </li>
          </ul>
        );
      }}
    />
  );
};

export default createFragmentContainer(Sidebar, {
  user: graphql`
    fragment Sidebar_user on User {
      ...UserMenu_user
    }
  `,
});
