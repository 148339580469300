import { routes } from '../pages/routes';
import { RouteType } from '../common/routing/route-type';
import { matchPath } from 'react-router-dom';
import useRouter from 'use-react-router';

export const useSelectedTenantIdOutsideRoute = (): string => {
  const { location } = useRouter();
  let match: any;

  for (const r of routes) {
    match = matchPath(location.pathname, { path: r.path, exact: r.type === RouteType.Exact });

    if (match) {
      break;
    }
  }

  const tenantId = match.params.tenantId;

  return tenantId;
};
