/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeManagement_query = {
  readonly qrCodes: {
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
      readonly endCursor: string | null;
    } | null;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ' $fragmentRefs': FragmentRefs<'QrCodeRow_qrCode'>;
      };
    } | null> | null;
  } | null;
  readonly qrCodeSubscription: {
    readonly ' $fragmentRefs': FragmentRefs<'ActivationAlert_qrCodeSubscription'>;
  } | null;
  readonly ' $refType': 'QrCodeManagement_query';
};
export type QrCodeManagement_query$data = QrCodeManagement_query;
export type QrCodeManagement_query$key = {
  readonly ' $data'?: QrCodeManagement_query$data;
  readonly ' $fragmentRefs': FragmentRefs<'QrCodeManagement_query'>;
};

const node: ReaderFragment = (function() {
  var v0 = {
    kind: 'Variable',
    name: 'tenantId',
    variableName: 'tenantId',
  };
  return {
    argumentDefinitions: [
      {
        kind: 'RootArgument',
        name: 'tenantId',
        type: 'String!',
      },
      {
        kind: 'RootArgument',
        name: 'count',
        type: 'Int',
      },
      {
        kind: 'RootArgument',
        name: 'cursor',
        type: 'String',
      },
      {
        kind: 'RootArgument',
        name: 'searchTerm',
        type: 'String',
      },
    ],
    kind: 'Fragment',
    metadata: {
      connection: [
        {
          count: 'count',
          cursor: 'cursor',
          direction: 'forward',
          path: ['qrCodes'],
        },
      ],
    },
    name: 'QrCodeManagement_query',
    selections: [
      {
        alias: 'qrCodes',
        args: [
          {
            kind: 'Variable',
            name: 'searchTerm',
            variableName: 'searchTerm',
          },
          v0 /*: any*/,
        ],
        concreteType: 'QrCodeConnection',
        kind: 'LinkedField',
        name: '__QrCodeManagement_qrCodes_connection',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'PageInfo',
            kind: 'LinkedField',
            name: 'pageInfo',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'hasNextPage',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'hasPreviousPage',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'startCursor',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'endCursor',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            concreteType: 'QrCodeEdge',
            kind: 'LinkedField',
            name: 'edges',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: null,
                kind: 'LinkedField',
                name: 'node',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: '__typename',
                    storageKey: null,
                  },
                  {
                    args: null,
                    kind: 'FragmentSpread',
                    name: 'QrCodeRow_qrCode',
                  },
                ],
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'cursor',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        alias: null,
        args: [v0 /*: any*/],
        concreteType: 'QrCodeSubscription',
        kind: 'LinkedField',
        name: 'qrCodeSubscription',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'ActivationAlert_qrCodeSubscription',
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Query',
  };
})();
(node as any).hash = '4285e55620a59b33785e7049c9191cce';
export default node;
