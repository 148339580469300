import en from './default.json';
import de from './default.de.json';

import dashboardEn from './dashboard/default.json';
import dashboardDe from './dashboard/default.de.json';

import authEn from './auth/default.json';
import authDe from './auth/default.de.json';

import qrcodesEn from '../../modules/qrCodes/locales/default.json';
import qrcodesDe from '../../modules/qrCodes/locales/default.de.json';

import productsEn from '../../modules/products/locales/default.json';
import productsDe from '../../modules/products/locales/default.de.json';

import ticketsEn from '../../modules/tickets/locales/default.json';
import ticketsDe from '../../modules/tickets/locales/default.de.json';

import accountEn from '../../modules/account/locales/default.json';
import accountDe from '../../modules/account/locales/default.de.json';

en.en.dashboard = dashboardEn.en;
de.de.dashboard = dashboardDe.de;

en.en.products = productsEn.en;
de.de.products = productsDe.de;

en.en.qrcodes = qrcodesEn.en;
de.de.qrcodes = qrcodesDe.de;

en.en.tickets = ticketsEn.en;
de.de.tickets = ticketsDe.de;

en.en.account = accountEn.en;
de.de.account = accountDe.de;

en.en.auth = authEn.en;
de.de.auth = authDe.de;

export { en, de };
