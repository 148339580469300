/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type LatestScansTableWidget_scans = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly timestamp: string;
      readonly country: string | null;
      readonly city: string | null;
      readonly browser: string | null;
      readonly device: string | null;
      readonly os: string | null;
      readonly qrCodeId: string;
    };
  } | null> | null;
  readonly retentionTimeInDays: number;
  readonly ' $refType': 'LatestScansTableWidget_scans';
};
export type LatestScansTableWidget_scans$data = LatestScansTableWidget_scans;
export type LatestScansTableWidget_scans$key = {
  readonly ' $data'?: LatestScansTableWidget_scans$data;
  readonly ' $fragmentRefs': FragmentRefs<'LatestScansTableWidget_scans'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'LatestScansTableWidget_scans',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'ScanEdge',
      kind: 'LinkedField',
      name: 'edges',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Scan',
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'timestamp',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'country',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'city',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'browser',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'device',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'os',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'qrCodeId',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'retentionTimeInDays',
      storageKey: null,
    },
  ],
  type: 'ScanConnection',
};
(node as any).hash = '616a6de29560f9939080bcf76faae110';
export default node;
