import React from 'react';
import { SignUp } from 'aws-amplify-react/lib/Auth';
import { Form, Formik } from 'formik';
import Input from '../../../forms/Input';
import { validationSchema } from './lib/validationSchema';
import ProgressButton from '../../../ProgressButton';
import AuthFormContainer from '../AuthFormContainer';
import Auth from '@aws-amplify/auth';
import { Translation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { TFunction } from 'i18next';

class QrpidSignUp extends SignUp {
  async signUpAccount(t: TFunction) {
    this.setState({ requestPending: true });
    const { email, password } = this.inputs;
    const { history } = this.props as any;

    try {
      const data = await Auth.signUp({ password, username: email });
      history.push({
        pathname: '/confirm-email',
        state: {
          successMessage: t('auth.alerts.success.sentCode'),
          username: data.user.getUsername(),
        },
      });
    } catch (err) {
      this.error(err);
    }

    this.setState({ requestPending: false });
  }

  showComponent() {
    return (
      <Translation>
        {t => (
          <AuthFormContainer
            loading={this.state.requestPending ?? false}
            headline={t('auth.signUp.headline')}
          >
            <Formik
              initialValues={{ email: '', password: '', confirmPassword: '' }}
              validationSchema={validationSchema(t)}
              onSubmit={async values => {
                this.inputs.email = values.email.toLowerCase();
                this.inputs.password = values.password;
                this.inputs.affiliateId = '';
                await this.signUpAccount(t);
              }}
            >
              {props => (
                <Form translate="no">
                  <Input
                    type="email"
                    label={t('auth.labels.email')}
                    name="email"
                    placeholder={t('auth.labels.email')}
                    touched={props.touched.email}
                    error={props.errors.email}
                  />

                  <Input
                    type="password"
                    label={t('auth.labels.newPassword')}
                    name="password"
                    placeholder={t('auth.labels.newPassword')}
                    touched={props.touched.password}
                    error={props.errors.password}
                  />

                  <Input
                    type="password"
                    label={t('auth.labels.confirmPassword')}
                    name="confirmPassword"
                    placeholder={t('auth.labels.confirmPassword')}
                    touched={props.touched.confirmPassword}
                    error={props.errors.confirmPassword}
                  />

                  <ProgressButton
                    type="submit"
                    isLoading={this.state.requestPending ?? false}
                    className="mb-0 w-100"
                  >
                    {t('auth.signUp.buttons.signUp')}
                  </ProgressButton>
                </Form>
              )}
            </Formik>

            <hr />

            <Link className="hollow button mb-0 w-100" to="/sign-in">
              {t('auth.signUp.buttons.goToSignIn')}
            </Link>
          </AuthFormContainer>
        )}
      </Translation>
    );
  }
}

export default withRouter(QrpidSignUp as any);
