import React, { useState } from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import ActionTypeSelect from '../../QrCodeDetails/components/ActionTypeSelect';
import Submit from '../../QrCodeDetails/components/Submit';
import { WifiForm_qrCode } from '../../../../../__generated__/WifiForm_qrCode.graphql';

interface IProps {
  qrCode: WifiForm_qrCode;
}

const WifiForm = ({ qrCode }: IProps) => {
  const { isDynamic, type } = qrCode;
  const [state, setState] = useState({
    actionType: qrCode.type,
    ssid: qrCode.ssid,
    password: qrCode.password,
    encryption: qrCode.encryption,
    name: qrCode.name,
  });
  const { ssid, password, encryption, name } = state;

  const handleInputChange = (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const inputName = event.target.name;

    setState({
      ...state,
      [inputName]: value,
    });
  };

  return (
    <>
      <label>
        Name
        <input
          type="text"
          name="name"
          placeholder="Name"
          disabled={!isDynamic}
          defaultValue={name ? name : undefined}
          onChange={handleInputChange}
          maxLength={32}
        />
      </label>

      <ActionTypeSelect value={type} isDynamic={isDynamic} onChange={handleInputChange} />

      <label>
        SSID
        <input
          type="text"
          name="ssid"
          disabled={!isDynamic}
          value={ssid ? ssid : ''}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Password
        <input
          type="text"
          name="password"
          disabled={!isDynamic}
          defaultValue={password ? password : ''}
          onChange={handleInputChange}
        />
      </label>

      <fieldset>
        <label>Encryption</label>
        <input
          type="radio"
          name="encryption"
          value="WEP"
          onChange={handleInputChange}
          defaultChecked={encryption === 'WEP'}
          disabled={!isDynamic}
        />
        <label>WEP</label>
        <input
          type="radio"
          name="encryption"
          value="WPA"
          onChange={handleInputChange}
          defaultChecked={encryption === 'WPA'}
          disabled={!isDynamic}
        />
        <label>WPA</label>
        <input
          type="radio"
          name="encryption"
          value="None"
          onChange={handleInputChange}
          defaultChecked={encryption === 'NONE'}
          disabled={!isDynamic}
        />
        <label>None</label>
      </fieldset>

      <Submit
        onSubmit={() => {
          return;
        }}
      />
    </>
  );
};

export default createFragmentContainer(WifiForm, {
  qrCode: graphql`
    fragment WifiForm_qrCode on QrCode {
      type
      isDynamic
      name
      ... on WifiQrCode {
        ssid
        password
        encryption
      }
    }
  `,
});
