import React from 'react';
import styles from './UserMenu.module.scss';
import Avatar from '../../../../../Avatar';
import CollapsibleMenu from '../../../../../Menu/components/CollapsibleMenuItem';
import { ISubmenuItem } from '../../../../../Menu';
import UserExtensions from '../../../../../../common/extensions/user.extensions';
import { PERMISSION_BILLING_READ } from '../../../../../../common/constants/permissions';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import { UserMenu_user } from '../../../../../../__generated__/UserMenu_user.graphql';
import { Skeleton } from '../../../../../Skeleton';
import { UserMenu_tenant } from '../../../../../../__generated__/UserMenu_tenant.graphql';

interface IProps {
  user: UserMenu_user | null | undefined;
  tenant: UserMenu_tenant | null | undefined;
  logout: () => void;
  onNavigate: () => void;
}

const createUserMenu = (
  tenant: UserMenu_tenant | null | undefined,
  onLogout: () => void
): (ISubmenuItem | null)[] => {
  const tenantId = tenant?.id;

  return tenant
    ? [
        {
          name: 'Profile',
          url: `/${tenantId}/user/profile`,
        },
        ...(UserExtensions.hasPermission(tenant.permissions, PERMISSION_BILLING_READ)
          ? [
              {
                name: 'Account',
                url: `/${tenantId}/account`,
              },
            ]
          : []),
        {
          name: 'Logout',
          onClick: onLogout,
        },
      ]
    : [null, null, null];
};

const UserMenu = ({ user, logout, tenant, onNavigate }: IProps) => {
  const menu = createUserMenu(tenant, logout);
  const userEmail = user && user.email;
  const userName = (user && user.firstname) ?? userEmail;

  return (
    <CollapsibleMenu submenuItems={menu} onNavigate={onNavigate}>
      <div className={styles.userMenu}>
        <Avatar className={styles.userMenu__avatar} email={userEmail} />
        <div className={styles.userMenu__details}>
          <p>Welcome,</p>
          <p className={styles.userMenu__details__name}>
            {userName ?? <Skeleton style={{ fontSize: '75%' }} />}
          </p>
        </div>
      </div>
    </CollapsibleMenu>
  );
};

export default createFragmentContainer(UserMenu, {
  user: graphql`
    fragment UserMenu_user on User {
      email
      firstname
    }
  `,
  tenant: graphql`
    fragment UserMenu_tenant on Tenant {
      id
      permissions {
        name
      }
    }
  `,
});
