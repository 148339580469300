import { ChartOptions, ChartDataSets } from 'chart.js';

export const baseColors = [
  '#2672b8',
  '#81cfe0',
  '#336e7b',
  '#59abe3',
  '#3a539b',
  '#1e8bc3',
  '#4183d7',
  '#2574a9',
  '#89c4f4',
];

export const baseChartDataSet: ChartDataSets[] = [
  {
    backgroundColor: 'rgba(38,114,184, 0.6)',
    borderColor: baseColors[0],
    borderWidth: 2,
    hoverBackgroundColor: baseColors[0],
    hoverBorderColor: baseColors[0],
    pointBackgroundColor: baseColors[0],
  },
];

export const baseOptions: ChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    duration: 0,
  },
  layout: {
    padding: { top: 16 },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: (value: number) => {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  tooltips: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderColor: '#646464',
    bodyFontColor: '#646464',
    titleFontColor: '#646464',
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOffsetX: 0,
    shadowOffsetY: 0,
  },
};
