import { commitMutation } from 'react-relay';
import environment from '../environment';
import { graphql } from 'babel-plugin-relay/macro';
import { CreateQrCodeInput } from '../../__generated__/createQrCodeMutation.graphql';

const mutation = graphql`
  mutation createQrCodeMutation($input: CreateQrCodeInput!) {
    createQrCode(input: $input) {
      qrCode {
        id
      }
    }
  }
`;

export default (input: CreateQrCodeInput): Promise<void> => {
  const variables = {
    input: {
      ...input,
      clientMutationId: '',
    },
  };

  return new Promise((resolve, reject) =>
    commitMutation(environment, {
      mutation,
      variables,
      // optimisticUpdater: proxyStore => {},
      // updater: proxyStore => {},
      onCompleted: () => {
        resolve();
      },
      onError: (err: any) => {
        reject(err.source?.errors[0]?.extensions?.response?.body?.error);
      },
    })
  );
};
