import React from 'react';
import Layout from '../Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { renderRoutes } from '../../common/routing/render-routes';
import { routes } from '../../pages/routes';
import QrpidLogo from '../Icons/QrpidLogo';
import styles from './App.module.scss';
import PrivateRoute from '../PrivateRoute';
import AuthPage from '../../pages/auth';
import Auth from '../Auth';

const App: React.FC = () => {
  return (
    <Router>
      <Auth>
        <Switch>
          <Route path="/sign-in">
            <AuthPage authState="signIn" />
          </Route>
          <Route path="/sign-up">
            <AuthPage authState="signUp" />
          </Route>
          <Route path="/forgot-password">
            <AuthPage authState="forgotPassword" />
          </Route>
          <Route path="/confirm-email">
            <AuthPage authState="confirmSignUp" />
          </Route>
          <PrivateRoute path="/">
            <Layout logo={<QrpidLogo className={styles.app__logo} />}>
              {renderRoutes(routes)}
            </Layout>
          </PrivateRoute>
        </Switch>
      </Auth>
    </Router>
  );
};

export default App;
