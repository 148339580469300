import React from 'react';
import useRouter from 'use-react-router';
import { generatePath } from 'react-router-dom';
import { getRouteFromUrl, getParamsFromUrl } from '../../../../../../common/routing/routeUtils';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { TenantSelect_user } from '../../../../../../__generated__/TenantSelect_user.graphql';

interface IProps {
  selectedTenantId: string;
  user: TenantSelect_user | null | undefined;
}

const renderOptions = (user: TenantSelect_user) =>
  user.tenants.map((t, i) => (
    <option key={i} value={t.id}>
      {t.name}
    </option>
  ));

const TenantSelect = ({ selectedTenantId, user }: IProps) => {
  const { history, location } = useRouter();
  const route = getRouteFromUrl(location.pathname);
  const params = getParamsFromUrl(location.pathname);

  if (!user?.tenants || user.tenants.length <= 1) {
    return null;
  }

  return (
    <select
      className="mb-0"
      name="tenantId"
      value={selectedTenantId}
      onChange={event =>
        history.push(generatePath(route.path, { ...params, tenantId: event.target.value }))
      }
    >
      {renderOptions(user)}
    </select>
  );
};

export default createFragmentContainer(TenantSelect, {
  user: graphql`
    fragment TenantSelect_user on User {
      tenants {
        id
        name
      }
    }
  `,
});
