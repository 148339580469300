import React from 'react';
import { QueryRenderer } from 'react-relay';
import environment from '../../../../graphql/environment';
import { graphql } from 'babel-plugin-relay/macro';
import { RouteComponentProps } from 'react-router';
import useRouter from 'use-react-router';
import ErrorNotice from '../../../../components/ErrorNotice';
import { ModuleHeader } from '../../../../components/Module';
import { useTranslation } from 'react-i18next';
import QrCodeManagement from '../../components/QrCodeManagement';
import { QrCodeListPageQuery } from '../../../../__generated__/QrCodeListPageQuery.graphql';
import { useSelectedTenantId } from '../../../../hooks/use-tenant-id.hook';

export const qrCodes = graphql`
  query QrCodeListPageQuery(
    $tenantId: String!
    $count: Int!
    $cursor: String
    $searchTerm: String
  ) {
    ...QrCodeManagement_query
  }
`;

const QrCodeListPage = () => {
  const { match }: RouteComponentProps<any> = useRouter();
  const { t } = useTranslation(['qrpid']);
  const tenantId = useSelectedTenantId();

  return (
    <>
      <ModuleHeader
        title={t('qrcodes.title')}
        description={t('qrcodes.description')}
        link={`/${tenantId}/qr-codes/new-qr-code`}
        linkText={t('qrcodes.new.button')}
      />

      <QueryRenderer<QrCodeListPageQuery>
        variables={{ count: 25, tenantId: match.params.tenantId, searchTerm: '' }}
        environment={environment}
        query={qrCodes}
        render={({ error, props }) => {
          if (error) {
            return (
              <ErrorNotice
                errorText={'Sorry, there was an error while loading the QR Codes.'}
                buttonText={'Back'}
                returnUrl={`/`}
              />
            );
          }

          return (
            <div className="cell small-12">
              <QrCodeManagement query={props} />
            </div>
          );
        }}
      />
    </>
  );
};

export default QrCodeListPage;
