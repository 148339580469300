import React from 'react';

import styles from './Header.module.scss';
import { Link } from 'react-router-dom';
import Plus from '../../Icons/Plus';

interface IProps {
  title: string;
  description: string;
  link?: string;
  linkText?: string;
}

const Header = ({ title, description, link, linkText }: IProps) => {
  return (
    <>
      <div className="cell small-12">
        <h1 className={styles.header__title}>{title}</h1>
      </div>

      <div className="cell small-12 medium-8">
        <p className={styles.header__description}>{description}</p>
      </div>

      {link && (
        <div className="cell small-12 medium-4">
          <Link to={link} className="button float-right mb-0">
            <Plus className={styles.header__button_icon} /> {linkText}
          </Link>
        </div>
      )}
    </>
  );
};

export default Header;
