import React, { useEffect, useState, ReactNode } from 'react';
import styles from './AuthFormContainer.module.scss';
import HighlightedContainer from '../../../HighlightedContainer';
import QrpidLogo from '../../../Icons/QrpidLogo';
import { Hub } from '@aws-amplify/core';
import { useTranslation } from 'react-i18next';

interface IProps {
  children: ReactNode;
  loading: boolean;
  headline: string;
  successMessage?: string;
}

const AuthFormContainer = ({ children, loading, headline, successMessage }: IProps) => {
  const [error, setError] = useState();
  const { t } = useTranslation(['qrpid']);

  useEffect(() => {
    const auth = (res: any) => {
      const { code } = res.payload.data;

      if (code) {
        setError({ message: t(`auth.alerts.error.${code}`) });
      }
    };

    Hub.listen('auth', auth);

    return () => {
      Hub.remove('auth', auth);
    };
  });

  return (
    <div className="grid-container fluid">
      <div className="grid-x grid-margin-x h-100">
        <div className="cell small-12 my-2 my-medium-3 ">
          <QrpidLogo className={styles.authFormContainer__logo} />
        </div>

        <div className="cell small-12">
          <HighlightedContainer className={styles.authFormContainer}>
            <h2 className="text-center mb-2">{headline}</h2>

            {error && !loading && (
              <div className={`callout small alert ${styles.authFormContainer__error}`}>
                {error.message}
              </div>
            )}

            {!error && successMessage && !loading && (
              <div className={`callout small success ${styles.authFormContainer__success}`}>
                {successMessage}
              </div>
            )}

            {children}
          </HighlightedContainer>
        </div>
      </div>
    </div>
  );
};

export default AuthFormContainer;
