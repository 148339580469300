import React from 'react';
import styles from './SkeletonTitle.module.scss';

const SkeletonTitle = () => (
  <>
    <div className={styles.skeleton__title} />
  </>
);

export default SkeletonTitle;
