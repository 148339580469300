import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) =>
  Yup.object().shape<any>({
    email: Yup.string()
      .required(t('auth.validation.email.required'))
      .email(t('auth.validation.email.valid')),
    password: Yup.string().required(t('auth.signIn.validation.password.required')),
  });
