import React from 'react';
import { Link } from 'react-router-dom';
import { useSelectedTenantId } from '../../../../../../hooks/use-tenant-id.hook';

interface IProps {
  onSubmit: () => void;
}

const Submit = ({ onSubmit }: IProps) => {
  const tenantId = useSelectedTenantId();

  return (
    <div className="clearfix">
      <button type="button" className="primary button float-right mb-0" onClick={onSubmit}>
        Save
      </button>
      <Link to={`/${tenantId}/qr-codes`} className="clear button secondary float-right mb-0">
        Cancel
      </Link>
    </div>
  );
};

export default Submit;
