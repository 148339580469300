import React from 'react';

interface IProps {
  className?: string;
}

const BurgerMenu = ({ className }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344 344" className={className}>
      <path d="M0 46.06h344.339v29.52H0zM0 156.506h344.339v29.52H0zM0 268.748h344.339v29.531H0z" />
    </svg>

    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className}>
    //   <path d="M501.333 96H10.667C4.779 96 0 100.779 0 106.667s4.779 10.667 10.667 10.667h490.667c5.888 0 10.667-4.779 10.667-10.667S507.221 96 501.333 96zM501.333 245.333H10.667C4.779 245.333 0 250.112 0 256s4.779 10.667 10.667 10.667h490.667c5.888 0 10.667-4.779 10.667-10.667s-4.78-10.667-10.668-10.667zM501.333 394.667H10.667C4.779 394.667 0 399.445 0 405.333 0 411.221 4.779 416 10.667 416h490.667c5.888 0 10.667-4.779 10.667-10.667-.001-5.888-4.78-10.666-10.668-10.666z" />
    // </svg>
  );
};

export default BurgerMenu;
