import React, { CSSProperties } from 'react';
import styles from './Skeleton.module.scss';

interface IProps {
  style?: CSSProperties;
}

const Skeleton = ({ style }: IProps) => (
  <span style={style} className={styles.skeleton}>
    &nbsp;
  </span>
);

export default Skeleton;
