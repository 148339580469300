/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type ProfilePageQueryVariables = {};
export type ProfilePageQueryResponse = {
  readonly user: {
    readonly ' $fragmentRefs': FragmentRefs<'UserProfile_user'>;
  } | null;
};
export type ProfilePageQuery = {
  readonly response: ProfilePageQueryResponse;
  readonly variables: ProfilePageQueryVariables;
};

/*
query ProfilePageQuery {
  user {
    ...UserProfile_user
    id
  }
}

fragment UserProfile_user on User {
  email
  firstname
  lastname
}
*/

const node: ConcreteRequest = {
  fragment: {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ProfilePageQuery',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'User',
        kind: 'LinkedField',
        name: 'user',
        plural: false,
        selections: [
          {
            args: null,
            kind: 'FragmentSpread',
            name: 'UserProfile_user',
          },
        ],
        storageKey: null,
      },
    ],
    type: 'Query',
  },
  kind: 'Request',
  operation: {
    argumentDefinitions: [],
    kind: 'Operation',
    name: 'ProfilePageQuery',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'User',
        kind: 'LinkedField',
        name: 'user',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'email',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'firstname',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lastname',
            storageKey: null,
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
  },
  params: {
    id: null,
    metadata: {},
    name: 'ProfilePageQuery',
    operationKind: 'query',
    text:
      'query ProfilePageQuery {\n  user {\n    ...UserProfile_user\n    id\n  }\n}\n\nfragment UserProfile_user on User {\n  email\n  firstname\n  lastname\n}\n',
  },
};
(node as any).hash = 'fcf1b50943816a606ff4631cddd791dc';
export default node;
