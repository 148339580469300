import React from 'react';
import { Formik, Form } from 'formik';
import { validationSchema } from './lib/validationSchema';
import Input from '../../../../../forms/Input';
import ProgressButton from '../../../../../ProgressButton';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IProps {
  onSubmit: (values: any) => void;
  onResendCode: () => void;
  isLoading: boolean;
  isResendLoading: boolean;
}

const CodeForm = ({ onSubmit, isLoading, onResendCode, isResendLoading }: IProps) => (
  <Translation>
    {t => (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{ code: '', password: '', confirmPassword: '' }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <Form translate="no" name="submitView">
              <Input
                type="text"
                label={t('auth.labels.code')}
                name="code"
                placeholder={t('auth.labels.code')}
                touched={props.touched.code}
                error={props.errors.code}
              />

              <Input
                type="password"
                label={t('auth.labels.newPassword')}
                name="password"
                placeholder={t('auth.labels.newPassword')}
                touched={props.touched.password}
                error={props.errors.password}
              />

              <Input
                type="password"
                label={t('auth.labels.confirmPassword')}
                name="confirmPassword"
                placeholder={t('auth.labels.confirmPassword')}
                touched={props.touched.confirmPassword}
                error={props.errors.confirmPassword}
              />

              <ProgressButton type="submit" isLoading={isLoading} className="mb-0 w-100">
                {t('auth.forgotPassword.buttons.forgotPasswordSubmit')}
              </ProgressButton>
            </Form>
          )}
        </Formik>

        <hr />

        <ProgressButton
          isLoading={isResendLoading}
          className="hollow button mb-2 w-100"
          onClick={() => onResendCode()}
          spinnerColor={'PRIMARY'}
        >
          {t('auth.buttons.resendCode')}
        </ProgressButton>

        <Link to="/sign-in" className="hollow button mb-0 w-100">
          {t('auth.buttons.goToSignIn')}
        </Link>
      </>
    )}
  </Translation>
);

export default CodeForm;
