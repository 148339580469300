import React from 'react';
import { Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import environment from '../../graphql/environment';
import { graphql } from 'babel-plugin-relay/macro';
import { HomePageQuery } from '../../__generated__/HomePageQuery.graphql';

const homePageQuery = graphql`
  query HomePageQuery {
    user {
      tenants {
        id
      }
    }
  }
`;

const HomePage = () => (
  <QueryRenderer<HomePageQuery>
    environment={environment}
    query={homePageQuery}
    variables={{}}
    render={({ error, props }) => {
      const defaultTenantId = props?.user?.tenants[0]?.id;

      if (error || !defaultTenantId) {
        return null;
      }

      return <Redirect to={`/${defaultTenantId}/dashboard`} />;
    }}
  />
);

export default HomePage;
