import React from 'react';
import styles from './SubMenuItem.module.scss';
import { ISubmenuItem } from '../../lib/sub-menu-item';
import { Link } from 'react-router-dom';
import { Skeleton } from '../../../Skeleton';

interface IProps {
  submenuItem: ISubmenuItem | null;
  onNavigate?: () => void;
}

const SubMenuItem = ({ submenuItem, onNavigate }: IProps) =>
  submenuItem?.url ? (
    <Link to={submenuItem.url} className={styles.subMenuItem} onClick={onNavigate}>
      {submenuItem.name}
    </Link>
  ) : (
    <div
      className={styles.subMenuItem}
      onClick={() => {
        if (submenuItem?.onClick) {
          submenuItem.onClick();
        }

        if (onNavigate) {
          onNavigate();
        }
      }}
    >
      {submenuItem?.name ?? <Skeleton />}
    </div>
  );

export default SubMenuItem;
