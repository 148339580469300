import { env } from '../../../common/helper/environment.helper';

export const amplifyConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'eu-west-1:0fc41793-d2fb-42ba-961c-ff8be0822a3a',

    // REQUIRED - Amazon Cognito Region
    region: 'eu-central-1',

    userPoolId: env('REACT_APP_USER_POOL_ID'),
    userPoolWebClientId: env('REACT_APP_USER_POOL_CLIENT_ID'),
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: env('REACT_APP_DOMAIN') ? env('REACT_APP_DOMAIN') : 'localhost',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: false,
    },
  },
};
