import React from 'react';
import Widget from '../Widget';
import Table from '../../Table';
import ClickableRow from '../../Table/components/ClickableRow';
import Row from '../../Table/components/Row';

interface IProps {
  headline?: string;
  columns: string[];
  rows: string[][];
  onClickRow?: (index: number) => void;
  missingDataText?: string;
}

const TableWidget = ({ headline, columns, rows, onClickRow, missingDataText }: IProps) => {
  const noData = rows.length <= 0 ? (missingDataText ? missingDataText : 'No data') : undefined;

  return (
    <Widget headline={headline} noPadding={true} missingDataText={noData}>
      <Table columns={columns}>
        {rows.map((r, i) =>
          onClickRow ? (
            <ClickableRow key={i} row={r} onClick={() => onClickRow(i)} />
          ) : (
            <Row key={i} row={r} />
          )
        )}
      </Table>
    </Widget>
  );
};

export default TableWidget;
