import React from 'react';

interface IProps {
  columns: string[];
}

const Header = ({ columns }: IProps) => (
  <thead>
    <tr>
      {columns.map((c, i) => (
        <th key={i}>{c}</th>
      ))}
    </tr>
  </thead>
);

export default Header;
