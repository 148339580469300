import { Environment, Network, RecordSource, Store, QueryResponseCache } from 'relay-runtime';
import { API_BASE_PATH } from '../common/constants/environment-variables';
import Auth from '@aws-amplify/auth';

const store = new Store(new RecordSource());

const ttl = 60 * 1000 * 5; // 5 minutes
const cache = new QueryResponseCache({ size: 250, ttl });

const network = Network.create(async (operation, variables, cacheConfig) => {
  const session = await Auth.currentSession();
  const queryId = operation.text ?? '';
  const isMutation = operation.operationKind === 'mutation';
  const isQuery = operation.operationKind === 'query';
  const forceFetch = cacheConfig && cacheConfig.force;

  // Try to get data from cache on queries
  const fromCache = cache.get(queryId, variables);
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache;
  }

  const response = await fetch(`${API_BASE_PATH}/graphql`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: session.getIdToken().getJwtToken(),
    },
    body: JSON.stringify({
      variables,
      query: operation.text,
    }),
  });

  let result = await response.json();

  if (isQuery && result) {
    cache.set(queryId, variables, result);
  }

  // Clear cache on mutations
  if (isMutation) {
    cache.clear();
  }

  // https://github.com/facebook/relay/issues/1913
  if (result.errors) {
    result = { data: null, errors: result.errors };
  }

  return result;
});

const environment = new Environment({
  network,
  store,
});

export default environment;
