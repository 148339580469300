import React, { ReactNode } from 'react';
import {
  Authenticator,
  Greetings,
  SignIn,
  SignUp,
  ConfirmSignUp,
  ForgotPassword,
  Loading,
} from 'aws-amplify-react/lib/Auth';
import { UsernameAttributes } from 'aws-amplify-react/lib/Auth/common/types';
import QrpidSignIn from './components/QrpidSignIn';
import QrpidSignUp from './components/QrpidSignUp';
import QrpidConfirmSignUp from './components/QrpidConfirmSignUp';
import QrpidForgotPassword from './components/QrpidForgotPassword';
import QrpidLoading from './components/QrpidLoading';
import AuthContextProvider from './components/AuthContextProvider';

interface IProps {
  children: ReactNode;
}

const Auth = ({ children }: IProps) => {
  return (
    <Authenticator
      hide={[Greetings, SignIn, SignUp, ConfirmSignUp, ForgotPassword, Loading]}
      usernameAttributes={UsernameAttributes.EMAIL}
      theme={{
        container: {
          height: '100vh',
          backgroundColor: '#f3f3f3',
        },
        toast: { display: 'none' },
      }}
    >
      <QrpidSignIn />
      <QrpidSignUp />
      <QrpidConfirmSignUp />
      <QrpidForgotPassword />
      <QrpidLoading />

      <AuthContextProvider>{children}</AuthContextProvider>
    </Authenticator>
  );
};

export default Auth;
