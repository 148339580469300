import React from 'react';
import styles from './Avatar.module.scss';
import { createAvatarComponent, GravatarSource, ValueSource } from 'react-avatar';

const ReactAvatar = createAvatarComponent({
  sources: [GravatarSource, ValueSource],
});

interface IProps {
  email: string | null | undefined;
  className?: string;
}

const Avatar = ({ className, email }: IProps) => {
  return (
    <div className={className}>
      <div className={styles.avatar}>
        <ReactAvatar className={styles.avatar__image} email={email} name={email} />
      </div>
    </div>
  );
};

export default Avatar;
