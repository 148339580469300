import React from 'react';
import { createPaginationContainer, RelayPaginationProp } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import Search from './components/Search';
import QrCodeTable from './components/QrCodeTable';
import QrCodeRow from './components/QrCodeRow';
import styles from './QrCodeManagement.module.scss';
import ActivationAlert from './components/ActivationAlert';
import { QrCodeManagement_query } from '../../../../__generated__/QrCodeManagement_query.graphql';
import HighlightedContainer from '../../../../components/HighlightedContainer';

interface IProps {
  query: QrCodeManagement_query | null;
  relay: RelayPaginationProp;
}

const QrCodeManagement = ({ query, relay }: IProps) => {
  let rows;

  if (query && query.qrCodes) {
    const { qrCodes } = query;

    if (qrCodes.edges) {
      rows = qrCodes.edges.map((e, i) => (e ? <QrCodeRow key={i} qrCode={e.node} /> : null));
    }
  }

  const search = (searchTerm: string) => relay.refetchConnection(25, null, { searchTerm });

  return (
    <>
      <HighlightedContainer className="cell small-12" padding={true}>
        {query?.qrCodeSubscription ? (
          <ActivationAlert qrCodeSubscription={query.qrCodeSubscription} />
        ) : null}
        <Search onSearchChange={search} />
        <QrCodeTable>{rows}</QrCodeTable>
      </HighlightedContainer>
      {relay.hasMore() && (
        <div className={styles.qrCodeManagement__loadMoreContainer}>
          <button className="button secondary mt-2" onClick={() => relay.loadMore(25)}>
            Load more
          </button>
        </div>
      )}
    </>
  );
};

export default createPaginationContainer(
  QrCodeManagement,
  {
    query: graphql`
      fragment QrCodeManagement_query on Query {
        qrCodes(tenantId: $tenantId, first: $count, after: $cursor, searchTerm: $searchTerm)
          @connection(key: "QrCodeManagement_qrCodes") {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              ...QrCodeRow_qrCode
            }
          }
        }

        qrCodeSubscription(tenantId: $tenantId) {
          ...ActivationAlert_qrCodeSubscription
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      if (props.query && props.query.qrCodes) {
        return {
          edges: props.query.qrCodes.edges,
          pageInfo: props.query.qrCodes.pageInfo,
        };
      }
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(_, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        searchTerm: fragmentVariables.searchTerm,
        tenantId: fragmentVariables.tenantId,
      };
    },
    query: graphql`
      query QrCodeManagementQuery(
        $tenantId: String!
        $count: Int!
        $cursor: String
        $searchTerm: String
      ) {
        ...QrCodeManagement_query
      }
    `,
  }
);
