import React, { ReactNode } from 'react';

import styles from './ProgressButton.module.scss';
import Spinner from '../Spinner';

interface IProps {
  children: ReactNode;
  isLoading: boolean;
  type?: 'button' | 'reset' | 'submit';
  className?: string;
  onClick?: () => void;
  spinnerColor?: 'WHITE' | 'PRIMARY';
}

const ProgressButton = ({
  children,
  isLoading,
  type,
  className,
  onClick,
  spinnerColor,
}: IProps) => {
  return (
    <>
      <button className={`button ${className}`} type={type} onClick={onClick}>
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner color={spinnerColor ?? 'WHITE'} />
          </div>
        )}
        {children}
      </button>
    </>
  );
};

export default ProgressButton;
