import React from 'react';
import { ISubmenuItem } from '../../lib/sub-menu-item';
import SubMenuItem from '../SubMenuItem';
import styles from './SubMenu.module.scss';

interface IProps {
  subMenuItems: (ISubmenuItem | null)[];
  onNavigate?: () => void;
}

const SubMenu = ({ subMenuItems, onNavigate }: IProps) => (
  <ul className={styles.subMenu}>
    <li>
      {subMenuItems
        ? subMenuItems.map((s, i) => (
            <SubMenuItem key={(s?.name ?? '') + i} submenuItem={s} onNavigate={onNavigate} />
          ))
        : null}
    </li>
  </ul>
);

export default SubMenu;
