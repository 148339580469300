/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type StaticDashboard_scans = {
  readonly ' $fragmentRefs': FragmentRefs<'LatestScansTableWidget_scans'>;
  readonly ' $refType': 'StaticDashboard_scans';
};
export type StaticDashboard_scans$data = StaticDashboard_scans;
export type StaticDashboard_scans$key = {
  readonly ' $data'?: StaticDashboard_scans$data;
  readonly ' $fragmentRefs': FragmentRefs<'StaticDashboard_scans'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'StaticDashboard_scans',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'LatestScansTableWidget_scans',
    },
  ],
  type: 'ScanConnection',
};
(node as any).hash = '28a1a3f9da5934d6a25945eecaeca549';
export default node;
