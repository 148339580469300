import React, { useContext, ReactNode } from 'react';
import { Route } from 'react-router';
import { AuthContext } from '../Auth/components/AuthContextProvider/AuthContextProvider';

interface IProps {
  children: ReactNode;
  path: string;
}

const PrivateRoute = ({ children, path }: IProps) => {
  const { authState } = useContext(AuthContext);

  return authState === 'signedIn' ? <Route path={path}>{children}</Route> : null;
};

export default PrivateRoute;
