import React, { useState, useEffect } from 'react';
import { useSelectedTenantId } from '../../hooks/use-tenant-id.hook';
import { useDebouncedInput } from '../../hooks/use-debounced-input.hook';
import ticketService from './lib/ticketService';
import HighlightedContainer from '../../components/HighlightedContainer';
import { ModuleHeader } from '../../components/Module';
import { TicketTable } from './components';
import { useTranslation } from 'react-i18next';

const TicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const tenantId = useSelectedTenantId();
  const { setInputText } = useDebouncedInput(setQuery, 300);
  const { t } = useTranslation(['qrpid']);

  useEffect(() => {
    setIsLoading(true);
    ticketService
      .getTickets(tenantId, query)
      .then(response => {
        setTickets(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
        setIsLoading(false);
      });
  }, [tenantId, query]);

  return (
    <>
      <ModuleHeader title={t('tickets.title')} description={t('tickets.description')} link={''} />
      <div className="cell small-12 mt-2">
        <HighlightedContainer className="cell small-12" padding={true}>
          <form>
            <div className="input-group">
              <input
                className="input-group-field"
                type="text"
                placeholder="Search"
                onChange={e => setInputText(e.target.value)}
              />
              <div className="input-group-button">
                <input type="submit" className="button" value="Search" />
              </div>
            </div>
          </form>
          <TicketTable isLoading={isLoading} tickets={tickets} />
        </HighlightedContainer>
      </div>
    </>
  );
};

export default TicketsPage;
