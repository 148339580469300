import React from 'react';
import { RouteType } from './route-type';
import { Route, Redirect, Switch } from 'react-router';
import { IRoute } from './route';

export const renderRoutes = (routes: IRoute[]) => {
  const renderedRoutes = routes.map((r, i) => {
    switch (r.type) {
      case RouteType.Default:
        return <Route key={i} path={r.path} component={r.component} />;
      case RouteType.Redirect:
        return <Redirect key={i} from={r.path} to={r.redirectPath ? r.redirectPath : ''} />;
      case RouteType.Exact:
        return <Route key={i} path={r.path} component={r.component} exact />;
      default:
        throw new Error(`Unknown route type: ${r.type}`);
    }
  });

  return <Switch>{renderedRoutes}</Switch>;
};
