import React from 'react';

interface IProps {
  isDynamic: boolean;
  value: string;
  onChange: (event: any) => void;
}

const dynamicActions = [{ text: 'Redirect', value: 'REDIRECT' }];

const staticActions = [
  { text: 'Website', value: 'URL' },
  { text: 'WiFi', value: 'WIFI' },
];

const renderOptions = (actions: any[]) =>
  actions.map((a, i) => (
    <option key={i} value={a.value}>
      {a.text}
    </option>
  ));

const ActionTypeSelect = ({ isDynamic, value, onChange }: IProps) => (
  <label>
    Action
    <select name="actionType" disabled={!isDynamic} value={value} onChange={onChange}>
      {renderOptions(isDynamic ? dynamicActions : staticActions)}
    </select>
  </label>
);

export default ActionTypeSelect;
