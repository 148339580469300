import React, { ReactNode, ReactElement, useState } from 'react';
import Logo from './components/Logo';
import styles from './Layout.module.scss';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import { useBreadcrumbs } from '../../hooks/use-breadcrumbs.hook';
import { useSelectedTenantIdOutsideRoute } from '../../hooks/use-tenant-id-outside-route.hook';
import { Link } from 'react-router-dom';
import { graphql } from 'babel-plugin-relay/macro';
import environment from '../../graphql/environment';
import { QueryRenderer } from 'react-relay';
import ErrorNotice from '../ErrorNotice';
import { LayoutQuery } from '../../__generated__/LayoutQuery.graphql';
import HomePage from '../../pages/home';

interface IProps {
  children: ReactNode;
  logo: ReactElement;
}

export const layoutQuery = graphql`
  query LayoutQuery {
    user {
      ...Sidebar_user
      ...Navbar_user
    }
  }
`;

const Layout = ({ children, logo }: IProps) => {
  const breadcrumbs = useBreadcrumbs();
  const selectedTenantId = useSelectedTenantIdOutsideRoute();
  const [sidebarClass, setSidebarClass] = useState(styles.layout__sidebar__mobile__hide);
  const [mainClass, setMainClass] = useState(styles.layout__main__sidebar__closed);
  const [overlayClass, setOverlayClass] = useState(styles.layout__overlay__hide);

  const toggleSidebar = () => {
    setSidebarClass(
      sidebarClass === styles.layout__sidebar__mobile__show
        ? styles.layout__sidebar__mobile__hide
        : styles.layout__sidebar__mobile__show
    );

    setMainClass(
      mainClass === styles.layout__main__sidebar__closed
        ? styles.layout__main__sidebar__open
        : styles.layout__main__sidebar__closed
    );

    setOverlayClass(
      overlayClass === styles.layout__overlay__show
        ? styles.layout__overlay__hide
        : styles.layout__overlay__show
    );
  };

  if (!selectedTenantId) {
    return <HomePage />;
  }

  return (
    <QueryRenderer<LayoutQuery>
      variables={{}}
      environment={environment}
      query={layoutQuery}
      render={({ error, props }) => {
        if (error) {
          return (
            <ErrorNotice
              errorText={'Sorry, there was an unexpected error.'}
              buttonText={'Reload'}
              returnUrl={`/`}
            />
          );
        }

        return (
          <div className={styles.layout}>
            <section
              className={`${styles.layout__sidebar} ${styles.layout__sidebar__mobile} ${sidebarClass}`}
            >
              <div className={styles.layout__sidebar__logo}>
                <Link to={`/${selectedTenantId}/dashboard`} onClick={toggleSidebar}>
                  <Logo logo={logo} />
                </Link>
              </div>
              <div className={styles.layout__sidebar__menu}>
                <Sidebar
                  selectedTenantId={selectedTenantId}
                  user={props?.user ?? null}
                  onNavigate={toggleSidebar}
                />
              </div>
            </section>

            <section
              className={`${styles.layout__main} ${styles.layout__main__sidebar} ${mainClass}`}
            >
              <div className={`${styles.layout__navbar} grid-container fluid py-2`}>
                <Navbar
                  breadcrumbs={breadcrumbs}
                  user={props?.user ?? null}
                  selectedTenantId={selectedTenantId}
                  onResponsiveMenuClick={() => toggleSidebar()}
                />
              </div>
              <div className={styles.layout__content}>
                <div className="grid-container fluid py-2 h-100">
                  <div className="grid-x grid-margin-x h-100">{children}</div>
                </div>
              </div>
              <div
                className={`${styles.layout__overlay} ${overlayClass}`}
                onClick={toggleSidebar}
              />
            </section>
          </div>
        );
      }}
    />
  );
};

export default Layout;
