import { matchPath } from 'react-router-dom';
import useRouter from 'use-react-router';
import { breadcrumbsRouteMapping } from '../pages/breadcrumbs';
import { IBreadcrumb } from '../components/Breadcrumbs/lib/breadcrumb';

// temporary solution for tenantId
const createBreadcrumbUrl = (url: string, tenantId: string): string => {
  if (url.indexOf(':tenantId') > 0) {
    return url.replace(':tenantId', tenantId);
  }

  return url;
};

export const useBreadcrumbs = (): IBreadcrumb[] => {
  const { location } = useRouter();
  let match: any;

  const breadcrumbsMapping = breadcrumbsRouteMapping.find(bm => {
    match = matchPath(location.pathname, { path: bm.route, exact: true });

    if (match) {
      return true;
    }
    return false;
  });

  if (!breadcrumbsMapping) {
    return [];
  }

  return breadcrumbsMapping.breadcrumbs.map(b => ({
    ...b,
    url: createBreadcrumbUrl(b.url, match.params.tenantId),
  }));
};
