import React, { useState } from 'react';
import HighlightedContainer from '../../../../../components/HighlightedContainer';
import { ModuleHeader } from '../../../../../components/Module';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelectedTenantId } from '../../../../../hooks/use-tenant-id.hook';
import { Formik, Form, Field } from 'formik';
import { validationSchema } from './lib/validationSchema';
import updateTenantMutation from '../../../../../graphql/mutations/updateTenant.mutation';
import { toast } from 'react-toastify';
import ProgressButton from '../../../../../components/ProgressButton';
import useRouter from 'use-react-router';
import { removeEmptyProperties } from '../../../../../common/helper/removeEmptyProperties.helper';
import { QueryRenderer } from 'react-relay';
import environment from '../../../../../graphql/environment';
import { graphql } from 'babel-plugin-relay/macro';
import { BillingInformationEditPageQuery } from '../../../../../__generated__/BillingInformationEditPageQuery.graphql';
import InputGroup from '../../../../../components/forms/InputGroup';
import Input from '../../../../../components/forms/Input';
import styles from './BillingInformationEditPage.module.scss';
import CountrySelect from '../../../../../components/forms/CountrySelect';
import StateSelect from '../../../../../components/forms/StateSelect';

const billingInformationEditQuery = graphql`
  query BillingInformationEditPageQuery($id: ID!, $language: String!) {
    tenant(id: $id) {
      billingInformation {
        type
        firstName
        lastName
        email
        phone
        company
        street
        streetNumber
        country
        city
        zipCode
        vat
        state
      }
    }

    countries(language: $language) {
      ...CountrySelect_countries
    }
  }
`;

const BillingInformationEditPage = () => {
  const tenantId = useSelectedTenantId();
  const { history } = useRouter();
  const { t, i18n } = useTranslation(['qrpid']);
  const [loading, setLoading] = useState(false);

  const updateBillingInformation = async (values: any) => {
    try {
      setLoading(true);
      await updateTenantMutation({
        id: tenantId,
        billingInformation: {
          ...removeEmptyProperties(values),
          type: values.company ? 'company' : 'person',
        },
      });
      history.push(`/${tenantId}/account`);
      toast.success('Successfully updated your Billing Information.');
    } catch {
      toast.error('Sorry, there was an error while updating your billing information.');
      setLoading(false);
    }
  };

  return (
    <QueryRenderer<BillingInformationEditPageQuery>
      environment={environment}
      variables={{ id: tenantId, language: i18n.language }}
      query={billingInformationEditQuery}
      render={({ error, props }) => {
        if (error) {
          return <div>Sorry, there was an unexpected error.</div>;
        }

        const isLoading = props?.tenant?.billingInformation === undefined;

        return (
          <>
            <ModuleHeader
              title={t('account.title')}
              description={t('account.description')}
              link={''}
            />

            <HighlightedContainer className="cell small-12">
              <div className="grid-container">
                <div className="grid-x grid-margin-x">
                  <div className="small-offset-0 small-12 large-offset-1 large-10">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        type: props?.tenant?.billingInformation?.type ?? '',
                        firstName: props?.tenant?.billingInformation?.firstName ?? '',
                        lastName: props?.tenant?.billingInformation?.lastName ?? '',
                        email: props?.tenant?.billingInformation?.email ?? '',
                        phone: props?.tenant?.billingInformation?.phone ?? '',
                        company: props?.tenant?.billingInformation?.company ?? '',
                        street: props?.tenant?.billingInformation?.street ?? '',
                        streetNumber: props?.tenant?.billingInformation?.streetNumber ?? '',
                        country: props?.tenant?.billingInformation?.country ?? '',
                        city: props?.tenant?.billingInformation?.city ?? '',
                        zipCode: props?.tenant?.billingInformation?.zipCode ?? '',
                        vat: props?.tenant?.billingInformation?.vat ?? '',
                        state: props?.tenant?.billingInformation?.state ?? '',
                      }}
                      onSubmit={updateBillingInformation}
                      validationSchema={validationSchema}
                    >
                      {formProps => {
                        const { errors, touched, values } = formProps;

                        return (
                          <Form translate="no">
                            <div className={styles.billingInformationEditPage__formContainer}>
                              <InputGroup
                                label={t('account.editBillingInformationPage.type.label')}
                                touched={touched.type}
                                error={errors.type}
                                loading={isLoading}
                                required
                                skeletonWidth="150px"
                              >
                                <div className={styles.billingInformationEditPage__type}>
                                  <Field
                                    type="radio"
                                    id="company"
                                    name="type"
                                    value="company"
                                    className={`mb-0 ${styles.billingInformationEditPage__grow}`}
                                  />
                                  <label htmlFor="company">
                                    {t('account.editBillingInformationPage.type.company')}
                                  </label>

                                  <Field
                                    type="radio"
                                    id="person"
                                    name="type"
                                    value="person"
                                    className={`mb-0 ${styles.billingInformationEditPage__grow}`}
                                  />
                                  <label htmlFor="person">
                                    {t('account.editBillingInformationPage.type.person')}
                                  </label>
                                </div>
                              </InputGroup>

                              {isLoading ||
                                (formProps.values.type === 'company' && (
                                  <InputGroup
                                    label={t('account.editBillingInformationPage.company.label')}
                                    touched={touched.company}
                                    error={errors.company}
                                    loading={isLoading}
                                    required
                                    skeletonWidth="250px"
                                  >
                                    <Input
                                      type="text"
                                      name="company"
                                      placeholder={t(
                                        'account.editBillingInformationPage.company.label'
                                      )}
                                      touched={touched.company}
                                      error={errors.company}
                                      hideValidationMessage
                                      inputClassName="mb-0"
                                      className={`mb-0 ${styles.billingInformationEditPage__grow}`}
                                    />
                                  </InputGroup>
                                ))}

                              <InputGroup
                                label={t('account.editBillingInformationPage.name.label')}
                                touched={touched.firstName || touched.lastName}
                                error={errors.firstName || errors.lastName}
                                required
                                loading={isLoading}
                                skeletonWidth="300px"
                              >
                                <div className={styles.billingInformationEditPage__name}>
                                  <Input
                                    type={'text'}
                                    name={'firstName'}
                                    placeholder={t(
                                      'account.editBillingInformationPage.name.firstName'
                                    )}
                                    error={errors.firstName}
                                    touched={touched.firstName}
                                    hideValidationMessage
                                    className={`mr-1 ${styles.billingInformationEditPage__grow}`}
                                    inputClassName="mb-0"
                                  />
                                  <Input
                                    type={'text'}
                                    name={'lastName'}
                                    placeholder={t(
                                      'account.editBillingInformationPage.name.lastName'
                                    )}
                                    error={errors.lastName}
                                    touched={touched.lastName}
                                    hideValidationMessage
                                    className={`ml-1 ${styles.billingInformationEditPage__grow}`}
                                    inputClassName="mb-0"
                                  />
                                </div>
                              </InputGroup>

                              <InputGroup
                                label={t('account.editBillingInformationPage.email.label')}
                                touched={touched.email}
                                error={errors.email}
                                required
                                loading={isLoading}
                                skeletonWidth="250px"
                              >
                                <Input
                                  type="text"
                                  name="email"
                                  placeholder={t('account.editBillingInformationPage.email.label')}
                                  touched={touched.email}
                                  error={errors.email}
                                  hideValidationMessage
                                  inputClassName="mb-0"
                                  className={`mb-0 ${styles.billingInformationEditPage__grow}`}
                                />
                              </InputGroup>

                              <InputGroup
                                label={t('account.editBillingInformationPage.phone.label')}
                                touched={touched.phone}
                                error={errors.phone}
                                loading={isLoading}
                                skeletonWidth="180px"
                              >
                                <Input
                                  type="text"
                                  name="phone"
                                  placeholder={t('account.editBillingInformationPage.phone.label')}
                                  touched={touched.phone}
                                  error={errors.phone}
                                  hideValidationMessage
                                  inputClassName="mb-0"
                                  className={`mb-0 ${styles.billingInformationEditPage__grow}`}
                                />
                              </InputGroup>
                            </div>

                            <hr />

                            <div className={styles.billingInformationEditPage__formContainer}>
                              <InputGroup
                                label={t('account.editBillingInformationPage.street.label')}
                                touched={touched.street}
                                error={errors.street}
                                required
                                loading={isLoading}
                                skeletonWidth="230px"
                              >
                                <Input
                                  type="text"
                                  name="street"
                                  placeholder={t('account.editBillingInformationPage.street.label')}
                                  touched={touched.street}
                                  error={errors.street}
                                  hideValidationMessage
                                  inputClassName="mb-0"
                                  className={`mb-0 ${styles.billingInformationEditPage__grow}`}
                                />
                              </InputGroup>

                              <InputGroup
                                touched={touched.streetNumber}
                                error={errors.streetNumber}
                                loading={isLoading}
                                skeletonWidth="100px"
                              >
                                <Input
                                  type="text"
                                  name="streetNumber"
                                  placeholder={t(
                                    'account.editBillingInformationPage.streetNumber.placeholder'
                                  )}
                                  touched={touched.streetNumber}
                                  error={errors.streetNumber}
                                  hideValidationMessage
                                  inputClassName="mb-0"
                                  className={`mb-0 ${styles.billingInformationEditPage__grow}`}
                                />
                              </InputGroup>

                              <InputGroup
                                touched={touched.zipCode || touched.city}
                                error={errors.zipCode || errors.city}
                                loading={isLoading}
                                skeletonWidth="210px"
                              >
                                <div className={styles.billingInformationEditPage__address__city}>
                                  <Input
                                    type="text"
                                    name="zipCode"
                                    placeholder={t(
                                      'account.editBillingInformationPage.city.zipCode'
                                    )}
                                    touched={touched.zipCode}
                                    error={errors.zipCode}
                                    hideValidationMessage
                                    className={`mr-1 ${styles.billingInformationEditPage__smallWidth}`}
                                    inputClassName="mb-0"
                                  />
                                  <Input
                                    type="text"
                                    name="city"
                                    placeholder={t(
                                      'account.editBillingInformationPage.city.placeholder'
                                    )}
                                    touched={touched.city}
                                    error={errors.city}
                                    hideValidationMessage
                                    className={`ml-1 ${styles.billingInformationEditPage__grow}`}
                                    inputClassName="mb-0"
                                  />
                                </div>
                              </InputGroup>

                              <InputGroup
                                touched={touched.country}
                                error={errors.country}
                                loading={isLoading}
                                skeletonWidth="260px"
                              >
                                <CountrySelect name="country" countries={props?.countries} />
                              </InputGroup>

                              <InputGroup
                                touched={touched.state}
                                error={errors.state}
                                loading={isLoading}
                                skeletonWidth="260px"
                              >
                                <StateSelect name="state" countryCode={values.country} />
                              </InputGroup>
                            </div>

                            <hr />

                            {isLoading ||
                              (formProps.values.type === 'company' && (
                                <div className={styles.billingInformationEditPage__formContainer}>
                                  <InputGroup
                                    label={t('account.editBillingInformationPage.vat.label')}
                                    touched={touched.vat}
                                    error={errors.vat}
                                    loading={isLoading}
                                    skeletonWidth="150px"
                                  >
                                    <Input
                                      type="text"
                                      name="vat"
                                      placeholder={t(
                                        'account.editBillingInformationPage.vat.label'
                                      )}
                                      touched={touched.vat}
                                      error={errors.vat}
                                      hideValidationMessage
                                      inputClassName="mb-0"
                                      className={`${styles.billingInformationEditPage__grow}`}
                                    />
                                  </InputGroup>
                                </div>
                              ))}
                            <div className="clearfix mt-3">
                              <ProgressButton
                                type="submit"
                                className="primary button float-right mb-0"
                                isLoading={loading}
                              >
                                {t('account.editBillingInformationPage.callToAction')}
                              </ProgressButton>

                              <Link
                                to={`/${tenantId}/account`}
                                className="clear button secondary float-right mb-0"
                              >
                                {t('account.editBillingInformationPage.cancel')}
                              </Link>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </HighlightedContainer>
          </>
        );
      }}
    />
  );
};

export default BillingInformationEditPage;
