import React from 'react';
import HighlightedContainer from '../../../../components/HighlightedContainer';
import RedirectForm from '../forms/RedirectForm';
import createQrCodeMutation from '../../../../graphql/mutations/createQrCode.mutation';
import { IRedirectForm } from '../forms/RedirectForm/lib/redirect-form';
import { useSelectedTenantId } from '../../../../hooks/use-tenant-id.hook';
import useRouter from 'use-react-router';
import { toast } from 'react-toastify';

const QrCodeNew = () => {
  const tenantId = useSelectedTenantId();
  const { history } = useRouter();

  const createQrCode = async (newQrCode: IRedirectForm) => {
    try {
      await createQrCodeMutation({ tenantId, ...newQrCode });
      history.push(`/${tenantId}/qr-codes`);
      toast.success('Create QR-Code successful!');
    } catch (err) {
      switch (err) {
        case 'QR_CODES_LIMIT_REACHED':
          toast.error('You have reached the maximum amount of QR-Codes in your plan.');
          break;

        case 'ACTIVATION_LIMIT_REACHED':
          toast.warn('The QR-Code has been saved but could not be activated.');
          history.push(`/${tenantId}/qr-codes`);
          break;

        default:
          toast.success('Sorry, something went wrong.');
      }
    }
  };

  return (
    <HighlightedContainer className="">
      <div className="grid-x grid-margin-x">
        <div className="cell small-12 large-offset-1 large-10">
          <RedirectForm onSubmit={async form => await createQrCode(form)} />
        </div>
      </div>
    </HighlightedContainer>
  );
};

export default QrCodeNew;
