import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import RedirectForm from '../forms/RedirectForm';
import Errors from '../../../../common/constants/errors';
import UrlForm from '../forms/UrlForm';
import WifiForm from '../forms/WifiForm';
import Sidebar from './components/Sidebar';
import updateQrCodeMutation from '../../../../graphql/mutations/updateQrCode.mutation';
import { useSelectedTenantId } from '../../../../hooks/use-tenant-id.hook';
import useRouter from 'use-react-router';
import { History } from 'history';
import { QrCodeDetails_scans } from '../../../../__generated__/QrCodeDetails_scans.graphql';
import { QrCodeDetails_aggregations } from '../../../../__generated__/QrCodeDetails_aggregations.graphql';
import styles from './QrCodeDetails.module.scss';
import {
  QrCodeDetails_qrCode,
  QrCodeType,
} from '../../../../__generated__/QrCodeDetails_qrCode.graphql';
import HighlightedContainer from '../../../../components/HighlightedContainer';
import LatestScansChartWidget from '../../../../components/Widgets/LatestScansChartWidget';
import LatestScansTableWidget from '../../../../components/Widgets/LatestScansTableWidget';
import { SkeletonTitle } from '../../../../components/Skeleton';

interface IProps {
  qrCode: QrCodeDetails_qrCode | null;
  scans: QrCodeDetails_scans | null;
  aggregations: QrCodeDetails_aggregations | null;
}

const renderActionForm = (
  type: QrCodeType,
  qrCode: QrCodeDetails_qrCode,
  tenantId: string,
  history: History<any>
) => {
  switch (type) {
    case 'REDIRECT':
      return (
        <RedirectForm
          initialValues={{
            ...qrCode,
            name: qrCode.name ?? '',
            comment: qrCode.comment ?? '',
            redirectUrl: qrCode.redirectUrl ?? '',
            enabled: qrCode.enabled ?? false,
          }}
          onSubmit={form =>
            updateQrCodeMutation(
              {
                id: qrCode.id,
                tenantId: qrCode.tenantId,
                ...form,
              },
              () => {
                history.push(`/${tenantId}/qr-codes`);
              }
            )
          }
        />
      );
    case 'URL':
      return <UrlForm qrCode={qrCode} />;
    case 'WIFI':
      return <WifiForm qrCode={qrCode} />;
    default:
      throw new Error(`${Errors.UnknownActionType} ${type}`);
  }
};

const QrCodeDetails = ({ qrCode, scans, aggregations }: IProps) => {
  const tenantId = useSelectedTenantId();
  const { history } = useRouter();

  if (qrCode && scans && aggregations) {
    const { type } = qrCode;

    return (
      <HighlightedContainer className="">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-8 large-9">
            {renderActionForm(type, qrCode, tenantId, history)}
          </div>

          <div className="cell medium-4 large-3">
            <Sidebar qrCode={qrCode} />
          </div>

          <div className="cell small-12 mt-2">
            <h4 className="mb-2">Latest Scans</h4>
          </div>

          <div className={`cell medium-12 large-6 ${styles.qrCodeDetails__widget}`}>
            <LatestScansChartWidget aggregations={aggregations} />
          </div>

          <div className={`cell medium-12 large-6 mt-2 mt-large-0 ${styles.qrCodeDetails__widget}`}>
            <LatestScansTableWidget scans={scans} />
          </div>
        </div>
      </HighlightedContainer>
    );
  }
  return (
    <HighlightedContainer className="">
      <div className="grid-x grid-margin-x">
        <div className="cell medium-8 large-9">
          <div style={{ width: '60%' }}>
            <SkeletonTitle />
          </div>
          <div className="mt-2" style={{ width: '40%' }}>
            <SkeletonTitle />
          </div>
          <div className="mt-2" style={{ width: '50%' }}>
            <SkeletonTitle />
          </div>
          <div className="mt-2" style={{ width: '10%' }}>
            <SkeletonTitle />
          </div>
          <div className="mt-2" style={{ width: '80%' }}>
            <SkeletonTitle />
          </div>
          <div className="mt-2" style={{ width: '80%' }}>
            <SkeletonTitle />
          </div>
          <div className="mt-2" style={{ width: '80%' }}>
            <SkeletonTitle />
          </div>
        </div>

        <div className="cell medium-4 large-3">
          <Sidebar qrCode={qrCode} />
        </div>
      </div>
    </HighlightedContainer>
  );
};

export default createFragmentContainer(QrCodeDetails, {
  qrCode: graphql`
    fragment QrCodeDetails_qrCode on QrCode {
      id
      tenantId
      type
      isDynamic
      comment
      name
      ... on RedirectQrCode {
        enabled
        redirectUrl
      }
      ...UrlForm_qrCode
      ...WifiForm_qrCode
      ...Sidebar_qrCode
    }
  `,
  scans: graphql`
    fragment QrCodeDetails_scans on ScanConnection {
      ...LatestScansTableWidget_scans
    }
  `,
  aggregations: graphql`
    fragment QrCodeDetails_aggregations on AggregationConnection {
      ...LatestScansChartWidget_aggregations
    }
  `,
});
