import React, { useState, useEffect } from 'react';
import ticketService from '../lib/ticketService';
import useRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router';

// import styles from './TicketDetailsPage.module.scss';
import { Link } from 'react-router-dom';
import { useSelectedTenantId } from '../../../hooks/use-tenant-id.hook';
import HighlightedContainer from '../../../components/HighlightedContainer';
import { ModuleHeader } from '../../../components/Module';
import { SkeletonTitle } from '../../../components/Skeleton';
import { useTranslation } from 'react-i18next';

const TicketDetailsPage = () => {
  const { match }: RouteComponentProps<any> = useRouter();
  const [ticket, setTicket] = useState({
    no: '',
    fullName: '',
    email: '',
    subject: '',
    description: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const { id } = match.params;
  const tenantId = useSelectedTenantId();
  const { t } = useTranslation(['qrpid']);

  useEffect(() => {
    ticketService
      .getTicket(tenantId, id)
      .then((response: { data: any }) => {
        setTicket(response.data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [tenantId, id]);

  return (
    <>
      <ModuleHeader
        title={t('tickets.details.title')}
        description={t('tickets.description')}
        link={''}
      />
      {isLoading ? (
        <HighlightedContainer className="cell small-12 mt-2" padding={true}>
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="small-offset-0 small-12 large-offset-1 large-10">
                <div style={{ width: '20%' }}>
                  <SkeletonTitle />
                </div>
                <hr />
                <div style={{ width: '80%' }}>
                  <SkeletonTitle />
                </div>
                <div className="mt-2" style={{ width: '60%' }}>
                  <SkeletonTitle />
                </div>
                <div className="clearfix">
                  <div className="cell small-12 mt-2">
                    <Link to={''} className="button disabled secondary float-right mb-0">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HighlightedContainer>
      ) : (
        <HighlightedContainer className="cell small-12 mt-2" padding={true}>
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="small-offset-0 small-12 large-offset-1 large-10">
                <p>
                  <strong>{t('tickets.columns.no')}:</strong> {ticket.no}
                </p>
                <hr />
                <p>
                  <strong>{t('tickets.columns.name')}:</strong> {ticket.fullName}
                </p>
                <p>
                  <strong>{t('tickets.columns.email')}:</strong>{' '}
                  <a
                    href={`mailto:${ticket.email}?subject=Re: ${ticket.subject}&body=${ticket.description}`}
                  >
                    {ticket.email}
                  </a>
                </p>
                <p>
                  <strong>{t('tickets.columns.subject')}:</strong> {ticket.subject}
                </p>
                <hr />
                <p>
                  <strong>{t('tickets.columns.description')}:</strong> {ticket.description}
                </p>
                <div className="clearfix">
                  <Link
                    to={`/${tenantId}/support/tickets`}
                    className="button secondary float-right mb-0"
                  >
                    Cancel
                  </Link>
                </div>{' '}
              </div>
            </div>
          </div>
        </HighlightedContainer>
      )}
    </>
  );
};

export default TicketDetailsPage;
