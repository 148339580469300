import React from 'react';

interface IProps {
  className?: string;
}

const QrpidLogo = ({ className }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329 100" className={className}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M178.65 43.16q0 10.22-3.63 17.14a22.06 22.06 0 0 1-10.64 10.12l14 15.58H162.6L152 73h-.91q-13.32 0-20.45-7.73t-7.14-22.19q0-14.47 7.15-22.1t20.52-7.63q13.35 0 20.43 7.69t7.05 22.12zm-42 .76q0 9.49 3.6 14.3T151.07 63q14.37 0 14.37-19.11t-14.3-19.14q-7.17 0-10.81 4.82t-3.64 14.35zM198 50v22h-12V15h16.49q11.53 0 17.07 4.23t5.54 12.85a15.19 15.19 0 0 1-2.75 8.92 18.24 18.24 0 0 1-7.78 6.15Q227.34 66.38 231.21 72h-13.05l-13.69-22zm0-10h3.8q5.58 0 8.24-1.86t2.66-5.85q0-3.94-2.72-5.62T201.57 25H198zM256.13 73.38q-9.85 0-14.37-7.38l.12 3.16C242 71 242 73 242 75.25V85h-4V30h3.25l.74 6h.16q4.4-6.91 14.06-6.91 8.64 0 13.17 5.67t4.54 16.51q0 10.53-4.77 16.32t-13.02 5.79zm0-3.68a11.72 11.72 0 0 0 10-4.81q3.56-4.8 3.55-13.45 0-18.55-13.41-18.56-7.37 0-10.81 4T242 50.12v1.25c0 6.58 1.11 11.29 3.31 14.1s5.81 4.23 10.8 4.23zM280.86 18.5c0-2.48.82-3.72 2.44-3.72a2.15 2.15 0 0 1 1.88 1 4.7 4.7 0 0 1 .68 2.75 4.8 4.8 0 0 1-.68 2.75 2.14 2.14 0 0 1-1.88 1c-1.62-.02-2.44-1.28-2.44-3.78zM285 72h-4V30h4zM310.8 29.67a17.45 17.45 0 0 1 7.86 1.66A15.75 15.75 0 0 1 324.6 37h.65q-.25-4.95-.25-9.69V15h4v57h-2.35l-.9-6h-1.15a15.51 15.51 0 0 1-13.73 7.17q-8.67 0-13.26-5.4T293 52q0-10.87 4.55-16.62t13.25-5.71zm.32 3.21q-6.89 0-10.36 4.84t-3.47 14.07q0 17.91 13.91 17.91c4.76 0 8.24-1.39 10.47-4.15S325 58.22 325 51.87v-.66q0-9.76-3.28-14.05t-10.6-4.28zM41 41v-5H28v-8h8v13h10V23a5 5 0 0 0-5-5H23a5 5 0 0 0-5 5v18a5 5 0 0 0 5 5h18a5 5 0 0 0 5-5zM72 23v13H59a5 5 0 0 0 0 10h18a5 5 0 0 0 5-5V23a5 5 0 0 0-10 0zM77 100h18a5 5 0 0 0 5-5V77a5 5 0 0 0-10 0v13H77a5 5 0 0 0 0 10zM0 77v18a5 5 0 0 0 5 5h18a5 5 0 0 0 0-10H10V77a5 5 0 0 0-10 0zM23 0H5a5 5 0 0 0-5 5v18a5 5 0 0 0 10 0V10h13a5 5 0 0 0 0-10zM100 23V5a5 5 0 0 0-5-5H77a5 5 0 0 0 0 10h13v13a5 5 0 0 0 10 0zM46 77V59a5 5 0 0 0-10 0v18a5 5 0 0 0 10 0M77 77v-5H64v-8h8v13h10V59a5 5 0 0 0-5-5H59a5 5 0 0 0-5 5v18a5 5 0 0 0 5 5h18a5 5 0 0 0 5-5z" />
          <circle cx="61.5" cy="24.5" r="6.5" />
          <circle cx="24.5" cy="59.5" r="6.5" />
          <circle cx="24.5" cy="75.5" r="6.5" />
        </g>
      </g>
    </svg>
  );
};

export default QrpidLogo;
