/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type BillingType = 'company' | 'person' | '%future added value';
export type BillingInformation_billingInformation = {
  readonly type: BillingType;
  readonly firstName: string;
  readonly lastName: string;
  readonly company: string | null;
  readonly city: string;
  readonly zipCode: string;
  readonly street: string;
  readonly streetNumber: string | null;
  readonly ' $refType': 'BillingInformation_billingInformation';
};
export type BillingInformation_billingInformation$data = BillingInformation_billingInformation;
export type BillingInformation_billingInformation$key = {
  readonly ' $data'?: BillingInformation_billingInformation$data;
  readonly ' $fragmentRefs': FragmentRefs<'BillingInformation_billingInformation'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'BillingInformation_billingInformation',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'type',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstName',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lastName',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'company',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'city',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'zipCode',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'street',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'streetNumber',
      storageKey: null,
    },
  ],
  type: 'BillingInformation',
};
(node as any).hash = 'f2e2762eee28f19e6f8d67c5cee24865';
export default node;
