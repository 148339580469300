import React, { memo } from 'react';
import Widget from '../Widget';
import { ChartData, ChartOptions } from 'chart.js';
import Chart, { ChartType } from '../../Chart';

interface IProps {
  headline?: string;
  missingDataText?: string;
  type: ChartType;
  data?: ChartData;
  options?: ChartOptions;
}

const hasData = (data: ChartData | undefined): boolean => {
  if (!data || !data.datasets || data.datasets.length <= 0) {
    return false;
  }

  for (const dataset of data.datasets) {
    if (dataset.data) {
      for (const d of dataset.data) {
        if (d && d > 0) {
          return true;
        }
      }
    }
  }

  return false;
};

const exampleChartData: ChartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      data: [65, 59, 80, 120, 56, 55, 40],
    },
  ],
};

const ChartWidget = ({ headline, type, data, options, missingDataText }: IProps) => {
  const noData = !hasData(data) ? (missingDataText ? missingDataText : 'No data') : undefined;

  return (
    <Widget headline={headline} missingDataText={noData}>
      <Chart type={type} data={data ? data : exampleChartData} options={options} />
    </Widget>
  );
};

export default memo(ChartWidget);
