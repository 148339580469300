import React, { ReactNode } from 'react';
import styles from './SkeletonWidget.module.scss';
import HighlightedContainer from '../../HighlightedContainer';
import { SkeletonTitle } from '../../Skeleton';

interface IProps {
  headline?: string;
  children?: ReactNode;
  noPadding?: boolean;
  missingDataText?: string;
}

const SkeletonWidget = ({ headline, noPadding }: IProps) => {
  return (
    <HighlightedContainer className={styles.skeleton_widget} padding={false}>
      {headline ? (
        <div className={styles.skeleton_widget__headline}>
          <div style={{ width: '40%' }}>
            <SkeletonTitle />
          </div>
        </div>
      ) : null}
      <div className={`${styles.skeleton_widget__content} ${!noPadding ? 'mx-1 mb-1' : ''}`}>
        <div className={styles.sp_circle} />
      </div>
    </HighlightedContainer>
  );
};

export default SkeletonWidget;
