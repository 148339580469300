import React from 'react';
import { QueryRenderer } from 'react-relay';
import environment from '../../../../graphql/environment';
import useRouter from 'use-react-router';
import { RouteComponentProps } from 'react-router';
import { graphql } from 'babel-plugin-relay/macro';
import { QrCodeDetailsPageQuery } from '../../../../__generated__/QrCodeDetailsPageQuery.graphql';
import { useSelectedTenantId } from '../../../../hooks/use-tenant-id.hook';
import ErrorNotice from '../../../../components/ErrorNotice';
import { ModuleHeader } from '../../../../components/Module';
import { useTranslation } from 'react-i18next';
import QrCodeDetails from '../../components/QrCodeDetails';

export const qrCodeByIdAndTenantId = graphql`
  query QrCodeDetailsPageQuery($id: ID!, $tenantId: String!) {
    node(id: $id, tenantId: $tenantId) {
      ... on QrCode {
        ...QrCodeDetails_qrCode
      }
    }
    scans(tenantId: $tenantId, qrCodeId: $id, first: 5) {
      ...QrCodeDetails_scans
    }
    aggregations(tenantId: $tenantId, id: $id, first: 30) {
      ...QrCodeDetails_aggregations
    }
  }
`;

const QrCodeDetailsPage = () => {
  const { match }: RouteComponentProps<any> = useRouter();
  const tenantId = useSelectedTenantId();
  const { t } = useTranslation(['qrpid']);

  return (
    <div className="cell small-12">
      <ModuleHeader
        title={t('qrcodes.details.title')}
        description={t('qrcodes.description')}
        link={''}
      />

      <QueryRenderer<QrCodeDetailsPageQuery>
        variables={{
          id: match.params.id,
          tenantId: match.params.tenantId,
        }}
        environment={environment}
        query={qrCodeByIdAndTenantId}
        render={({ error, props }) => {
          if (error) {
            return (
              <ErrorNotice
                errorText={'Sorry, there was an error while loading the QR Code Details.'}
                buttonText={'Back to Manage QR Codes'}
                returnUrl={`/${tenantId}/qr-codes`}
              />
            );
          }

          const node = props ? props.node : null;
          const scans = props ? props.scans : null;
          const aggregations = props ? props.aggregations : null;

          return <QrCodeDetails qrCode={node} scans={scans} aggregations={aggregations} />;
        }}
      />
    </div>
  );
};

export default QrCodeDetailsPage;
