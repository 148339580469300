import React, { useEffect, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import qrCodeGenerator from 'qrcode';
import styles from './QrCodeWithDownload.module.scss';
import { QrCodeWithDownload_qrCode } from '../../../../../../../../__generated__/QrCodeWithDownload_qrCode.graphql';
import { b64toBlob } from '../../../../../../../../common/helper/base64-to-blob.helper';
import DownloadFileLink from '../../../../../../../../components/DownloadFileLink';
import QrCode from '../../../../../../../../components/QrCode';
import Errors from '../../../../../../../../common/constants/errors';

interface IProps {
  qrCode: QrCodeWithDownload_qrCode;
}

const createQrCodeContent = ({
  type,
  qrCodeUrl,
  url,
  encryption,
  ssid,
  password,
}: QrCodeWithDownload_qrCode) => {
  switch (type) {
    case 'REDIRECT':
      return qrCodeUrl ? qrCodeUrl : '';
    case 'URL':
      return url ? url : '';
    case 'WIFI':
      return `WIFI:T:${encryption};S:${ssid};P:${password};;`;
    default:
      throw new Error(`${Errors.UnknownActionType} ${type}`);
  }
};

const QrCodeWithDownload = ({ qrCode }: IProps) => {
  const [qrCodeFile, setQrCodeFile] = useState();
  const content = createQrCodeContent(qrCode);

  useEffect(() => {
    let isSubscribed = true;

    const createQrCodeFile = async () => {
      const b64Image = await qrCodeGenerator.toDataURL(content);
      const blob = b64toBlob(b64Image);

      if (isSubscribed) {
        setQrCodeFile(blob);
      }
    };

    if (isSubscribed) {
      createQrCodeFile();
    }

    return () => {
      isSubscribed = false;
    };
  }, [qrCode, content]);

  return (
    <div className={styles.qrCodeWithDownload}>
      <QrCode content={content} />
      <DownloadFileLink
        className={styles.qrCodeWithDownload__downloadLink}
        filename={`${qrCode.serialNumber}.png`}
        file={qrCodeFile}
      />
    </div>
  );
};

export default createFragmentContainer(QrCodeWithDownload, {
  qrCode: graphql`
    fragment QrCodeWithDownload_qrCode on QrCode {
      serialNumber
      type
      isDynamic
      ... on RedirectQrCode {
        qrCodeUrl
      }
      ... on UrlQrCode {
        url
      }
      ... on WifiQrCode {
        ssid
        password
        encryption
      }
    }
  `,
});
