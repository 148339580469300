import React from 'react';
import styles from './Navbar.module.scss';
import Breadcrumbs from '../../../Breadcrumbs';
import { IBreadcrumb } from '../../../Breadcrumbs/lib/breadcrumb';
import TenantSelect from './components/TenantSelect';
import ResponsiveMenuButton from './components/ResponsiveMenuButton';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Navbar_user } from '../../../../__generated__/Navbar_user.graphql';

interface IProps {
  breadcrumbs: IBreadcrumb[];
  selectedTenantId: string;
  user: Navbar_user | null | undefined;
  onResponsiveMenuClick: () => void;
}

const Navbar = ({ breadcrumbs, selectedTenantId, user, onResponsiveMenuClick }: IProps) => (
  <div className={styles.navbar}>
    <ul className={styles.navbar__list}>
      <li className={`${styles.navbar__list__item} show-for-small-only`}>
        <ResponsiveMenuButton onClick={onResponsiveMenuClick} />
      </li>
      <li className={`${styles.navbar__list__item} show-for-medium`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </li>
    </ul>

    <ul className={styles.navbar__list}>
      <li className={styles.navbar__list__item}>
        <TenantSelect user={user} selectedTenantId={selectedTenantId} />
      </li>
    </ul>
  </div>
);

export default createFragmentContainer(Navbar, {
  user: graphql`
    fragment Navbar_user on User {
      ...TenantSelect_user
    }
  `,
});
