import React from 'react';

interface IProps {
  className?: string;
}

const Edit = ({ className }: IProps) => {
  return (
    <svg viewBox="0 0 381 381" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M370.59 230.965c-5.524 0-10 4.476-10 10v88.793c-.02 16.558-13.438 29.98-30 30H50c-16.563-.02-29.98-13.442-30-30V69.168c.02-16.563 13.438-29.98 30-30h88.79c5.523 0 10-4.477 10-10s-4.477-10-10-10H50c-27.602.031-49.969 22.398-50 50v260.59c.031 27.601 22.398 49.969 50 50h280.59c27.601-.031 49.969-22.399 50-50v-88.79c0-5.523-4.477-10.003-10-10.003zm0 0" />
      <path d="M156.367 178.344L302.38 32.328l47.09 47.09-146.012 146.016zm0 0M132.543 249.258l52.039-14.414-37.625-37.625zm0 0M362.488 7.578c-9.77-9.746-25.586-9.746-35.355 0l-10.606 10.606 47.09 47.09 10.606-10.606c9.75-9.77 9.75-25.586 0-35.355zm0 0" />
    </svg>
  );
};

export default Edit;
