import React from 'react';
import styles from './Breadcrumbs.module.scss';
import { IBreadcrumb } from './lib/breadcrumb';
import { Link } from 'react-router-dom';

interface IProps {
  breadcrumbs: IBreadcrumb[];
}

const Breadcrumbs = ({ breadcrumbs }: IProps) => (
  <ul className={`breadcrumbs ${styles.breadcrumbs__list}`}>
    {breadcrumbs.map(b => (
      <li key={b.name + b.url}>{b.url ? <Link to={b.url}>{b.name}</Link> : b.name}</li>
    ))}
  </ul>
);

export default Breadcrumbs;
