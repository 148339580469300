import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { ActivationAlert_qrCodeSubscription } from '../../../../../../__generated__/ActivationAlert_qrCodeSubscription.graphql';
import styles from './ActivationAlert.module.scss';
import { useSelectedTenantId } from '../../../../../../hooks/use-tenant-id.hook';
import Warning from '../../../../../../components/Icons/Warning';
import mailtoLink from 'mailto-link';
import { useTranslation } from 'react-i18next';

interface IProps {
  qrCodeSubscription: ActivationAlert_qrCodeSubscription;
}

const createMailto = (tenantId: string): string => {
  return mailtoLink({
    to: 'support@qrpid.io',
    subject: 'Plan upgrade',
    body: `Hi QRpid Team,\n\nI would like to upgrade my QRpid plan.\n\nAmount of QR-Codes I need: 10 (change this to the amount you need)\n\nPlease send me an offer.\n\nBest regards\n\n\n\nAdditional Information:\nTenant: ${tenantId}`,
  });
};

const ActivationAlert = ({
  qrCodeSubscription: { maxQrCodeActivations, currentQrCodeActivations },
}: IProps) => {
  const { t } = useTranslation(['qrpid']);
  const tenantId = useSelectedTenantId();

  if (currentQrCodeActivations < maxQrCodeActivations) {
    return null;
  }

  return (
    <div className={`callout warning ${styles.activationAlert}`}>
      <Warning className={styles.activationAlert__icon} />
      <div>
        <p className="mb-1">
          <strong>{t('qrcodes.activationAlert.headline')}</strong>
        </p>
        <p className="mb-0">
          <a href={createMailto(tenantId)} className="button secondary small mb-0">
            {t('qrcodes.activationAlert.callToAction')}
          </a>
        </p>
      </div>
    </div>
  );
};

export default createFragmentContainer(ActivationAlert, {
  qrCodeSubscription: graphql`
    fragment ActivationAlert_qrCodeSubscription on QrCodeSubscription {
      maxQrCodeActivations
      currentQrCodeActivations
    }
  `,
});
