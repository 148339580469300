import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../components/Auth/components/AuthContextProvider/AuthContextProvider';
import { useAsync } from 'react-async-hook';
import Auth from '@aws-amplify/auth';
import { Redirect } from 'react-router';
import useRouter from 'use-react-router';

interface IProps {
  authState: string;
}

const AuthPage = ({ authState }: IProps) => {
  const auth = useContext(AuthContext);
  const user = useAsync(Auth.currentAuthenticatedUser, []);
  const { location } = useRouter();

  useEffect(() => {
    if (
      auth.authState !== authState &&
      auth.authState !== 'signedIn' &&
      auth.authState !== 'loading'
    ) {
      auth.changeState(authState, { username: (location.state as any)?.username });
    }
  }, [auth, authState, user, location.state]);

  if ((!user.loading && user.result) || auth.authState === 'signedIn') {
    return <Redirect to={'/'} />;
  }

  return null;
};

export default AuthPage;
