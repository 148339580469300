import DashboardPage from './dashboard';
import { IRoute } from '../common/routing/route';
import { RouteType } from '../common/routing/route-type';
import QrCodesPage from '../modules/qrCodes/pages/QrCodeList';
import QrCodeDetailsPage from '../modules/qrCodes/pages/QrCodeDetails';
import HomePage from './home';
import UserProfilePage from './user/profile';
import { TicketsPage, TicketDetailsPage } from '../modules/tickets';
import { AccountPage, BillingInformationEditPage } from '../modules/account';
import QrCodeNewPage from '../modules/qrCodes/pages/QrCodeNew';
// import {
//   ProductsPage,
//   ProductDetailsPage,
//   ProductNewPage,
//   ProductEditPage,
// } from '../modules/products';

export const rootRoute = '/';
export const dashboardRoute = '/:tenantId/dashboard';
export const qrCodesRoute = '/:tenantId/qr-codes';
export const qrCodeDetailsRoute = '/:tenantId/qr-codes/:id';
export const qrCodeNewRoute = '/:tenantId/qr-codes/new-qr-code';
export const userProfileRoute = '/:tenantId/user/profile';
export const supportRoute = '/:tenantId/support';
export const ticketsRoute = '/:tenantId/support/tickets';
export const ticketDetailsRoute = '/:tenantId/support/tickets/:id';
export const productsRoute = '/:tenantId/products';
export const productDetailsRoute = '/:tenantId/products/:id';
export const productNewRoute = '/:tenantId/products/new-product';
export const productEditRoute = '/:tenantId/products/:id/edit-product';
export const accountRoute = '/:tenantId/account';
export const billingInformationRoute = '/:tenantId/account/billing-information';

export const routes: IRoute[] = [
  {
    type: RouteType.Exact,
    path: rootRoute,
    title: 'Home - QRpid Portal',
    component: HomePage,
  },
  {
    type: RouteType.Exact,
    path: dashboardRoute,
    title: 'Dashboard - QRpid Portal',
    component: DashboardPage,
  },
  {
    type: RouteType.Exact,
    path: qrCodesRoute,
    title: 'QR Codes - QRpid Portal',
    component: QrCodesPage,
  },
  {
    type: RouteType.Exact,
    path: qrCodeNewRoute,
    title: 'Create QR-Code - QRpid Portal',
    component: QrCodeNewPage,
  },
  {
    type: RouteType.Exact,
    path: qrCodeDetailsRoute,
    title: 'QR Code Details - QRpid Portal',
    component: QrCodeDetailsPage,
  },
  {
    type: RouteType.Exact,
    path: userProfileRoute,
    title: 'User Profile - QRpid Portal',
    component: UserProfilePage,
  },
  {
    type: RouteType.Exact,
    path: accountRoute,
    title: 'Account - QRpid Portal',
    component: AccountPage,
  },
  {
    type: RouteType.Exact,
    path: billingInformationRoute,
    title: 'Billing Information - QRpid Portal',
    component: BillingInformationEditPage,
  },
  // {
  //   type: RouteType.Exact,
  //   path: productsRoute,
  //   title: 'Products - QRpid Portal',
  //   component: ProductsPage,
  // },
  // {
  //   type: RouteType.Exact,
  //   path: productNewRoute,
  //   title: 'Product New - QRpid Portal',
  //   component: ProductNewPage,
  // },
  // {
  //   type: RouteType.Exact,
  //   path: productDetailsRoute,
  //   title: 'Product Details - QRpid Portal',
  //   component: ProductDetailsPage,
  // },
  // {
  //   type: RouteType.Exact,
  //   path: productEditRoute,
  //   title: 'Product Edit - QRpid Portal',
  //   component: ProductEditPage,
  // },
  {
    type: RouteType.Exact,
    path: ticketsRoute,
    title: 'Support Tickets - QRpid Portal',
    component: TicketsPage,
  },
  {
    type: RouteType.Exact,
    path: ticketDetailsRoute,
    title: 'Support Ticket Details - QRpid Portal',
    component: TicketDetailsPage,
  },
  {
    type: RouteType.Redirect,
    path: rootRoute,
    redirectPath: rootRoute,
    title: '',
  },
];
