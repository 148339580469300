/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
export type BillingType = 'company' | 'person' | '%future added value';
export type UpdateTenantInput = {
  id: string;
  name?: string | null;
  billingInformation?: BillingInformationInput | null;
  clientMutationId?: string | null;
};
export type BillingInformationInput = {
  type: BillingType;
  company?: string | null;
  vat?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  streetNumber?: string | null;
  zipCode: string;
  city: string;
  country: string;
  state?: string | null;
  phone?: string | null;
};
export type updateTenantMutationVariables = {
  input: UpdateTenantInput;
};
export type updateTenantMutationResponse = {
  readonly updateTenant: {
    readonly tenant: {
      readonly id: string;
    };
  } | null;
};
export type updateTenantMutation = {
  readonly response: updateTenantMutationResponse;
  readonly variables: updateTenantMutationVariables;
};

/*
mutation updateTenantMutation(
  $input: UpdateTenantInput!
) {
  updateTenant(input: $input) {
    tenant {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'input',
        type: 'UpdateTenantInput!',
      },
    ],
    v1 = [
      {
        alias: null,
        args: [
          {
            kind: 'Variable',
            name: 'input',
            variableName: 'input',
          },
        ],
        concreteType: 'UpdateTenantPayload',
        kind: 'LinkedField',
        name: 'updateTenant',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Tenant',
            kind: 'LinkedField',
            name: 'tenant',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'updateTenantMutation',
      selections: v1 /*: any*/,
      type: 'Mutation',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'updateTenantMutation',
      selections: v1 /*: any*/,
    },
    params: {
      id: null,
      metadata: {},
      name: 'updateTenantMutation',
      operationKind: 'mutation',
      text:
        'mutation updateTenantMutation(\n  $input: UpdateTenantInput!\n) {\n  updateTenant(input: $input) {\n    tenant {\n      id\n    }\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'bac379bf3b177eb300ce7f52d0b508bc';
export default node;
