/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type UserMenu_user = {
  readonly email: string;
  readonly firstname: string | null;
  readonly ' $refType': 'UserMenu_user';
};
export type UserMenu_user$data = UserMenu_user;
export type UserMenu_user$key = {
  readonly ' $data'?: UserMenu_user$data;
  readonly ' $fragmentRefs': FragmentRefs<'UserMenu_user'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'UserMenu_user',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'email',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'firstname',
      storageKey: null,
    },
  ],
  type: 'User',
};
(node as any).hash = '26b685e10d3199a109ef5447d88ce9d4';
export default node;
