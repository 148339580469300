import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import TableWidget from '../TableWidget';
import { LatestScansTableWidget_scans } from '../../../__generated__/LatestScansTableWidget_scans.graphql';
import { toLocaleDateTime } from '../../../common/helper/dateFormat.helper';
import useRouter from 'use-react-router';
import { useSelectedTenantId } from '../../../hooks/use-tenant-id.hook';
import SkeletonWidget from '../SkeletonWidget';

interface IProps {
  scans: LatestScansTableWidget_scans | null;
  showHeadline?: boolean;
  clickableRows?: boolean;
}

const createTableRows = (scans: LatestScansTableWidget_scans): string[][] => {
  if (!scans || !scans.edges || scans.edges.length <= 0) {
    return [];
  }

  return scans.edges.map(s =>
    s
      ? [
          toLocaleDateTime(s.node.timestamp),
          s.node.country ? s.node.country : '',
          s.node.city ? s.node.city : '',
          s.node.browser ? s.node.browser : '',
          s.node.device ? s.node.device : '',
          s.node.os ? s.node.os : '',
        ]
      : []
  );
};

const LatestScansTableWidget = ({ scans, showHeadline, clickableRows }: IProps) => {
  const { history } = useRouter();
  const tenantId = useSelectedTenantId();

  if (!scans) {
    return (
      <SkeletonWidget headline={showHeadline ? 'Latest Scans' : undefined} missingDataText={''}>
        <>Loading...</>
      </SkeletonWidget>
    );
  }

  const onClickRow = (index: number) => {
    if (!scans || !scans.edges) {
      return;
    }

    const edge = scans.edges[index];
    const qrCodeId = edge ? edge.node.qrCodeId : undefined;

    if (!qrCodeId) {
      return;
    }

    history.push(`/${tenantId}/qr-codes/${qrCodeId}`);
  };

  return (
    <TableWidget
      headline={showHeadline ? 'Latest Scans' : undefined}
      columns={['Date', 'Country', 'City', 'Browser', 'Device', 'OS Version']}
      rows={createTableRows(scans)}
      onClickRow={clickableRows ? onClickRow : undefined}
      missingDataText={`No scans during the last ${scans.retentionTimeInDays} days.`}
    />
  );
};

export default createFragmentContainer(LatestScansTableWidget, {
  scans: graphql`
    fragment LatestScansTableWidget_scans on ScanConnection {
      edges {
        node {
          timestamp
          country
          city
          browser
          device
          os
          qrCodeId
        }
      }
      retentionTimeInDays
    }
  `,
});
