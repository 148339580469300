import React, { ReactElement } from 'react';
import styles from './Logo.module.scss';

interface IProps {
  logo: ReactElement;
}

const Logo = ({ logo }: IProps) => {
  return <div className={styles.logo}>{logo}</div>;
};

export default Logo;
