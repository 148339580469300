/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type DashboardPageQueryVariables = {
  tenantId: string;
};
export type DashboardPageQueryResponse = {
  readonly scans: {
    readonly ' $fragmentRefs': FragmentRefs<'StaticDashboard_scans'>;
  } | null;
  readonly aggregations: {
    readonly ' $fragmentRefs': FragmentRefs<'StaticDashboard_aggregations'>;
  } | null;
};
export type DashboardPageQuery = {
  readonly response: DashboardPageQueryResponse;
  readonly variables: DashboardPageQueryVariables;
};

/*
query DashboardPageQuery(
  $tenantId: String!
) {
  scans(tenantId: $tenantId, first: 10) {
    ...StaticDashboard_scans
  }
  aggregations(tenantId: $tenantId, first: 30) {
    ...StaticDashboard_aggregations
  }
}

fragment LatestScansChartWidget_aggregations on AggregationConnection {
  edges {
    node {
      timestamp
      count
    }
  }
  retentionTimeInDays
}

fragment LatestScansTableWidget_scans on ScanConnection {
  edges {
    node {
      timestamp
      country
      city
      browser
      device
      os
      qrCodeId
      id
    }
  }
  retentionTimeInDays
}

fragment StaticDashboard_aggregations on AggregationConnection {
  ...LatestScansChartWidget_aggregations
}

fragment StaticDashboard_scans on ScanConnection {
  ...LatestScansTableWidget_scans
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'tenantId',
        type: 'String!',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'tenantId',
      variableName: 'tenantId',
    },
    v2 = [
      {
        kind: 'Literal',
        name: 'first',
        value: 10,
      },
      v1 /*: any*/,
    ],
    v3 = [
      {
        kind: 'Literal',
        name: 'first',
        value: 30,
      },
      v1 /*: any*/,
    ],
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timestamp',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'retentionTimeInDays',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'DashboardPageQuery',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'ScanConnection',
          kind: 'LinkedField',
          name: 'scans',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'StaticDashboard_scans',
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'AggregationConnection',
          kind: 'LinkedField',
          name: 'aggregations',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'StaticDashboard_aggregations',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'DashboardPageQuery',
      selections: [
        {
          alias: null,
          args: v2 /*: any*/,
          concreteType: 'ScanConnection',
          kind: 'LinkedField',
          name: 'scans',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'ScanEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'Scan',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'country',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'city',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'browser',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'device',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'os',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'qrCodeId',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            v5 /*: any*/,
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'AggregationConnection',
          kind: 'LinkedField',
          name: 'aggregations',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'AggregationEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'AggregationItem',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v4 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'count',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            v5 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'DashboardPageQuery',
      operationKind: 'query',
      text:
        'query DashboardPageQuery(\n  $tenantId: String!\n) {\n  scans(tenantId: $tenantId, first: 10) {\n    ...StaticDashboard_scans\n  }\n  aggregations(tenantId: $tenantId, first: 30) {\n    ...StaticDashboard_aggregations\n  }\n}\n\nfragment LatestScansChartWidget_aggregations on AggregationConnection {\n  edges {\n    node {\n      timestamp\n      count\n    }\n  }\n  retentionTimeInDays\n}\n\nfragment LatestScansTableWidget_scans on ScanConnection {\n  edges {\n    node {\n      timestamp\n      country\n      city\n      browser\n      device\n      os\n      qrCodeId\n      id\n    }\n  }\n  retentionTimeInDays\n}\n\nfragment StaticDashboard_aggregations on AggregationConnection {\n  ...LatestScansChartWidget_aggregations\n}\n\nfragment StaticDashboard_scans on ScanConnection {\n  ...LatestScansTableWidget_scans\n}\n',
    },
  };
})();
(node as any).hash = '043b0930f2ef33ca4dfc1dfdd0dc523f';
export default node;
