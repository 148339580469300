import { commitMutation } from 'react-relay';
import environment from '../environment';
import { graphql } from 'babel-plugin-relay/macro';
import { toast } from 'react-toastify';
import { UpdateQrCodeInput } from '../../__generated__/updateQrCodeMutation.graphql';

const mutation = graphql`
  mutation updateQrCodeMutation($input: UpdateQrCodeInput!) {
    updateQrCode(input: $input) {
      qrCode {
        id
        tenantId
        type
        isDynamic
        comment
      }
    }
  }
`;
export default (input: UpdateQrCodeInput, callback: () => void) => {
  const variables = {
    input: {
      ...input,
      clientMutationId: '',
    },
  };

  commitMutation(environment, {
    mutation,
    variables,
    // optimisticUpdater: proxyStore => {},
    // updater: proxyStore => {},
    onCompleted: () => {
      toast.success('QR Code update successful!');
      callback();
    },
    onError: (err: any) => {
      if (err.source?.errors[0]?.extensions?.response?.body?.error === 'ACTIVATION_LIMIT_REACHED') {
        toast.warn('Your changes have been saved but could not activate QR-Code.');
        callback();
      } else {
        toast.error('Sorry! Something went wrong.');
      }
    },
  });
};
