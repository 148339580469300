/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type SidebarQueryVariables = {
  tenantId: string;
};
export type SidebarQueryResponse = {
  readonly tenant: {
    readonly permissions: ReadonlyArray<{
      readonly name: string;
    }> | null;
    readonly ' $fragmentRefs': FragmentRefs<'UserMenu_tenant'>;
  } | null;
};
export type SidebarQuery = {
  readonly response: SidebarQueryResponse;
  readonly variables: SidebarQueryVariables;
};

/*
query SidebarQuery(
  $tenantId: ID!
) {
  tenant(id: $tenantId) {
    permissions {
      name
    }
    ...UserMenu_tenant
    id
  }
}

fragment UserMenu_tenant on Tenant {
  id
  permissions {
    name
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'tenantId',
        type: 'ID!',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'tenantId',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      concreteType: 'Permission',
      kind: 'LinkedField',
      name: 'permissions',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null,
        },
      ],
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'SidebarQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Tenant',
          kind: 'LinkedField',
          name: 'tenant',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'UserMenu_tenant',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'SidebarQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'Tenant',
          kind: 'LinkedField',
          name: 'tenant',
          plural: false,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'SidebarQuery',
      operationKind: 'query',
      text:
        'query SidebarQuery(\n  $tenantId: ID!\n) {\n  tenant(id: $tenantId) {\n    permissions {\n      name\n    }\n    ...UserMenu_tenant\n    id\n  }\n}\n\nfragment UserMenu_tenant on Tenant {\n  id\n  permissions {\n    name\n  }\n}\n',
    },
  };
})();
(node as any).hash = 'e6e0a62289db269516690c71d193d611';
export default node;
