import React from 'react';
import styles from './StaticDashboard.module.scss';
import { WidthProvider, Responsive } from 'react-grid-layout';
import DashboardToolbar from '../components/DashboardToolbar';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { StaticDashboard_scans } from '../../../__generated__/StaticDashboard_scans.graphql';
import LatestScansTableWidget from '../../Widgets/LatestScansTableWidget';
import LatestScansChartWidget from '../../Widgets/LatestScansChartWidget';
import { StaticDashboard_aggregations } from '../../../__generated__/StaticDashboard_aggregations.graphql';

const ReactGridLayout = WidthProvider(Responsive);

interface IProps {
  scans: StaticDashboard_scans | null;
  aggregations: StaticDashboard_aggregations | null;
}

const StaticDashboard = ({ scans, aggregations }: IProps) => {
  return (
    <>
      <DashboardToolbar />

      <ReactGridLayout
        className={styles.staticDashboard}
        rowHeight={60}
        breakpoints={{ lg: 1440, md: 1200, sm: 1024, xs: 640, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 2 }}
        compactType={null}
        containerPadding={[0, 0]}
        margin={[15, 15]}
        useCSSTransforms={false}
        draggableHandle={'.not-draggable'} // https://github.com/STRML/react-grid-layout/issues/371#issuecomment-253814395
        isDraggable={false}
        isResizable={false}
      >
        <div key={0} data-grid={{ x: 0, y: 0, w: 6, h: 6 }}>
          <LatestScansChartWidget aggregations={aggregations} showHeadline />
        </div>
        <div key={1} data-grid={{ x: 7, y: 0, w: 6, h: 6 }}>
          <LatestScansTableWidget scans={scans} showHeadline clickableRows />
        </div>
      </ReactGridLayout>
    </>
  );
};

export default createFragmentContainer(StaticDashboard, {
  scans: graphql`
    fragment StaticDashboard_scans on ScanConnection {
      ...LatestScansTableWidget_scans
    }
  `,
  aggregations: graphql`
    fragment StaticDashboard_aggregations on AggregationConnection {
      ...LatestScansChartWidget_aggregations
    }
  `,
});
