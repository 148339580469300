import { ChartOptions, ChartDataSets } from 'chart.js';
import { baseOptions, baseColors, baseChartDataSet } from './base.options';
import merge from 'lodash.merge';

export const chartDataSet: ChartDataSets[] = [
  {
    backgroundColor: baseColors[0],
    borderColor: baseColors[0],
    borderWidth: 10,
    hoverBackgroundColor: baseColors[0],
    hoverBorderColor: baseColors[0],
  },
];

const options: ChartOptions = {
  tooltips: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderColor: '#646464',
    bodyFontColor: '#646464',
    titleFontColor: '#646464',
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    mode: 'x',
    intersect: false,
  },
};

export const barOptions: ChartOptions = merge({}, baseOptions, options);
export const barChartDataSet: ChartDataSets[] = merge({}, baseChartDataSet, chartDataSet);
