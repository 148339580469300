/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type StaticDashboard_aggregations = {
  readonly ' $fragmentRefs': FragmentRefs<'LatestScansChartWidget_aggregations'>;
  readonly ' $refType': 'StaticDashboard_aggregations';
};
export type StaticDashboard_aggregations$data = StaticDashboard_aggregations;
export type StaticDashboard_aggregations$key = {
  readonly ' $data'?: StaticDashboard_aggregations$data;
  readonly ' $fragmentRefs': FragmentRefs<'StaticDashboard_aggregations'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'StaticDashboard_aggregations',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'LatestScansChartWidget_aggregations',
    },
  ],
  type: 'AggregationConnection',
};
(node as any).hash = '41b7828105f9ec499688f35fccaf0422';
export default node;
