import React from 'react';
import styles from './ErrorNotice.module.scss';
import { Link } from 'react-router-dom';
import Error from '../Icons/Error';

interface IProps {
  returnUrl: string;
  errorText: string;
  buttonText: string;
}

const ErrorNotice = ({ returnUrl, errorText, buttonText }: IProps) => (
  <div className={styles.errorNotice}>
    <div>
      <Error className={styles.errorNotice__icon} />
    </div>
    <div className={'my-2'}>{errorText}</div>
    <div>
      <Link className={'button primary'} to={returnUrl}>
        {buttonText}
      </Link>
    </div>
  </div>
);

export default ErrorNotice;
