import React from 'react';
import Table from '../../../../components/Table';
import useRouter from 'use-react-router';
import { SkeletonRows } from '../../../../components/Skeleton';
import { useTranslation } from 'react-i18next';
import ClickableRow from '../../../../components/Table/components/ClickableRow';

interface IProps {
  isLoading: boolean;
  tickets: any[];
}

const options = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Europe/Berlin',
};

const TicketTable = ({ isLoading, tickets }: IProps) => {
  const { history } = useRouter();
  const { t } = useTranslation(['qrpid']);

  const columns = [
    t('tickets.columns.no'),
    t('tickets.columns.subject'),
    t('tickets.columns.name'),
    t('tickets.columns.lastChanged'),
  ];

  if (isLoading) {
    return (
      <Table columns={columns}>
        <SkeletonRows rowCount={3} columnCount={columns.length} />
      </Table>
    );
  }

  if (tickets.length > 0) {
    const rows = tickets.map((item: any) => {
      const lastChange = item.createdAt
        ? new Intl.DateTimeFormat('de-DE', options).format(Date.parse(item.updatedAt))
        : '';

      const row: string[] = [item.no, item.subject, `${item.fullName} (${item.email})`, lastChange];

      return (
        <ClickableRow key={item.id} row={row} onClick={() => history.push(`tickets/${item.id}`)} />
      );
    });

    return <Table columns={columns}>{rows}</Table>;
  }

  return <div>No Data!</div>;
};

export default TicketTable;
