/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type CountrySelect_countries = {
  readonly edges: ReadonlyArray<{
    readonly code: string;
    readonly name: string;
  } | null>;
  readonly ' $refType': 'CountrySelect_countries';
};
export type CountrySelect_countries$data = CountrySelect_countries;
export type CountrySelect_countries$key = {
  readonly ' $data'?: CountrySelect_countries$data;
  readonly ' $fragmentRefs': FragmentRefs<'CountrySelect_countries'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CountrySelect_countries',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Country',
      kind: 'LinkedField',
      name: 'edges',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'code',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ],
  type: 'CountryConnection',
};
(node as any).hash = '9bc49606f0eb11b848019fbad1ca8196';
export default node;
