import React, { ReactElement } from 'react';
import styles from './MenuItemWithIcon.module.scss';
import { Skeleton } from '../../../Skeleton';

interface IProps {
  name: string | null | undefined;
  icon: ReactElement | null;
  className?: string;
}

const MenuItemWithIcon = (props: IProps) => {
  const icon = props.icon
    ? React.cloneElement(props.icon, { className: styles.menuItemWithIcon__icon })
    : null;

  return (
    <div className={`${styles.menuItemWithIcon} ${props.className}`}>
      {icon}
      {props.name ? (
        <span className={styles.menuItemWithIcon__text}>{props.name}</span>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export default MenuItemWithIcon;
