import React from 'react';
import { SignIn } from 'aws-amplify-react/lib/Auth';
import { Form, Formik } from 'formik';
import Input from '../../../forms/Input';
import { validationSchema } from './lib/validationSchema';
import ProgressButton from '../../../ProgressButton';
import AuthFormContainer from '../AuthFormContainer';
import { Translation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

class QrpidSignIn extends SignIn {
  showComponent() {
    const { location } = this.props as any;

    return (
      <Translation>
        {t => (
          <AuthFormContainer
            loading={this.state.loading ?? false}
            headline={t('auth.signIn.headline')}
            successMessage={location.state?.successMessage}
          >
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={validationSchema(t)}
              onSubmit={values => {
                this.inputs.email = values.email.toLowerCase();
                this.inputs.password = values.password;
                this.signIn(null);
              }}
            >
              {props => (
                <Form translate="no">
                  <Input
                    type="email"
                    label={t('auth.labels.email')}
                    name="email"
                    placeholder={t('auth.labels.email')}
                    touched={props.touched.email}
                    error={props.errors.email}
                  />

                  <Input
                    type="password"
                    label={t('auth.labels.password')}
                    name="password"
                    placeholder={t('auth.labels.password')}
                    touched={props.touched.password}
                    error={props.errors.password}
                  />

                  <ProgressButton
                    type="submit"
                    isLoading={this.state.loading ?? false}
                    className="mb-0 w-100"
                  >
                    {t('auth.signIn.buttons.signIn')}
                  </ProgressButton>
                </Form>
              )}
            </Formik>

            <hr />

            <Link className="hollow button mb-2 w-100" to="/forgot-password">
              {t('auth.signIn.buttons.goToForgotPassword')}
            </Link>

            <Link className="hollow button mb-0 w-100" to="/sign-up">
              {t('auth.signIn.buttons.goToSignUp')}
            </Link>
          </AuthFormContainer>
        )}
      </Translation>
    );
  }
}

export default withRouter(QrpidSignIn as any);
