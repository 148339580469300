/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeType = 'REDIRECT' | 'URL' | 'WIFI' | '%future added value';
export type QrCodeDetails_qrCode = {
  readonly id: string;
  readonly tenantId: string;
  readonly type: QrCodeType;
  readonly isDynamic: boolean;
  readonly comment: string | null;
  readonly name: string | null;
  readonly enabled?: boolean | null;
  readonly redirectUrl?: string | null;
  readonly ' $fragmentRefs': FragmentRefs<'UrlForm_qrCode' | 'WifiForm_qrCode' | 'Sidebar_qrCode'>;
  readonly ' $refType': 'QrCodeDetails_qrCode';
};
export type QrCodeDetails_qrCode$data = QrCodeDetails_qrCode;
export type QrCodeDetails_qrCode$key = {
  readonly ' $data'?: QrCodeDetails_qrCode$data;
  readonly ' $fragmentRefs': FragmentRefs<'QrCodeDetails_qrCode'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'QrCodeDetails_qrCode',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'tenantId',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'type',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDynamic',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'comment',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'enabled',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'redirectUrl',
          storageKey: null,
        },
      ],
      type: 'RedirectQrCode',
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'UrlForm_qrCode',
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'WifiForm_qrCode',
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'Sidebar_qrCode',
    },
  ],
  type: 'QrCode',
};
(node as any).hash = '5c47d2f9bd76fd58dd6cf5564cd083b6';
export default node;
