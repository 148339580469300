import React from 'react';
import { ConfirmSignUp } from 'aws-amplify-react/lib/Auth';
import { Form, Formik } from 'formik';
import Input from '../../../forms/Input';
import { validationSchema } from './lib/validationSchema';
import ProgressButton from '../../../ProgressButton';
import AuthFormContainer from '../AuthFormContainer';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router';
import { TFunction } from 'i18next';

class QrpidConfirmSignUp extends ConfirmSignUp {
  async confirmEmail(t: TFunction) {
    const { history } = this.props as any;
    const username = this.usernameFromAuthData() || this.inputs.username;
    const { code } = this.inputs;

    this.setState(prevState => ({ ...prevState, confirmLoading: true }));

    try {
      await Auth.confirmSignUp(username, code);
      history.push({
        pathname: '/sign-in',
        state: {
          successMessage: t('auth.alerts.success.signedUp'),
        },
      });
    } catch (err) {
      Hub.dispatch('auth', { event: this.props.authState ?? '', data: err }, 'Auth');
    } finally {
      this.setState(prevState => ({ ...prevState, confirmLoading: false }));
    }
  }

  async resendCode(t: TFunction) {
    const username = this.usernameFromAuthData();

    this.setState(prevState => ({ ...prevState, resendLoading: true }));

    try {
      await Auth.resendSignUp(username);
      this.setState(prevState => ({
        ...prevState,
        successMessage: t('auth.alerts.success.resentCode'),
      }));
    } catch (err) {
      this.error(err);
    } finally {
      this.setState(prevState => ({ ...prevState, resendLoading: false }));
    }
  }

  showComponent() {
    const { confirmLoading, resendLoading, successMessage } = (this.state as any) ?? {};
    const { location } = this.props as any;

    return (
      <Translation>
        {t => (
          <AuthFormContainer
            loading={confirmLoading}
            headline={t('auth.confirmSignUp.headline')}
            successMessage={successMessage ?? location.state?.successMessage}
          >
            <Formik
              initialValues={{ code: '' }}
              validationSchema={validationSchema}
              onSubmit={async values => {
                this.inputs.code = values.code;
                await this.confirmEmail(t);
              }}
            >
              {props => (
                <Form translate="no">
                  <Input
                    type="text"
                    label={t('auth.labels.code')}
                    name="code"
                    placeholder={t('auth.labels.code')}
                    touched={props.touched.code}
                    error={props.errors.code}
                  />

                  <ProgressButton type="submit" isLoading={confirmLoading} className="mb-0 w-100">
                    {t('auth.confirmSignUp.buttons.confirm')}
                  </ProgressButton>
                </Form>
              )}
            </Formik>

            <hr />

            <ProgressButton
              isLoading={resendLoading}
              className="hollow button mb-2 w-100"
              onClick={() => this.resendCode(t)}
              spinnerColor={'PRIMARY'}
            >
              {t('auth.buttons.resendCode')}
            </ProgressButton>
          </AuthFormContainer>
        )}
      </Translation>
    );
  }
}

export default withRouter(QrpidConfirmSignUp as any);
