/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type LayoutQueryVariables = {};
export type LayoutQueryResponse = {
  readonly user: {
    readonly ' $fragmentRefs': FragmentRefs<'Sidebar_user' | 'Navbar_user'>;
  } | null;
};
export type LayoutQuery = {
  readonly response: LayoutQueryResponse;
  readonly variables: LayoutQueryVariables;
};

/*
query LayoutQuery {
  user {
    ...Sidebar_user
    ...Navbar_user
    id
  }
}

fragment Navbar_user on User {
  ...TenantSelect_user
}

fragment Sidebar_user on User {
  ...UserMenu_user
}

fragment TenantSelect_user on User {
  tenants {
    id
    name
  }
}

fragment UserMenu_user on User {
  email
  firstname
}
*/

const node: ConcreteRequest = (function() {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'id',
    storageKey: null,
  };
  return {
    fragment: {
      argumentDefinitions: [],
      kind: 'Fragment',
      metadata: null,
      name: 'LayoutQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'User',
          kind: 'LinkedField',
          name: 'user',
          plural: false,
          selections: [
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'Sidebar_user',
            },
            {
              args: null,
              kind: 'FragmentSpread',
              name: 'Navbar_user',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [],
      kind: 'Operation',
      name: 'LayoutQuery',
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'User',
          kind: 'LinkedField',
          name: 'user',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'email',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'firstname',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              concreteType: 'Tenant',
              kind: 'LinkedField',
              name: 'tenants',
              plural: true,
              selections: [
                v0 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'name',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            v0 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      id: null,
      metadata: {},
      name: 'LayoutQuery',
      operationKind: 'query',
      text:
        'query LayoutQuery {\n  user {\n    ...Sidebar_user\n    ...Navbar_user\n    id\n  }\n}\n\nfragment Navbar_user on User {\n  ...TenantSelect_user\n}\n\nfragment Sidebar_user on User {\n  ...UserMenu_user\n}\n\nfragment TenantSelect_user on User {\n  tenants {\n    id\n    name\n  }\n}\n\nfragment UserMenu_user on User {\n  email\n  firstname\n}\n',
    },
  };
})();
(node as any).hash = 'fdf0db8a9eabf0069607f81013014ee2';
export default node;
