import React from 'react';
import { Field } from 'formik';
import styles from './Input.module.scss';

interface IProps {
  label?: string;
  name: string;
  placeholder?: string;
  type: string;
  touched?: boolean;
  error?: string;
  required?: boolean;
  hideValidationMessage?: boolean;
  className?: string;
  inputClassName?: string;
}

const Input = ({
  label,
  name,
  placeholder,
  touched,
  error,
  type,
  required,
  hideValidationMessage,
  className,
  inputClassName,
}: IProps) => {
  return (
    <label className={`${touched && error ? 'is-invalid-label' : ''} ${className ?? ''}`}>
      {label}
      {required && <sup className={styles.input__required}>*</sup>}
      <Field
        type={type}
        name={name}
        className={`${touched && error ? 'is-invalid-input' : ''} ${inputClassName ?? ''}`}
        placeholder={placeholder}
      />
      {!hideValidationMessage && touched && error ? (
        <span className="form-error is-visible">{error}</span>
      ) : null}
    </label>
  );
};

export default Input;
