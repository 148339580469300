/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeType = 'REDIRECT' | 'URL' | 'WIFI' | '%future added value';
export type WifiEncryptionType = 'NONE' | 'WEP' | 'WPA' | '%future added value';
export type QrCodeWithDownload_qrCode = {
  readonly serialNumber: number;
  readonly type: QrCodeType;
  readonly isDynamic: boolean;
  readonly qrCodeUrl?: string | null;
  readonly url?: string | null;
  readonly ssid?: string | null;
  readonly password?: string | null;
  readonly encryption?: WifiEncryptionType | null;
  readonly ' $refType': 'QrCodeWithDownload_qrCode';
};
export type QrCodeWithDownload_qrCode$data = QrCodeWithDownload_qrCode;
export type QrCodeWithDownload_qrCode$key = {
  readonly ' $data'?: QrCodeWithDownload_qrCode$data;
  readonly ' $fragmentRefs': FragmentRefs<'QrCodeWithDownload_qrCode'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'QrCodeWithDownload_qrCode',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'serialNumber',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'type',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDynamic',
      storageKey: null,
    },
    {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'qrCodeUrl',
          storageKey: null,
        },
      ],
      type: 'RedirectQrCode',
    },
    {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'url',
          storageKey: null,
        },
      ],
      type: 'UrlQrCode',
    },
    {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'ssid',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'password',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'encryption',
          storageKey: null,
        },
      ],
      type: 'WifiQrCode',
    },
  ],
  type: 'QrCode',
};
(node as any).hash = 'e4496c56405546a56bd4e53e66f25f0b';
export default node;
