/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from 'relay-runtime';
export type QrCodeType = 'REDIRECT' | 'URL' | 'WIFI' | '%future added value';
export type Sidebar_qrCode = {
  readonly accessKey: string;
  readonly serialNumber: number;
  readonly updatedAt: string | null;
  readonly type: QrCodeType;
  readonly isDynamic: boolean;
  readonly scanCount?: number | null;
  readonly ' $fragmentRefs': FragmentRefs<'QrCodeWithDownload_qrCode'>;
  readonly ' $refType': 'Sidebar_qrCode';
};
export type Sidebar_qrCode$data = Sidebar_qrCode;
export type Sidebar_qrCode$key = {
  readonly ' $data'?: Sidebar_qrCode$data;
  readonly ' $fragmentRefs': FragmentRefs<'Sidebar_qrCode'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Sidebar_qrCode',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'accessKey',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'serialNumber',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'updatedAt',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'type',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isDynamic',
      storageKey: null,
    },
    {
      kind: 'InlineFragment',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'scanCount',
          storageKey: null,
        },
      ],
      type: 'RedirectQrCode',
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'QrCodeWithDownload_qrCode',
    },
  ],
  type: 'QrCode',
};
(node as any).hash = 'b76ebc4ccfbf690e82d0216fdacfc0be';
export default node;
