import React from 'react';
import { Formik, Form } from 'formik';
import { validationSchema } from './lib/validationSchema';
import Input from '../../../../../forms/Input';
import ProgressButton from '../../../../../ProgressButton';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IProps {
  onSubmit: (values: any) => void;
  isLoading: boolean;
}

const EmailForm = ({ onSubmit, isLoading }: IProps) => (
  <Translation>
    {t => (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {props => (
            <Form translate="no" name="sendView">
              <Input
                type="email"
                label={t('auth.labels.email')}
                name="email"
                placeholder={t('auth.labels.email')}
                touched={props.touched.email}
                error={props.errors.email}
              />

              <ProgressButton type="submit" isLoading={isLoading} className="mb-0 w-100">
                {t('auth.forgotPassword.buttons.forgotPassword')}
              </ProgressButton>
            </Form>
          )}
        </Formik>
        <hr />

        <Link to="/sign-in" className="hollow button mb-0 w-100">
          {t('auth.buttons.goToSignIn')}
        </Link>
      </>
    )}
  </Translation>
);

export default EmailForm;
