import { ChartOptions, ChartDataSets } from 'chart.js';
import { baseOptions } from './base.options';
import merge from 'lodash.merge';

const chartDataSet: ChartDataSets[] = [
  {
    backgroundColor: 'rgba(38,114,184, 0.6)',
    borderColor: '#2672b8',
    borderWidth: 2,
    hoverBackgroundColor: '#2672b8',
    hoverBorderColor: '#2672b8',
    pointBackgroundColor: '#2672b8',
  },
];

const options: ChartOptions = {
  tooltips: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderColor: '#646464',
    bodyFontColor: '#646464',
    titleFontColor: '#646464',
    shadowBlur: 10,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    mode: 'x',
    intersect: false,
  },
};

export const lineOptions: ChartOptions = merge({}, baseOptions, options);
export const lineChartDataSet: ChartDataSets[] = merge({}, baseOptions, chartDataSet);
