import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import useRouter from 'use-react-router';
import { QrCodeRow_qrCode } from '../../../../../../__generated__/QrCodeRow_qrCode.graphql';
import ClickableRow from '../../../../../../components/Table/components/ClickableRow';

interface IProps {
  qrCode: QrCodeRow_qrCode;
}

const QrCodeRow = ({
  qrCode: { id, serialNumber, type, isDynamic, enabled, name, comment, scanCount },
}: IProps) => {
  const { history } = useRouter();
  const nameRow = name
    ? name
    : comment // fallback to comment if name is not set
    ? `${comment.substr(0, 32)}${comment.length > 32 ? '...' : ''}` // limit comment to max. 32 characters
    : '';
  const scanCountRow = scanCount ? scanCount.toString() : type === 'REDIRECT' ? '0' : 'n/a';

  const row: string[] = [
    serialNumber.toString(),
    nameRow,
    !isDynamic ? 'Yes' : isDynamic && enabled ? 'Yes' : 'No',
    scanCountRow,
  ];

  return <ClickableRow row={row} onClick={() => history.push(`qr-codes/${id}`)} />;
};

export default createFragmentContainer(QrCodeRow, {
  qrCode: graphql`
    fragment QrCodeRow_qrCode on QrCode {
      id
      serialNumber
      type
      isDynamic
      name
      comment
      ... on RedirectQrCode {
        enabled
        scanCount
      }
    }
  `,
});
