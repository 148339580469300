import { ChartOptions, ChartDataSets } from 'chart.js';
import { baseOptions, baseChartDataSet, baseColors } from './base.options';
import merge from 'lodash.merge';

const chartDataSet: ChartDataSets[] = [
  {
    backgroundColor: baseColors,
    borderWidth: 0,
    hoverBackgroundColor: baseColors,
  },
];

const options: ChartOptions = {
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        display: false,
      },
    ],
  },
};

export const pieOptions: ChartOptions = merge({}, baseOptions, options);
export const pieChartDataSet: ChartDataSets[] = merge({}, baseChartDataSet, chartDataSet);
