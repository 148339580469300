import React from 'react';

interface IProps {
  disabled?: boolean;
  checked: boolean;
  onChange: (event: any) => void;
}

const EnabledCheckbox = ({ disabled, checked, onChange }: IProps) => (
  <>
    <label>Activated</label>
    <div className="switch tiny">
      <input
        className="switch-input"
        id="enabled"
        type="checkbox"
        name="enabled"
        defaultChecked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="switch-paddle" htmlFor="enabled">
        <span className="show-for-sr">Enabled</span>
        <span className="switch-active" aria-hidden="true">
          Yes
        </span>
        <span className="switch-inactive" aria-hidden="true">
          No
        </span>
      </label>
    </div>
  </>
);

export default EnabledCheckbox;
