import React from 'react';
import SkeletonRow from '../../Table/components/SkeletonRow';

interface IProps {
  rowCount: number;
  columnCount: number;
  width?: number | string;
  alternateWidth?: number | string;
}

const SkeletonRows = ({ rowCount, columnCount, width, alternateWidth }: IProps) => {
  const skeleton: any[] = [];

  const skeletonColumns = [];

  const isEven = (n: number) => {
    return n % 2 === 0;
  };

  for (let _i = 0; _i < columnCount; _i = _i + 1) {
    skeletonColumns.push(' ');
  }

  for (let _i = 0; _i < rowCount; _i = _i + 1) {
    skeleton.push({ id: _i, columns: skeletonColumns, width: isEven(_i) ? width : alternateWidth });
  }

  const rows = skeleton.map((item: any) => {
    return <SkeletonRow key={item.id} row={item.columns} width={item.width} />;
  });

  return <>{rows}</>;
};

export default SkeletonRows;
