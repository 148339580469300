import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../../../components/Table';
import { SkeletonRows } from '../../../../../../components/Skeleton';

interface IProps {
  children: ReactNode;
}

const QrCodeTable = ({ children }: IProps) => {
  const { t } = useTranslation(['qrpid']);

  const columns = [
    t('qrcodes.columns.serialNo'),
    t('qrcodes.columns.name'),
    t('qrcodes.columns.activated'),
    t('qrcodes.columns.scans'),
  ];

  if (!children) {
    return (
      <Table columns={columns}>
        <SkeletonRows
          rowCount={3}
          columnCount={columns.length}
          width={'50%'}
          alternateWidth={'80%'}
        />
      </Table>
    );
  }

  return <Table columns={columns}>{children}</Table>;
};

export default QrCodeTable;
