export default class UserExtensions {
  static hasPermission(
    permissions: readonly { readonly name: string }[] | null,
    permission: string
  ): boolean {
    if (!permissions) {
      return false;
    }

    return permissions.map(p => p.name).includes(permission);
  }
}
