import React from 'react';

interface IProps {
  file: Blob;
  filename: string;
  linkText?: string;
  className?: string;
}

const DownloadFileLink = ({ file, filename, linkText = 'Download', className }: IProps) => {
  const handleDownloadFile = () => {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <button onClick={handleDownloadFile} className={`clear button primary ${className}`}>
      {linkText}
    </button>
  );
};

export default DownloadFileLink;
